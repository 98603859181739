import React from "react";
import '../assets/css/styles.css';
import '../assets/css/colors.css';
import '../assets/css/plugins/font-awesome.css';
import '../assets/css/plugins/flaticon.css';
import '../assets/css/plugins/bootstrap.min.css';
import upevents3 from "../assets/images/upevents6.jpg";
import upevents5 from "../assets/images/upevents5.jpg";

export const UpcomingEvents = () => {
  return (
    <div>
      <section className="page-title" >
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-3" ></div> */}
            {/* <div className="col-lg-12 col-md-6" style={{ padding: "5px" }}>
              <div className="article_featured_image">
                <img className="img-fluid" src={upevents3} alt="" />
              </div>
            </div> */}
            {/* <div className="col-lg-6 col-md-6" style={{ padding: "5px" }}>
              <div className="article_featured_image">
                <img className="img-fluid" src={upevents5} alt="" />
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};
