import React, { useEffect, useState } from "react";
import "../../App.css";
import axios from 'axios';
export const Sample = () => {

    // const [posts, setPosts] = useState([]);

    // useEffect(() => {
    //     const fetchPosts = async () => {
    //         const response = await axios.get('http://localhost/wordpress/wp-json/wp/v2/posts');
    //         setPosts(response.data);
    //         console.log(response.data[0]);
    //     };

    //     fetchPosts();
    // }, []);


    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetch('http://localhost/wordpress/wp-json/wp/v2/pages')
            .then(response => response.json())
            .then(data => {
                setPosts(data);
                console.log(data)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []); // Empty dependency array ensures the effect runs once after initial render



    return (
        <div>
            <section className="aboutRow">
                <div >
                    <div className="row" style={{ boxShadow: "0px 4px 5px #888888" }}>
                        <div class="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                            {/* <div className="education_block_list_layout"> */}
                            <div className="list_layout_ecucation_caption">
                                <div style={{ padding: "5px", boxSizing: "content-box" }}>
                                    <h1 style={{ textAlign: "left", fontSize: "25px", color: "#222a35" }}>Welcome</h1>


                                    {/* <ul>
                                        {posts.map((post) => (
                                            <li key={post.id}>{post.title.rendered}</li>
                                        ))}
                                    </ul> */}
                                    {posts.map(post => (
                                        <div key={post.id}>
                                            {/* <h2>{post.title.rendered}</h2> */}
                                            <p dangerouslySetInnerHTML={{ __html: post.content.rendered }}></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
