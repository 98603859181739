import '../assets/css/styles.css';
import '../assets/css/colors.css';
import '../assets/css/plugins/font-awesome.css';
import '../assets/css/plugins/flaticon.css';
import '../assets/css/plugins/bootstrap.min.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const Conference = () => {
  const form1 = useRef();
  const form2 = useRef();
  const form3 = useRef();
  const sendEmail1 = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_5jg3ske', 'template_o86ulkp', form1.current,
      'tNwTmvoIP5Jp6GXov')
      .then((result) => {
        console.log(result.text);
        window.location.reload(false);
      }, (error) => {
        console.log(error.text);
      });
  };
  const sendEmail3 = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_5jg3ske', 'template_o86ulkp', form3.current,
      'tNwTmvoIP5Jp6GXov')
      .then((result) => {
        console.log(result.text);
        window.location.reload(false);
      }, (error) => {
        console.log(error.text);
      });
  };
  const sendEmail2 = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_5jg3ske', 'template_o86ulkp', form2.current,
      'tNwTmvoIP5Jp6GXov')
      .then((result) => {
        console.log(result.text);
        window.location.reload(false);
      }, (error) => {
        console.log(error.text);
      });
  };
  return (
    <div>

      <Tabs
        defaultActiveKey="conference"
        id="fill-tab-example"
        className="mb-3 " fill style={{ display: "inline-flex", flexDirection: "row" }}
      >
        <Tab eventKey="conference" title="Conference Halls" style={{ width: "100%", color: "#000" }}>
          <section className="page-title" style={{ padding: "0px" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">

                  <div className="breadcrumbs-wrap">
                    <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Conference Halls</h1>

                  </div>

                </div>
              </div>
            </div>
          </section>
          <section className="gray">

            <div className="container">

              <div className="row">

                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="article_detail_wrapss single_article_wrap format-standard">
                    <div className="article_body_wrap">

                      <div className="article_featured_image">
                        <img className="img-fluid" src="https://malayaleeclub.org/assets/img/meet_bg.jpg" alt="" />
                      </div>
                      <hr />
                      <div class="dashboard_container_body">
                        <div class="table-responsive">
                          <table class="table">
                            <thead class="thead-dark">
                              <tr>
                                <th scope="col">Sl. No.</th>
                                <th scope="col">Halls</th>
                                <th scope="col" style={{ textAlign: "right" }}>Capacity</th>
                                <th scope="col" style={{ textAlign: "right" }}>Amount (INR)</th>
                                <th scope="col" style={{ textAlign: "right" }}>GST (18%)</th>
                                <th scope="col" style={{ textAlign: "right" }}>Total (INR)</th>
                                <th scope="col" style={{ textAlign: "right" }}>Duration</th>
                                {/* <th scope="col" style={{ textAlign: "right" }}>Capacity</th> */}
                                <th scope="col">Facilities</th>

                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">1</th>
                                <td style={{ textAlign: "left" }}><b>Bina Nandu Meeting Center</b></td>
                                <td>60</td>
                                <td >4000</td>
                                <td >720</td>
                                <td ><b>4720</b></td>
                                <td>5 hours</td>
                                <td style={{ textAlign: "left" }}>60 Banquet Chairs<br />
                                  Meeting Head Table<br />
                                  Public Address System</td>

                              </tr>

                              <tr>
                                <th scope="row">2</th>
                                <td style={{ textAlign: "left" }}><b>Farwood Conference Hall</b></td>
                                <td>50</td>
                                <td>4500</td>
                                <td>810</td>
                                <td><b>5310</b></td>
                                <td>5 hours</td>
                                <td style={{ textAlign: "left" }}>Conference Table + 50 Chairs<br />
                                  Public Address System<br />
                                  Visual Projector + Reception Room<br />
                                  Food Counter</td>
                              </tr>

                              <tr>
                                <th scope="row">3</th>
                                <td style={{ textAlign: "left" }}><b>Sheenlac Meeting Center</b></td>
                                <td>50</td>
                                <td>3500</td>
                                <td>630</td>
                                <td><b>4130</b></td>
                                <td>5 hours</td>
                                <td style={{ textAlign: "left" }}>50 Banquet Chairs</td>
                              </tr>

                              <tr>
                                <th scope="row">4</th>
                                <td style={{ textAlign: "left" }}><b>Mini Meeting Center</b></td>
                                <td>15</td>
                                <td>1500</td>
                                <td>270</td>
                                <td><b>1770</b></td>
                                <td>5 hours</td>
                                <td style={{ textAlign: "left" }}>Conference Table with chairs</td>
                              </tr>

                              <tr>
                                <th scope="row">5</th>
                                <td style={{ textAlign: "left" }}><b>M.A. Vellodi Literary Room</b></td>
                                <td>10</td>
                                <td>1500</td>
                                <td>270</td>
                                <td><b>1770</b></td>
                                <td>5 hours</td>
                                <td style={{ textAlign: "left" }}>Subject to availability.</td>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                      </div>
                      <div className='jumbotron' style={{ padding: "2rem 1rem" }}>

                        <h1 style={{ textAlign: "left", fontSize: "15px", color: "#222a35" }}>Remarks</h1>

                        <ul style={{ textAlign: "left" }}>
                          <li>Club members 10% Discount</li>
                          <li>Malayalee Associations – 20% Discount</li>

                        </ul>
                      </div>
                      {/* <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping">
                            <div className="packages_headers">
                              <i className="lni-layers"></i>
                              <h4 className="packages_pr_title">Bina Nandu Meeting Center (60)</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">4000</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 720 (GST 18%)</li>
                                <li>Duration of Usage : 5 hours</li>
                                <li>60 Sitting Capacity A/C Hall</li>
                                <li>60 Banquet Chairs</li>
                                <li>Meeting Head Table</li>
                                <li>Public Address System</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping recommended">
                            <div className="packages_headers">
                              <i className="lni-diamond"></i>
                              <h4 className="packages_pr_title">Farwood Conference Hall (50)</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">4500</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 810 (GST 18%)</li>
                                <li>Duration of Usage : 5 hours</li>
                                <li>Conference Table + 50 Chairs</li>
                                <li>Public Address System</li>
                                <li>Visual Projector + Reception Room</li>
                                <li>Food Counter</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping">
                            <div className="packages_headers">
                              <i className="lni-layers"></i>
                              <h4 className="packages_pr_title">Sheenlac Meeting Center (50)</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">3500</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 630 (GST 18%)</li>
                                <li>Duration of Usage : 5 hours</li>
                                <li>50 Sitting Capacity A/C Hall</li>
                                <li>50 Banquet Chairs</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping recommended">

                            <div className="packages_headers">
                              <i className="lni-diamond"></i>
                              <h4 className="packages_pr_title">Mini Meeting Center (15)</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">1500</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 270 (GST 18%)</li>
                                <li>Duration of Usage : 5 hours</li>
                                <li>Conference Table</li>
                                <li>15 Chairs</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping">
                            <div className="packages_headers">
                              <i className="lni-layers"></i>
                              <h4 className="packages_pr_title">M.A. Vellodi Literary Room (10)</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">1500</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 270 (GST 18%)</li>
                                <li>Duration of Usage : 5 hours</li>
                                <li>10 Sitting Capacity</li>
                                <li>A/C Hall</li>

                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="single_widgets widget_tags">
                    <h3 className="title">For Booking Please Contact:
                    </h3>
                    <ul>
                      <li><a href="#">
                        04428360733 | 98843 80071</a></li>

                    </ul>
                  </div>
                  <div className="single_widgets widget_thumb_post">
                    <div className="prc_wrap">

                      <div className="prc_wrap_header">
                        <h3 className="property_block_title">Enquire Now</h3>
                      </div>

                      <form ref={form2} onSubmit={sendEmail2}>

                        <div className="prc_wrap-body">
                          <div className="row">

                            <div className="col-lg-12 col-md-12">


                              <div className="form-group" style={{ textAlign: "left" }}>
                                <label >Name</label>
                                <input type="text" name="user_name" className="form-control simple" />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group" style={{ textAlign: "left" }}>
                                <label>Email</label>
                                <input type="email" name="user_email" className="form-control simple" />
                              </div>
                            </div>
                          </div>

                          <div className="form-group" style={{ textAlign: "left" }}>
                            <label>Phone Number</label>
                            <input name="user_number" type="text" className="form-control simple" />
                          </div>
                          <div className="form-group" style={{ display: "none" }}>
                            <input name="enquiry_from" type="text" value="Message from Conference Booking page" className="form-control simple" />
                          </div>

                          <div className="form-group" style={{ textAlign: "left" }}>
                            <label>Message</label>
                            <textarea name="message" className="form-control simple"></textarea>
                          </div>

                          <div className="form-group">

                            <button className="btn btn-theme" value="Send" type="submit">Submit</button>
                          </div>
                        </div>
                      </form>

                    </div>
                  </div>



                </div>

              </div>

            </div>

          </section>
        </Tab>
        <Tab eventKey="banquets" title="Banquet Halls" style={{ width: "100%", color: "#000" }}>
          <section className="page-title" style={{ padding: "0px" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="breadcrumbs-wrap">
                    <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Banquet Halls</h1>
                  </div>
                </div>

              </div>
            </div>
          </section>
          <section className="gray">

            <div className="container">

              <div className="row">

                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="article_detail_wrapss single_article_wrap format-standard">
                    <div className="article_body_wrap">

                      <div className="article_featured_image">
                        <img className="img-fluid" src="https://malayaleeclub.org/assets/img/hover_slide3.jpg" alt="" />
                      </div>
                      <hr />
                      <div class="dashboard_container_body">
                        <div class="table-responsive">
                          <h4 style={{ textAlign: "center" }}>BANQUET HALL WITH 100/150 CAPACITY</h4>
                          <table class="table">
                            <thead class="thead-dark">
                              <tr>
                                <th scope="col">Sl. No.</th>
                                <th scope="col" style={{ textAlign: "left" }}>Banquet Halls</th>
                                <th scope="col" style={{ textAlign: "right" }}>Capacity</th>
                                <th scope="col" style={{ textAlign: "right" }}>Tariff for 5 hours</th>
                                <th scope="col" style={{ textAlign: "right" }}>GST (18%)</th>
                                <th scope="col" style={{ textAlign: "right" }}>Total (INR)</th>
                                {/* <th scope="col" style={{ textAlign: "right" }}>Duration</th> */}
                                {/* <th scope="col" style={{ textAlign: "right" }}>Capacity</th> */}
                                <th scope="col">Facilities</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">1</th>
                                <td style={{ textAlign: "left" }}><b>Travancore Hall (Ground Floor) </b></td>
                                <td>150</td>
                                <td>20000</td>
                                <td>3600</td>
                                <td><b>23600</b></td>
                                {/* <td>5hrs</td> */}
                                <td style={{ textAlign: "left" }}>Banquet chairs<br />
                                  Frills for Dinning tables
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">2</th>
                                <td style={{ textAlign: "left" }}><b>Pallava Hall (Fourth Floor)</b></td>
                                <td>120</td>
                                <td>15000</td>
                                <td>2700</td>
                                <td><b>17700</b></td>
                                {/* <td>5 hours</td> */}
                                <td style={{ textAlign: "left" }}>Banquet chairs</td>
                              </tr>

                              {/* <tr>
                                <th scope="row">3</th>
                                <td style={{ textAlign: "left" }}><b>Club Members</b></td>
                                <td>10000</td>
                                <td>1800</td>
                                <td><b>11800</b></td>
                                <td>5 hours</td>
                                <td style={{ textAlign: "left" }}>Banquet Chairs</td>
                              </tr>

                              <tr>
                                <th scope="row">4</th>
                                <td style={{ textAlign: "left" }}><b>Malayalee Associations</b></td>
                                <td>12000</td>
                                <td>2160</td>
                                <td><b>14160</b></td>
                                <td>5 hours</td>
                                <td style={{ textAlign: "left" }}>Banquet Chairs</td>
                              </tr> */}
                            </tbody>
                          </table>

                        </div>
                      </div>
                      <div className='jumbotron' style={{ padding: "2rem 1rem" }}>

                        <h1 style={{ textAlign: "left", fontSize: "15px", color: "#222a35" }}>Remarks</h1>

                        <ul style={{ textAlign: "left" }}>
                          <li>Club members 10% Discount</li>
                          <li>Malayalee Associations – 20% Discount</li>

                        </ul>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="single_widgets widget_tags">
                    <h3 className="title">For Booking Please Contact:
                    </h3>
                    <ul>
                      <li><a href="#">
                        04428360733 | 98843 80071</a></li>

                    </ul>
                  </div>
                  <div className="single_widgets widget_thumb_post">
                    <div className="prc_wrap">

                      <div className="prc_wrap_header">
                        <h3 className="property_block_title">Enquire Now</h3>
                      </div>

                      <form ref={form3} onSubmit={sendEmail3}>

                        <div className="prc_wrap-body">
                          <div className="row">

                            <div className="col-lg-12 col-md-12">


                              <div className="form-group" style={{ textAlign: "left" }}>
                                <label >Name</label>
                                <input type="text" name="user_name" className="form-control simple" />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group" style={{ textAlign: "left" }}>
                                <label>Email</label>
                                <input type="email" name="user_email" className="form-control simple" />
                              </div>
                            </div>
                          </div>

                          <div className="form-group" style={{ textAlign: "left" }}>
                            <label>Phone Number</label>
                            <input name="user_number" type="text" className="form-control simple" />
                          </div>
                          <div className="form-group" style={{ display: "none" }}>
                            <input name="enquiry_from" type="text" value="Message from Banquet Booking page" className="form-control simple" />
                          </div>

                          <div className="form-group" style={{ textAlign: "left" }}>
                            <label>Message</label>
                            <textarea name="message" className="form-control simple"></textarea>
                          </div>

                          <div className="form-group">

                            <button className="btn btn-theme" value="Send" type="submit">Submit</button>
                          </div>
                        </div>
                      </form>

                    </div>
                  </div>



                </div>

              </div>

            </div>

          </section>
        </Tab>

      </Tabs>
    </div>
  );
};
