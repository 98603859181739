import React, { useEffect } from "react";


import g1 from "../assets/images/gallery/1.jpg";
import g2 from "../assets/images/gallery/2.jpg";
import g3 from "../assets/images/gallery/3.jpg";
import g4 from "../assets/images/gallery/4.jpg";
import g5 from "../assets/images/gallery/5.jpg";
import g6 from "../assets/images/gallery/6.jpg";
import g7 from "../assets/images/gallery/7.jpg";
import g8 from "../assets/images/gallery/8.jpg";
import g9 from "../assets/images/gallery/9.jpg";
import g10 from "../assets/images/gallery/10.jpg";
import g11 from "../assets/images/gallery/11.jpg";
import g12 from "../assets/images/gallery/12.jpg";
import g13 from "../assets/images/gallery/13.jpg";
import g14 from "../assets/images/gallery/14.jpg";
import g15 from "../assets/images/gallery/15.jpg";
import g16 from "../assets/images/gallery/16.jpg";
import g17 from "../assets/images/gallery/17.jpg";
import g18 from "../assets/images/gallery/18.jpg";
import g19 from "../assets/images/gallery/19.jpg";
import g20 from "../assets/images/gallery/20.jpg";
import g21 from "../assets/images/gallery/21.jpg";
import g22 from "../assets/images/gallery/22.jpg";
import g23 from "../assets/images/gallery/23.jpg";
import g24 from "../assets/images/gallery/24.jpg";
import g25 from "../assets/images/gallery/25.jpg";
import g26 from "../assets/images/gallery/26.jpg";
import g27 from "../assets/images/gallery/27.jpg";
import g28 from "../assets/images/gallery/28.jpg";
import g29 from "../assets/images/gallery/29.jpg";
import g30 from "../assets/images/gallery/30.jpg";
import g31 from "../assets/images/gallery/31.jpg";
import g32 from "../assets/images/gallery/32.jpg";
import g33 from "../assets/images/gallery/33.jpg";
import g34 from "../assets/images/gallery/34.jpg";
import g35 from "../assets/images/gallery/35.jpg";
import g36 from "../assets/images/gallery/36.jpg";
import g37 from "../assets/images/gallery/37.jpg";
import g38 from "../assets/images/gallery/38.jpg";
import g39 from "../assets/images/gallery/39.jpg";
import g40 from "../assets/images/gallery/40.jpg";
import g41 from "../assets/images/gallery/41.jpg";
import g42 from "../assets/images/gallery/42.jpg";
import g43 from "../assets/images/gallery/43.jpg";
import g44 from "../assets/images/gallery/44.jpg";
import g45 from "../assets/images/gallery/45.jpg";
import g46 from "../assets/images/gallery/46.jpg";
import g47 from "../assets/images/gallery/47.jpg";
import g48 from "../assets/images/gallery/48.jpg";
import g49 from "../assets/images/gallery/49.jpg";
import g50 from "../assets/images/gallery/50.jpg";
import g51 from "../assets/images/gallery/51.jpg";
import g52 from "../assets/images/gallery/52.jpg";
import g53 from "../assets/images/gallery/53.jpg";
import g54 from "../assets/images/gallery/54.jpg";
import g55 from "../assets/images/gallery/55.jpg";
import g56 from "../assets/images/gallery/56.jpg";
import g57 from "../assets/images/gallery/57.jpg";
import g58 from "../assets/images/gallery/58.jpg";
import g59 from "../assets/images/gallery/59.jpg";
import g60 from "../assets/images/gallery/60.jpg";
import g61 from "../assets/images/gallery/61.jpg";
import g62 from "../assets/images/gallery/62.jpg";
import g63 from "../assets/images/gallery/63.jpg";
import g64 from "../assets/images/gallery/64.jpg";
import g65 from "../assets/images/gallery/65.jpg";
import g66 from "../assets/images/gallery/66.jpg";
import g67 from "../assets/images/gallery/67.jpg";
import g68 from "../assets/images/gallery/68.jpg";
import g69 from "../assets/images/gallery/69.jpg";
import g70 from "../assets/images/gallery/70.jpg";
import g71 from "../assets/images/gallery/71.jpg";
import g72 from "../assets/images/gallery/72.jpg";
import g73 from "../assets/images/gallery/73.jpg";
import g74 from "../assets/images/gallery/74.jpg";
import g75 from "../assets/images/gallery/75.jpg";
import g76 from "../assets/images/gallery/76.jpg";
import g77 from "../assets/images/gallery/77.jpg";
import g78 from "../assets/images/gallery/78.jpg";
import g79 from "../assets/images/gallery/79.jpg";
import g80 from "../assets/images/gallery/80.jpg";
import g81 from "../assets/images/gallery/81.jpg";
import g82 from "../assets/images/gallery/82.jpg";
import g83 from "../assets/images/gallery/83.jpg";
import g84 from "../assets/images/gallery/84.jpg";
import g85 from "../assets/images/gallery/85.jpg";
import g86 from "../assets/images/gallery/86.jpg";
import g87 from "../assets/images/gallery/87.jpg";
import g88 from "../assets/images/gallery/88.jpg";
import g89 from "../assets/images/gallery/89.jpg";
import g90 from "../assets/images/gallery/90.jpg";
import g91 from "../assets/images/gallery/91.jpg";
import g92 from "../assets/images/gallery/92.jpg";
import g93 from "../assets/images/gallery/93.jpg";
import g94 from "../assets/images/gallery/94.jpg";
import g95 from "../assets/images/gallery/95.jpg";
import g96 from "../assets/images/gallery/96.jpg";
import g97 from "../assets/images/gallery/97.jpg";
import g98 from "../assets/images/gallery/98.jpg";
import g99 from "../assets/images/gallery/99.jpg";
import g100 from "../assets/images/gallery/100.jpg";
import g101 from "../assets/images/gallery/101.jpg";
import g102 from "../assets/images/gallery/102.jpg";
import g103 from "../assets/images/gallery/103.jpg";
import g104 from "../assets/images/gallery/104.jpg";
import g105 from "../assets/images/gallery/105.jpg";
import g106 from "../assets/images/gallery/106.jpg";
import g107 from "../assets/images/gallery/107.jpg";
import g108 from "../assets/images/gallery/108.jpg";
import g109 from "../assets/images/gallery/109.jpg";
import g110 from "../assets/images/gallery/110.jpg";
import g111 from "../assets/images/gallery/111.jpg";
import g112 from "../assets/images/gallery/112.jpg";
import g113 from "../assets/images/gallery/113.jpg";
import g114 from "../assets/images/gallery/114.jpg";
import g115 from "../assets/images/gallery/115.jpg";
import g116 from "../assets/images/gallery/116.jpg";
import g117 from "../assets/images/gallery/117.jpg";
import g118 from "../assets/images/gallery/118.jpg";
import g119 from "../assets/images/gallery/119.jpg";
import g120 from "../assets/images/gallery/120.jpg";
import g121 from "../assets/images/gallery/121.jpg";
import g122 from "../assets/images/gallery/122.jpg";
import g123 from "../assets/images/gallery/123.jpg";

// import "../../App.css";
export const Gal = () => {
    

    

    // window.addEventListener('scroll', pauseVideo);

    return (
        <div>

           
            <section className="container" style={{ padding: "0px", display:'none' }}>

               
                <div class="row " style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                    <img className="card-img-top " src={g1} alt="Card cap " />
                            <img className="card-img-top " src={g2} alt="Card cap " />
                            <img className="card-img-top " src={g3} alt="Card cap " />
                            <img className="card-img-top " src={g4} alt="Card cap " />
                            <img className="card-img-top " src={g5} alt="Card cap " />
                            <img className="card-img-top " src={g6} alt="Card cap " />
                            <img className="card-img-top " src={g7} alt="Card cap " />
                            <img className="card-img-top " src={g8} alt="Card cap " />
                            <img className="card-img-top " src={g9} alt="Card cap " />
                            <img className="card-img-top " src={g10} alt="Card cap " />
                            <img className="card-img-top " src={g11} alt="Card cap " />
                            <img className="card-img-top " src={g12} alt="Card cap " />
                            <img className="card-img-top " src={g13} alt="Card cap " />
                            <img className="card-img-top " src={g14} alt="Card cap " />
                            <img className="card-img-top " src={g15} alt="Card cap " />
                            <img className="card-img-top " src={g16} alt="Card cap " />
                            <img className="card-img-top " src={g17} alt="Card cap " />
                            <img className="card-img-top " src={g18} alt="Card cap " />
                            <img className="card-img-top " src={g19} alt="Card cap " />
                            <img className="card-img-top " src={g20} alt="Card cap " />
                            <img className="card-img-top " src={g21} alt="Card cap " />
                            <img className="card-img-top " src={g22} alt="Card cap " />
                            <img className="card-img-top " src={g23} alt="Card cap " />
                            <img className="card-img-top " src={g24} alt="Card cap " />
                            <img className="card-img-top " src={g25} alt="Card cap " />
                            <img className="card-img-top " src={g26} alt="Card cap " />
                            <img className="card-img-top " src={g27} alt="Card cap " />
                            <img className="card-img-top " src={g28} alt="Card cap " />
                            <img className="card-img-top " src={g29} alt="Card cap " />
                            <img className="card-img-top " src={g30} alt="Card cap " />
                            <img className="card-img-top " src={g31} alt="Card cap " />
                            <img className="card-img-top " src={g32} alt="Card cap " />
                            <img className="card-img-top " src={g33} alt="Card cap " />
                            <img className="card-img-top " src={g34} alt="Card cap " />
                            <img className="card-img-top " src={g35} alt="Card cap " />
                            <img className="card-img-top " src={g36} alt="Card cap " />
                            <img className="card-img-top " src={g37} alt="Card cap " />
                            <img className="card-img-top " src={g38} alt="Card cap " />
                            <img className="card-img-top " src={g39} alt="Card cap " />
                            <img className="card-img-top " src={g40} alt="Card cap " />
                            <img className="card-img-top " src={g41} alt="Card cap " />
                            <img className="card-img-top " src={g42} alt="Card cap " />
                            <img className="card-img-top " src={g43} alt="Card cap " />
                            <img className="card-img-top " src={g44} alt="Card cap " />
                            <img className="card-img-top " src={g45} alt="Card cap " />
                            <img className="card-img-top " src={g46} alt="Card cap " />
                            <img className="card-img-top " src={g47} alt="Card cap " />
                            <img className="card-img-top " src={g48} alt="Card cap " />
                            <img className="card-img-top " src={g49} alt="Card cap " />
                            <img className="card-img-top " src={g50} alt="Card cap " />
                            <img className="card-img-top " src={g51} alt="Card cap " />
                            <img className="card-img-top " src={g52} alt="Card cap " />
                            <img className="card-img-top " src={g53} alt="Card cap " />
                            <img className="card-img-top " src={g54} alt="Card cap " />
                            <img className="card-img-top " src={g55} alt="Card cap " />
                            <img className="card-img-top " src={g56} alt="Card cap " />
                            <img className="card-img-top " src={g57} alt="Card cap " />
                            <img className="card-img-top " src={g58} alt="Card cap " />
                            <img className="card-img-top " src={g59} alt="Card cap " />
                            <img className="card-img-top " src={g60} alt="Card cap " />
                            <img className="card-img-top " src={g61} alt="Card cap " />
                            <img className="card-img-top " src={g62} alt="Card cap " />
                            <img className="card-img-top " src={g63} alt="Card cap " />
                            <img className="card-img-top " src={g64} alt="Card cap " />
                            <img className="card-img-top " src={g65} alt="Card cap " />
                            <img className="card-img-top " src={g66} alt="Card cap " />
                            <img className="card-img-top " src={g67} alt="Card cap " />
                            <img className="card-img-top " src={g68} alt="Card cap " />
                            <img className="card-img-top " src={g69} alt="Card cap " />
                            <img className="card-img-top " src={g70} alt="Card cap " />
                            <img className="card-img-top " src={g71} alt="Card cap " />
                            <img className="card-img-top " src={g72} alt="Card cap " />
                            <img className="card-img-top " src={g73} alt="Card cap " />
                            <img className="card-img-top " src={g74} alt="Card cap " />
                            <img className="card-img-top " src={g74} alt="Card cap " />
                            <img className="card-img-top " src={g75} alt="Card cap " />
                            <img className="card-img-top " src={g76} alt="Card cap " />
                            <img className="card-img-top " src={g77} alt="Card cap " />
                            <img className="card-img-top " src={g78} alt="Card cap " />
                            <img className="card-img-top " src={g79} alt="Card cap " />
                            <img className="card-img-top " src={g80} alt="Card cap " />
                            <img className="card-img-top " src={g81} alt="Card cap " />
                            <img className="card-img-top " src={g82} alt="Card cap " />
                            <img className="card-img-top " src={g83} alt="Card cap " />
                            <img className="card-img-top " src={g84} alt="Card cap " />
                            <img className="card-img-top " src={g85} alt="Card cap " />
                            <img className="card-img-top " src={g86} alt="Card cap " />
                            <img className="card-img-top " src={g87} alt="Card cap " />
                            <img className="card-img-top " src={g88} alt="Card cap " />
                            <img className="card-img-top " src={g89} alt="Card cap " />
                            <img className="card-img-top " src={g90} alt="Card cap " />
                            <img className="card-img-top " src={g91} alt="Card cap " />
                            <img className="card-img-top " src={g92} alt="Card cap " />
                            <img className="card-img-top " src={g93} alt="Card cap " />
                            <img className="card-img-top " src={g94} alt="Card cap " />
                            <img className="card-img-top " src={g95} alt="Card cap " />
                            <img className="card-img-top " src={g96} alt="Card cap " />
                            <img className="card-img-top " src={g97} alt="Card cap " />
                            <img className="card-img-top " src={g98} alt="Card cap " />
                            <img className="card-img-top " src={g99} alt="Card cap " />
                            <img className="card-img-top " src={g100} alt="Card cap " />
                            <img className="card-img-top " src={g101} alt="Card cap " />
                            <img className="card-img-top " src={g102} alt="Card cap " />
                            <img className="card-img-top " src={g103} alt="Card cap " />
                            <img className="card-img-top " src={g104} alt="Card cap " />
                            <img className="card-img-top " src={g105} alt="Card cap " />
                            <img className="card-img-top " src={g106} alt="Card cap " />
                            <img className="card-img-top " src={g107} alt="Card cap " />
                            <img className="card-img-top " src={g108} alt="Card cap " />
                            <img className="card-img-top " src={g109} alt="Card cap " />
                            <img className="card-img-top " src={g110} alt="Card cap " />
                            <img className="card-img-top " src={g111} alt="Card cap " />
                            <img className="card-img-top " src={g112} alt="Card cap " />
                            <img className="card-img-top " src={g113} alt="Card cap " />
                            <img className="card-img-top " src={g114} alt="Card cap " />
                            <img className="card-img-top " src={g115} alt="Card cap " />
                            <img className="card-img-top " src={g116} alt="Card cap " />
                            <img className="card-img-top " src={g117} alt="Card cap " />
                            <img className="card-img-top " src={g118} alt="Card cap " />
                            <img className="card-img-top " src={g119} alt="Card cap " />
                            <img className="card-img-top " src={g120} alt="Card cap " />
                            <img className="card-img-top " src={g121} alt="Card cap " />
                            <img className="card-img-top " src={g122} alt="Card cap " />
                            <img className="card-img-top " src={g123} alt="Card cap " />
                    </div>
                   
                    <div class="col-lg-1"></div>

                </div>
                



            </section>
            
        </div>
    );
};
