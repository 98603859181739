import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './assets/css/styles.css';
import './assets/css/colors.css';
import './assets/css/plugins/font-awesome.css';
import './assets/css/plugins/flaticon.css';
import './assets/css/plugins/bootstrap.min.css';
import Iframe from 'react-iframe';

export const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_5jg3ske', 'template_o86ulkp', form.current,
      'tNwTmvoIP5Jp6GXov')
      .then((result) => {
        console.log(result.text);
        window.location.reload(false);
      }, (error) => {
        console.log(error.text);
      });
  };
  
  return (
    <div>
      <section className="page-title" style={{ padding: "0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">

              <div className="breadcrumbs-wrap">
                <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Contact </h1>


              </div>

            </div>
          </div>
        </div>
      </section>

      <section className="bg-light">

        <div className="container">

          <div className="row">

            <div className="col-lg-8 col-md-7">
              <div className="prc_wrap">

                <div className="prc_wrap_header">
                  <h3 className="property_block_title">Enquire Now</h3>
                </div>

                <form ref={form} onSubmit={sendEmail}>

                  <div className="prc_wrap-body">
                    <div className="row">

                      <div className="col-lg-6 col-md-12">


                        <div className="form-group" style={{ textAlign: "left" }}>
                          <label >Name</label>
                          <input type="text" name="user_name" className="form-control simple" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group" style={{ textAlign: "left" }}>
                          <label>Email</label>
                          <input type="email" name="user_email" className="form-control simple" />
                        </div>
                      </div>
                    </div>
                    <div className="form-group" style={{ display:"none" }}>
                      <input name="enquiry_from" type="text" value="Message from Contact Us page" className="form-control simple" />
                    </div>

                    <div className="form-group" style={{ textAlign: "left" }}>
                      <label>Phone Number</label>
                      <input name="user_number" type="text" className="form-control simple" />
                    </div>

                    <div className="form-group" style={{ textAlign: "left" }}>
                      <label>Message</label>
                      <textarea name="message" className="form-control simple"></textarea>
                    </div>

                    <div className="form-group">

                      <button className="btn btn-theme" value="Send"  type="submit">Submit</button>
                    </div>
                  </div>
                </form>

              </div>

            </div>

            <div className="col-lg-4 col-md-5">

              <div className="prc_wrap">



                <div className="prc_wrap-body">
                  <div className="contact-info">




                    <div className="cn-info-detail">
                      <div className="cn-info-icon">
                        <i className="ti-home"></i>
                      </div>
                      <div className="cn-info-content" style={{ textAlign: "left" }}>
                        <h4 className="cn-info-title">Reach Us</h4>
                        #28, Club Road,<br />Srinivasan Nagar,<br />Chetpet Chennai - 600 031
                      </div>
                    </div>

                    <div className="cn-info-detail">
                      <div className="cn-info-icon">
                        <i className="ti-email"></i>
                      </div>
                      <div className="cn-info-content" style={{ textAlign: "left" }}>
                        <h4 className="cn-info-title">Drop A Mail</h4>
                        malayalee_club1897@yahoo.com<br />
                      </div>
                    </div>

                    <div className="cn-info-detail">
                      <div className="cn-info-icon">
                        <i className="ti-mobile"></i>
                      </div>
                      <div className="cn-info-content" style={{ textAlign: "left" }}>
                        <h4 className="cn-info-title">Call Us</h4>
                        044 28360733
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

      </section>
      <div className="row">
        <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.406299614084!2d80.24439237381206!3d13.073416912640388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52650023ffffff%3A0xd448ea14f09b83cc!2sThe%20Malayalee%20Club!5e0!3m2!1sen!2sin!4v1702130382500!5m2!1sen!2sin" width="100%" height="200px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />

      </div>

    </div>
  );
};
