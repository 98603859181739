import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export const Affiliation = () => {
  return (
    <div>
      <section className="page-title" style={{ padding: "0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">

              <div className="breadcrumbs-wrap">
                <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Affiliation</h1>
                <hr />

                <nav aria-label="breadcrumb">

                  <p>Bringing you the best of all worlds, our club has affiliations with the most prestigious clubs in the country located within cities and at holiday spots. Avail special rates when travelling and enjoy the same star privileges from this ever growing network. The club has got affiliations with more than fifty clubs at different parts of the country. More affiliations are being added keeping in mind the quality and convenience of our members.</p>
                </nav>
              </div>

            </div>
          </div>
        </div>
      </section>
      <Tabs
        defaultActiveKey="KERALA"
        id="fill-tab-example"
        className="mb-3 " fill style={{display:"inline-flex", flexDirection:"row"}}
      >
        <Tab eventKey="KERALA" title="KERALA" style={{width:"100%", color:"#000"}}>
          <div class="container">



            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">The Cosmopolitan Club</a></h4>
                    <p>Cosmo Junction, Pookkottur Road, Manjeri. <br />Ph: 48327 66843 <br />Email: cosmopolitan.manjeri@gmail.com</p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-2">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Bekal Club</a></h4>
                    <p>Padannakad, Kanhangad, Kasaragod,
                      Kerala 671328
                      <br />Ph: 0091-467 2204 609, 09995 84609
                      <br />E-mail: bekalclub@gmail.com
                    </p>
                  </div>
                </div>
              </div>


              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-3">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Trivandrum Club</a></h4>
                    <p>Vazhuthacaud,
                      Thiruvanthapuram-695010. <br />Ph: 0091-467 2204 609, 09995 84609 <br />E-mail: trivandrumclub@gmail.com</p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-4">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Reccaa Club</a></h4>
                    <p>Reccaa Valley, Off Seaport-Airport Road,
                      Kakkanad, Kochi-682030. <br />Ph: 095440 04335, 095443 43335. <br />E-mail: reccaacs@gmail.com</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-10">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">The Changanacherry Club</a></h4>
                    <p>Bye-Pass Road, Vazhappally P.O.,
                      Changanacherry-686103. <br />Ph: 62823 81682 <br />E-mail: changanacherryclub@gmail.com</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-6">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Banerji Memorial Club</a></h4>
                    <p>Swaraj Round N, Round North,
                      Thrissur, Kerala 680001 <br />Ph: 94463 7695 <br />E-mail: 1912banerji@gmail.com</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-7">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">The Citizen Club</a></h4>
                    <p>MC Road, Kodimatha, Muppaikad,
                      Kerala 686039. <br />Ph: 091-481-2361744, 95912 28966 <br />E-mail: thecitizensclubkottayam@gmail.com,
                      heritageclubmys@gmail.com </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-8">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">The Calicut Cosmopolitan Club</a></h4>
                    <p>8/360, Beach Rd, Next to All India Radio,
                      Kozhikode, Kerala 673032 <br />Ph: 0495-2761 767, 2761 539,
                      WHATSAPP: +91 97463 19721 <br />E-mail: calicutcosmoclub@gmail.com</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-9">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Century Club</a></h4>
                    <p>Century Club Lane, Vennala, P.O.,
                      Ernakulam, Kochi, Kerala 680028 <br />Ph: 0484 280 5387 <br />E-mail: centuryclubcochin@gmail.com</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Cochin Gymkhana</a></h4>
                    <p>Toc-H Nagar, Vyttila, Kochi,
                      Kerala 682019 <br />Ph: 0484 230 3071 <br />E-mail: mail@cochingymkhana.com.</p>
                  </div>
                </div>
              </div>




              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-2">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">The City Club</a></h4>
                    <p> Main Road, Sulthan Bathery,
                      Wayanad -673592<br /> Ph: 0493 6346539<br /></p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-3">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Cosmos Club</a></h4>
                    <p>Near Potta, NH Bye-pass,
                      Chalakudy, Kerala 680307 <br />Ph: 085890 08918/0480 2708918 <br />E-mail: cosmoschalakudy@yahoo.com</p>
                  </div>
                </div>
              </div>





              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-4">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Lotus Club</a></h4>
                    <p>Warriam Road, Cochin 682016
                      Kerala-INDIA <br />Ph: 914842352456/2366737 <br />Email: lotusclubcochin@yahoo.co.in</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-10">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Mannam Memorial National Club</a></h4>
                    <p>Press Club Road Behind,
                      Secretariat East Residents Association Rd,
                      Trivandrum, Kerala 695001 <br />Ph: 0471-2331128, 2336811, 2333599 / 8547556526 <br />E-mail: mmnctvm@gmail.com</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-6">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">The Munnar Club</a></h4>
                    <p>Kannan Devan Hills, Near KDH Club,
                      Idukki, Munnar, Kerala 685612 <br />Ph: 04865 231 781 <br />E-mail: reservation@deepwoodsmunnar.com</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-7">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Madhava Raja Club</a></h4>
                    <p>English church Road,
                      Palakkad-678014. <br />Ph: 0491-2522706, 2525354, 94969 10206 <br />E-mail: mrcpkd@yahoo.com,
                      madhavarajapkd1943@gmail.com</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-8">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>

                    <h4 class="title"><a href="#">The New Cosmopolitan Club</a></h4>
                    <p>Near police station, Palissery,
                      Thalassery, Kerala 670101 <br />Ph: 0490-232 2390 <br />E-mail: newcosmotly@gmail.com</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-9">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>

                    <h4 class="title"><a href="#">National Club</a></h4>
                    <p>KUMBANAD, Vattakottal,
                      Thiruvalla, Kerala 689547 <br />Ph: 0469 266 5977 <br />E-mail: thekoramangalaclub@gmail.com</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">The Officers Club</a></h4>
                    <p>420/1996 Thiruthiyad,
                      Kozhikode-673004. <br />Ph: 0495 277 1017 <br />E-mail: officersclubcalicut@gmail.com</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-2">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Periyar Club</a></h4>
                    <p>Desom PO. Swargam Road.
                      Aluva Pin 683102. Kerala <br />Ph: 0484 4063083/94005 89963 <br />E-mail: periyarclubdesom@gmail.com</p>
                  </div>
                </div>
              </div>






              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-3">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Palghat Cosmopolitan Club</a></h4>
                    <p>N.H. Bye-Pass Road,
                      Salem-Kanyakumari Highway,
                      Kadamkod, Kalmandapam,
                      Palakkad, Kerala 678013 <br />Ph: 0491-2538866, 2504848/94461 82866 <br />E-mail: cosmopgt@gmail.com</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-4">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Ramavarma Club</a></h4>
                    <p>Thevally, Kollam,
                      Kerala 691009 <br />Ph : 0474-2792587 <br />E-mail : collam@gmail.com</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-10">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Regency Club</a></h4>
                    <p>Club Road, Thottapady, Kuttancllur,
                      Thrissur, Kerala 680014 <br />Ph : 0487 235 3101 <br />E-mail : booking©regencycountryclub.com</p>
                  </div>
                </div>
              </div>




              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-6">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Ramavarrna Club</a></h4>
                    <p>Club Rd, Opp Durbar Hall Ground,
                      Marine Drive. Ernakulam,
                      Kerala 682011 <br />Ph : 0484 235 2040 / 236 5806 <br />E-mail : ramavarmaclubkochi@gmail.com</p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-7">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Travancore Club</a></h4>
                    <p>Perumthuruthy,
                      Kerala 689107 <br />Ph : 085475 45909 <br />E-mail : travancorcclubthiruvalla@gmai|-com.</p>
                  </div>
                </div>
              </div>





              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-8">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>

                    <h4 class="title"><a href="#">The United Club</a></h4>
                    <p>Mangalassery - Chirangara Rd, Karukutty,
                      Kerala 680308 <br />Ph : 0480 273 0733 <br />E-mail : theunitcdclub@yahoo.com</p>
                  </div>
                </div>
              </div>




              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-9">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>

                    <h4 class="title"><a href="#">Varkala Club</a></h4>
                    <p>Varkala, Kerala 695141 <br />Ph: 0821 2412377 / 2974377 /
                      95912 28966 / 0470 260 6438 <br />E-mail : heritageclubmysénail.com
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">The Hill District Club</a></h4>
                    <p>No AP/111/36C, Kolagappara,
                      Meenangadi PO,
                      Vaduvanchal - Kolagappara Road,
                      Wayanad District, Kolagapaara,
                      Kerala-673591 <br />Ph : 85899 50377 <br />E-mail : info@hilldistrictdub.com   </p>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </Tab>



        <Tab eventKey="TAMIL NADU" title="TAMIL NADU" style={{width:"100%", color:"#000"}}>
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">The Kerala Club
                    </a></h4>

                    <p>
                      78 80, ATT Colony, Gopalapuram,
                      Coimbatore, Tamil Nadu 641018

                      <br />Ph : 89033 53800
                      <br />E-mail : thekeralaclub@gmail.com


                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-2">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Gudalur Cosmopolitan Club
                    </a></h4>

                    <p>
                      PB- No.61, M-T- Nagar, Mysore Road,
                      Gudalur - 643212.
                      <br />Ph : 04262-261842, 089035 61842.
                      <br />E-mail : gudalurcosmo@gmail.com
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-3">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Jenney Club

                    </a></h4>

                    <p>2. 2, Avinashi Rd, Peelamedu,
                      Dr. Jaganathan Nagar,
                      Coimbatore. Tamil Nadu 641014

                      <br />Ph : 0422 433 5777 / 0422 2575552


                      <br />E-mail : booking@jenneyresidency.in


                    </p>
                  </div>
                </div>
              </div>


            </div>

          </div>

        </Tab>
        <Tab eventKey="KARNATAKA" title="KARNATAKA" style={{width:"100%", color:"#000"}}>
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">East Cultural Association</a></h4>

                    <p>
                      8, 100 Fee! Road, Indiranagar,
                      Bcngaluru, Karnataka 560095

                      <br />Ph : 0802526 2058, 2526 0388, 2527 5241
                      <br />E-mail : castculturalassociation@gmail.com

                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-2">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">The Club</a></h4>

                    <p>
                      Mysore Rd, SLV layout, Phase 3,
                      Nayanda Halli, Bengaluru,
                      Karnataka 560039

                      <br />Ph : 080 28600665, 28600768 / 98458 30506

                      <br />E-mail : thedubblr@gmail.com

                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-3">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Jain Farm & Resorts Ltd.
                    </a></h4>

                    <p>No-59/1, 3rd Floor, Nakoda Arcade,
                      DVG Road, Basavanagudi,
                      Bengaluru, Karnataka 560004

                      <br />Ph : 080674 15551

                      <br />E-mail : nature@jainfarms.com

                    </p>
                  </div>
                </div>
              </div>


            </div>

          </div>

        </Tab>
        <Tab eventKey="RAJASTHAN" title="RAJASTHAN" style={{width:"100%", color:"#000"}}>
          <div class="container">



            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Jaisal Club</a></h4>

                    <p>
                      Jethwai Road , RIICO Colony
                      Jaisalmer-345001
                      Rajasthan, India.
                      <br />Ph : 02992 255 555 / 99503 32222
                      <br />E-mail : info@jaisalclub.com
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-2">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Gym Khana Club Jodhpur</a></h4>

                    <p>
                      Mini Market, Uchiyarda Rd,
                      Shikargarh, Palace, Jodhpur,
                      Rajasthan 342015
                      <br />Ph : 094141 49526 / 0291 2511390 I
                      <br />jodhpurgymkhana©rcdiffmaiLcom
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-3">
                <div class="">

                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Golden City Club
                    </a></h4>

                    <p>Jethwai Road, Transport Nagar,
                      Jaisalmer - 345001,
                      <br />Ph : 02992-254558, 099281 28293,
                      86968 91200-
                      <br />E-mail : nicerajasthan293@gmail.com
                    </p>
                  </div>
                </div>
              </div>


            </div>

          </div>

        </Tab>
        <Tab eventKey="MAHARASHTRA" title="MAHARASHTRA" style={{width:"100%", color:"#000"}}>
          <div class="container">



            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Spring Time Club</a></h4>

                    <p>
                      Club Road, opp. KDMC B Ward Office,
                      Khadakpada, Bhoirwadi, Kalyan,
                      Maharashtra 421301
                      <br />Ph : 0251 223 3331 I 0251 2230597
                      <br />E-mail : crm@springtimeclub.com
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-2">
                <div class="">
                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#"> Nasiklub</a></h4>

                    <p>
                      Sarda Activity Centre ’Nandinee',
                      Nashik - Pune Road, Nashik,
                      Maharashtra 422011
                      <br />Ph : 0253 241 1254 / 0253 241 7931
                      <br />E-mail : reservation@nasiklub.in
                    </p>
                  </div>
                </div>
              </div>


            </div>

          </div>

        </Tab>
        <Tab eventKey="GUJARAT" title="GUJARAT" style={{width:"100%", color:"#000"}}>
          <div class="container">



            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                <div class="">




                  <div class="edu_cat_data" style={{ textAlign: "left" }}>
                    <h4 class="title"><a href="#">Umbergoan Club</a></h4>

                    <p>
                      Madhuvan Complex,
                      Sanjan - Umbergaon Rd,<br />
                      Umargam, Gujarat 396171
                      <br />Ph : 0260 2561 122 / 026025 62464 /<br />0260 2561 122 / 026025 62464 /

                      <br />E-mail : info@umbergoanclub.com
                    </p>
                  </div>
                </div>
              </div>


            </div>

          </div>

        </Tab>
      </Tabs>

    </div>
  );
};
