import React, { useEffect } from "react";
import './assets/css/styles.css';
import './assets/css/colors.css';
import './assets/css/plugins/font-awesome.css';
import './assets/css/plugins/flaticon.css';
import './assets/css/plugins/bootstrap.min.css';
import m1 from "./assets/images/management/1.jpg";
import m2 from "./assets/images/management/2.jpg";
import m3 from "./assets/images/management/3.jpg";
import m4 from "./assets/images/management/4.jpg";
import m5 from "./assets/images/management/5.jpg";
import m6 from "./assets/images/management/6.jpg";
import m7 from "./assets/images/management/7.jpg";
import m8 from "./assets/images/management/8.jpg";
import m9 from "./assets/images/management/9.jpg";
import m10 from "./assets/images/management/10.jpg";
import m11 from "./assets/images/management/11.jpg";
import m12 from "./assets/images/management/12.jpg";
import m13 from "./assets/images/management/13.jpg";
import m14 from "./assets/images/management/14.jpg";
import m15 from "./assets/images/management/15.jpg";
import m16 from "./assets/images/management/16.jpg";
import m17 from "./assets/images/management/17.jpg";

import history from "./assets/images/history.png";
import historyOld from "./assets/images/historyOld.png";
import aboutBanner1 from "./assets/images/carosal/1.jpg";
import aboutBanner2 from "./assets/images/carosal/2.jpg";
import aboutBanner3 from "./assets/images/carosal/3.jpg";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { useState } from 'react';
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';


export const About = () => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "./assets/js/imagelightbox.min.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);


    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const handleShow = () => setShow(true);


    const handleShowActivities = () => setShow1(true);



    return (
        <div>
            <div>






                <div className="row align-items bannerRow" id="profileRedirect" >
                    {/* <div className="col-lg-7 col-sm-12 alignValue" style={{ paddingRight: "0px", alignSelf: "self-end" }}>

                        <img style={{ width: "100%", height: "auto", position: "relative", zIndex: "2" }} src={BannerImage} alt="" />

                    </div> */}

                    <div className="col-lg-12 " style={{ alignSelf: "end", paddingLeft: "0px" }}>
                        <MDBCarousel showControls showIndicators style={{ boxShadow: "rgb(136, 136, 136) 0px 4px 5px" }} touch={false}>
                            <MDBCarouselItem itemId={1}>
                                <img style={{ width: "100%" }} src={aboutBanner1} alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={2}>
                                <img style={{ width: "100%" }} src={aboutBanner2} alt='...' />
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId={2}>
                                <img style={{ width: "100%" }} src={aboutBanner3} alt='...' />
                            </MDBCarouselItem>


                        </MDBCarousel>



                    </div>




                </div>
                <br />
                <section id="historyRedirect" className="container">
                    <div>

                        <div className="row">

                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <h1 style={{ textAlign: "left", fontSize: "25px", color: "#222a35" }}>The Beginning<br /></h1>
                                <hr />
                            </div>
                            <div className="col-lg-7 col-md-12 col-sm-12">
                                <div style={{ boxSizing: "content-box", }}>


                                    <p style={{ textAlign: "justify", color: "gray" }}>The Malayalee Club started off as a small meeting place of the Malayalee elite of then city of Madras way back in 1897 on a piece of land of its own. A small Club house was initially constructed where Madras malayalees congregated in the evenings to enjoy each others company to discuss state and social issues to relax with a pack of cards or to enjoy a cup of tea after a day's hard work. As the membership of the club grew so did the activities. To facilitate the conduct of cultural events by troupes brought specially from Kerala, a small stage with an open auditorium was constructed. On non - performance days the open air auditorium doubled as a Tennis or Badminton court.<br /><br />

                                        Still later in its Journey, with the club metamorphosing into a family institution, a large ,covered auditorium was constructed. A restaurant serving authentic Kerala cuisine became a major attraction. A spacious, well- stacked library , a comfortable card room and a trendy bar were added on in addition to meeting halls and better laid office area. The Malayalee Club earned the reputation of being a very vibrant and dynamic institution with calendar full of events.
                                    </p>


                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12 col-sm-12">
                                <img style={{ width: "100%" }} src={historyOld} alt='...' />

                            </div>
                            {/* <div className="col-lg-6 col-md-12 col-sm-12" >
                                <div onClick={handleShowActivities} className="gallery-wrap sliderHeight" id="activitiesClick" style={{ fontSize: "30px", cursor: "pointer" }} >
                                    <div className="itemm text_faci itemm-17">
                                    </div>
                                    <div className="itemm text_faci itemm-18">
                                    </div>
                                    <div className="itemm text_faci itemm-19">
                                    </div>

                                </div>

                            </div> */}
                        </div>
                    </div>
                </section>


                <div className="container">

                    <div className="row">

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            {/* <div className="dashboard_container"> */}
                            {/* <div className="dashboard_container_header"> */}
                            {/* <div className="dashboard_fl_1"> */}

                            <h1 style={{ textAlign: "left", fontSize: "28px", color: "#222a35" }}>Evolving with the times</h1>
                            <hr />
                            <img style={{ width: "100%", height: "auto" }} src={history} alt='...' />
                            <p style={{ textAlign: "justify" }}><br />Later in its journey, with the Club evolving into a family institution, a large, covered auditorium was constructed. A restaurant serving authentic Kerala cuisine became a major attraction. The main club house itself went for renovation and additions. A spacious, well-stacked library, a comfortable card room and a trendy bar were added, in addition to meeting halls and a better laid out office area. The Malayalee Club became a vibrant and dynamic institution and a symbol of pride for the diaspora.

                                <br /><br /> All along, The Club has always been in the forefront, nurturing and promoting the lifestyle and cultural strength of Kerala. Various festivals of Kerala including Music and Drama performances, Kavi Sammelanams, etc. find a reflection in Chennai through the Malayalee Club.

                                <br /><br /> At the same time, the Club ensures that the Tamil majority and other linguistic communities are not excluded from its activities. In fact, a prime goal of the Malayalee Club has been to ensure a harmonious integration of the Malayalee ethos with the much larger Tamil milieu.
                            </p>
                            <ul className="columnTwo" style={{ listStyleType: "circle", padding: "0px 20px 0px 20px" }}>
                                <li style={{ listStyleType: "circle" }}>
                                    A state- of- the- art 700 seater air-conditioned auditorium
                                </li>
                                <li>
                                    4 Conference / Banquet Halls (50/150 seater)

                                </li>
                                <li>
                                    2 Board Rooms / Meeting Rooms

                                </li>
                                <li>
                                    Well-stocked Bar

                                </li>
                                <li>
                                    Multi- cuisine Restaurant

                                </li>
                                <li>
                                    Club-house with indoor games facilities

                                </li>
                                <li>
                                    Well stocked library with Internet browsing

                                </li>
                                <li>
                                    Spacious basement car-parking

                                </li>
                                <li>
                                    24 luxurious four-star grade suite rooms

                                </li>
                                <li>
                                    Weekly entertainment programs

                                </li>
                                <li>
                                    Arts and Culture Training Center

                                </li>
                                <li>
                                    Youth affairs group for skill development / Start-up Incubation / <br />Entrepreneurship development

                                </li>
                                <li>
                                    Sports facilities with Badminton / Table Tennis / Card Room / Chess / Carroms / <br />Kids Center

                                </li>
                                <li>
                                    All under one roof: A 72000 sq.ft. centrally air-conditioned building

                                </li>
                            </ul>

                            {/* </div> */}
                            {/* </div> */}
                            <section className="" >


                                <div className="sec-heading center ">
                                    <h1 style={{ textAlign: "left", fontSize: "25px", color: "#222a35" }}>Management</h1>
                                    <hr />
                                </div>


                                <div class="row " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                    <div class="col-lg-1"></div>
                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m1} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. N. R. Panicker<span style={{ fontSize: "10px", fontWeight: "100" }}> <br /> PRESIDENT</span></a></h6>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m12} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. P N Ravi<span style={{ fontSize: "10px", fontWeight: "100" }}> <br /> SECRETARY</span></a></h6>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m3} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr.S T Prabhu<span style={{ fontSize: "10px", fontWeight: "100" }}> <br /> TREASURER</span></a></h6>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m4} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. Gopakumaran Nair<span style={{ fontSize: "10px", fontWeight: "100" }}> <br /> VICE PRESIDENT</span></a></h6>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m5} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. Vijayan Mukundan<span style={{ fontSize: "10px", fontWeight: "100" }}> <br /> JT. SECRETARY</span></a></h6>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-1"></div>

                                </div>
                                <div class="row " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                    {/* <div class="col-lg-1"></div> */}

                                    {/* 2 */}
                                    {/* <div class="col-lg-1"></div> */}
                                    <div class="col-lg-1"></div>

                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m6} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. M P Farook</a></h6>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m7} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. G P Vijay Kumar</a></h6>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m9} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Ms. Meera Krishnankutty</a></h6>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m10} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Ms. Latha Mohan</a></h6>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m11} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. Jose Varghese</a></h6>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-1"></div>
                                </div>
                                <div class="row " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m2} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. A N Gireeshan</a></h6>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div class="col-lg-1"></div> */}
                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m13} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. K Sivakumar</a></h6>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m14} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. A R Vinod Kumar Nair</a></h6>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m15} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. Zachariah Cheriyan</a></h6>
                                                    {/* <span style={{ visibility:"hidden" }}>1</span> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div class="col-lg-1"></div>*/}

                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m16} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. A M Gopalan <span style={{ fontSize: "10px", fontWeight: "100" }}> <br /> PAST PRESIDENT</span></a></h6>
                                                    {/* <span style={{ fontSize: "10px" }}>Past President</span> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                                        <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
                                            <img className="card-img-top " src={m17} alt="Card cap " />
                                            <div className="card-body ">
                                                <div className="instructor_caption " style={{ textAlign: "center" }}>
                                                    <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Mr. D Ganesan<span style={{ fontSize: "10px", fontWeight: "100" }}> <br /> PAST SECRETARY</span></a></h6>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="col-lg-4"></div> */}

                                </div>


                            </section>
                            <div id="affiliationRedirect" className="dashboard_container_body">
                                <h1 style={{ textAlign: "left", fontSize: "25px", color: "#222a35" }}>The Journey Continues...<br /></h1>
                                <hr />
                                <div className="table-responsive fixTableHead" >
                                    <table className="table search-table  ">
                                        <thead className="thead-dark" >
                                            <tr >
                                                <th style={{ textAlign: "left" }} scope="col">Year</th>
                                                <th style={{ textAlign: "left" }} scope="col">President</th>
                                                <th style={{ textAlign: "left" }} scope="col">Secretary</th>
                                                <th style={{ textAlign: "left" }} scope="col">Treasurer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <th scope="row">#0000149</th>
                <td>02 July 2020</td>
                <td>In Progress</td>
                <td>$110.00</td> */}
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2023 - 2024 </td>
                                                <td style={{ textAlign: "left" }}>N.R. Panicker</td>
                                                <td style={{ textAlign: "left" }}>P N Ravi</td>
                                                <td style={{ textAlign: "left" }}>S T Prabhu</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2022 - 2023 </td>
                                                <td style={{ textAlign: "left" }}>N.R. Panicker</td>
                                                <td style={{ textAlign: "left" }}>A N Gireeshan</td>
                                                <td style={{ textAlign: "left" }}>S T Prabhu</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2021 - 2022 </td>
                                                <td style={{ textAlign: "left" }}>A.M. Gopalan</td>
                                                <td style={{ textAlign: "left" }}>D. Ganesan</td>
                                                <td style={{ textAlign: "left" }}>J. Manoj Kumar</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2020 - 2021 </td>
                                                <td style={{ textAlign: "left" }}>A.M.Gopalan </td>
                                                <td style={{ textAlign: "left" }}>D.Ganesan</td>
                                                <td style={{ textAlign: "left" }}>J. Manoj Kumar</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2019 - 2020 </td>
                                                <td style={{ textAlign: "left" }}>A.M.Gopalan </td>
                                                <td style={{ textAlign: "left" }}>V. P. Kuriachan </td>
                                                <td style={{ textAlign: "left" }}>Abraham Zacharia</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2018 - 2019 </td>
                                                <td style={{ textAlign: "left" }}>A.M.Gopalan </td>
                                                <td style={{ textAlign: "left" }}>V.P. Kuriachan</td>
                                                <td style={{ textAlign: "left" }}>Abraham Zacharia</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2017 - 2018 </td>
                                                <td style={{ textAlign: "left" }}>M. Nanda Govind </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>K.V.Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2016 - 2017 </td>
                                                <td style={{ textAlign: "left" }}>M. Nanda Govind </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>K.V.Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2015 - 2016 </td>
                                                <td style={{ textAlign: "left" }}>M. Nanda Govind </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>K.V.Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2014 - 2015 </td>
                                                <td style={{ textAlign: "left" }}>M. Nanda Govind </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>K.V.Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2013 - 2014 </td>
                                                <td style={{ textAlign: "left" }}>M. Nanda Govind </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2012 - 2013 </td>
                                                <td style={{ textAlign: "left" }}>M. Nanda Govind </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2011 - 2012 </td>
                                                <td style={{ textAlign: "left" }}>M. Nanda Govind </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2010 - 2011 </td>
                                                <td style={{ textAlign: "left" }}>M. Nanda Govind </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2009 - 2010 </td>
                                                <td style={{ textAlign: "left" }}>M. Nanda Govind </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2008 - 2009 </td>
                                                <td style={{ textAlign: "left" }}>M. Nanda Govind </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2007 - 2008 </td>
                                                <td style={{ textAlign: "left" }}>M. Nanda Govind </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2006 - 2007 </td>
                                                <td style={{ textAlign: "left" }}>M. Nanda Govind </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>John Paul</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2005 - 2006 </td>
                                                <td style={{ textAlign: "left" }}>M. Nanda Govind </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>John Paul</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2004 - 2005 </td>
                                                <td style={{ textAlign: "left" }}>M. Nanda Govind </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>John Paul</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2003 - 2004 </td>
                                                <td style={{ textAlign: "left" }}>M. Nanda Govind </td>
                                                <td style={{ textAlign: "left" }}>K V Mamu Koya</td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2002 - 2003</td>
                                                <td style={{ textAlign: "left" }}>V.P. Sidhan </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>KV Mamukoya </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2001 - 2002 </td>
                                                <td style={{ textAlign: "left" }}>K Vasudevan </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                                <td style={{ textAlign: "left" }}>Jose Thomas </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>2000 - 2001 </td>
                                                <td style={{ textAlign: "left" }}>M K Kumar</td>
                                                <td style={{ textAlign: "left" }}>KV Nair </td>
                                                <td style={{ textAlign: "left" }}>M. Padmanabhan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1999 - 2000 </td>
                                                <td style={{ textAlign: "left" }}>Dr. P. Balakrishnan </td>
                                                <td style={{ textAlign: "left" }}>A.V. Anoop</td>
                                                <td style={{ textAlign: "left" }}>K. Jayakumar</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1998 - 1999 </td>
                                                <td style={{ textAlign: "left" }}>Dr. P. Balakrishnan </td>
                                                <td style={{ textAlign: "left" }}>A.V. Anoop</td>
                                                <td style={{ textAlign: "left" }}>G. Vijayakumar</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1997 - 1998 </td>
                                                <td style={{ textAlign: "left" }}>K. Ravindran</td>
                                                <td style={{ textAlign: "left" }}>A.V. Anoop</td>
                                                <td style={{ textAlign: "left" }}>G. Vijayakumar</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1996 - 1997 </td>
                                                <td style={{ textAlign: "left" }}>K. Ravindran</td>
                                                <td style={{ textAlign: "left" }}>A.V. Anoop</td>
                                                <td style={{ textAlign: "left" }}>G. Vijayakumar</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1995 - 1996 </td>
                                                <td style={{ textAlign: "left" }}>K. Ravindran</td>
                                                <td style={{ textAlign: "left" }}>A.V. Anoop</td>
                                                <td style={{ textAlign: "left" }}>G. Vijayakumar</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1994 - 1995</td>
                                                <td style={{ textAlign: "left" }}>K. Ravindran</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>A.V. Anoop</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1993 - 1994 </td>
                                                <td style={{ textAlign: "left" }}>K. Ravindran</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>A.V. Anoop</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1992 - 1993 </td>
                                                <td style={{ textAlign: "left" }}>K. Ravindran</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>A.V. Anoop</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1991 - 1992 </td>
                                                <td style={{ textAlign: "left" }}>K. Vasudevan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>A.V. Anoop</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1990 - 1991 </td>
                                                <td style={{ textAlign: "left" }}>K. Vasudevan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>K. Narayanan Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1989 - 1990 </td>
                                                <td style={{ textAlign: "left" }}>K. Vasudevan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>A. Sethumadhavan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1988 - 1989 </td>
                                                <td style={{ textAlign: "left" }}>K. Vasudevan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>A. Sethumadhavan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1987 - 1988 </td>
                                                <td style={{ textAlign: "left" }}>K. Vasudevan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>A. Sethumadhavan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1986 - 1987 </td>
                                                <td style={{ textAlign: "left" }}>K. Vasudevan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>A. Sethumadhavan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1985 - 1986 </td>
                                                <td style={{ textAlign: "left" }}>K. Vasudevan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>A. Sethumadhavan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1984 - 1985 </td>
                                                <td style={{ textAlign: "left" }}>K. Vasudevan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>A. Sethumadhavan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1983 - 1984 </td>
                                                <td style={{ textAlign: "left" }}>K. Vasudevan</td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>A. Sethumadhavan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1982 - 1983 </td>
                                                <td style={{ textAlign: "left" }}>P. Chandrasekhara Menon </td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>A. Sethumadhavan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1981 - 1982 </td>
                                                <td style={{ textAlign: "left" }}>P. Chandrasekhara Menon </td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>A. Sethumadhavan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1980 - 1981 </td>
                                                <td style={{ textAlign: "left" }}>P. Chandrasekhara Menon </td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>T.K. Ramakrishna Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1979 - 1980 </td>
                                                <td style={{ textAlign: "left" }}>P. Chandrasekhara Menon </td>
                                                <td style={{ textAlign: "left" }}>K.V. Nair</td>
                                                <td style={{ textAlign: "left" }}>K.V.Krishnan Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1978 - 1979 </td>
                                                <td style={{ textAlign: "left" }}>P. Chandrasekhara Menon </td>
                                                <td style={{ textAlign: "left" }}>M. Girijavallabhan</td>
                                                <td style={{ textAlign: "left" }}>T.K. Ramakrishna Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1977 - 1978 </td>
                                                <td style={{ textAlign: "left" }}>P. Chandrasekhara Menon </td>
                                                <td style={{ textAlign: "left" }}>M. Girijavallabhan</td>
                                                <td style={{ textAlign: "left" }}>P.V. Raghava Varier </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1976 - 1977 </td>
                                                <td style={{ textAlign: "left" }}>V.K. Eradi</td>
                                                <td style={{ textAlign: "left" }}>P. Chandrasekhra Menon</td>
                                                <td style={{ textAlign: "left" }}>M. Girijavallabhan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1975 - 1976 </td>
                                                <td style={{ textAlign: "left" }}>V.K. Eradi</td>
                                                <td style={{ textAlign: "left" }}>P. Chandrasekhra Menon</td>
                                                <td style={{ textAlign: "left" }}>S.A.N. Sarma</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1974 - 1975 </td>
                                                <td style={{ textAlign: "left" }}>V.K. Eradi</td>
                                                <td style={{ textAlign: "left" }}>P. Chandrasekhra Menon</td>
                                                <td style={{ textAlign: "left" }}>P.K. Menon</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1973 - 1974 </td>
                                                <td style={{ textAlign: "left" }}>V.K. Eradi</td>
                                                <td style={{ textAlign: "left" }}>P.K. Menon</td>
                                                <td style={{ textAlign: "left" }}>M. Girijavallabhan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1972 - 1973 </td>
                                                <td style={{ textAlign: "left" }}>V.K. Eradi</td>
                                                <td style={{ textAlign: "left" }}>P.K. Menon</td>
                                                <td style={{ textAlign: "left" }}>M. Girijavallabhan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1971 - 1972 </td>
                                                <td style={{ textAlign: "left" }}>V.K. Eradi</td>
                                                <td style={{ textAlign: "left" }}>P.K. Menon</td>
                                                <td style={{ textAlign: "left" }}>A.S. Menon</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1970 - 1971 </td>
                                                <td style={{ textAlign: "left" }}>P.N. Menon</td>
                                                <td style={{ textAlign: "left" }}>K. Madhavan Nair</td>
                                                <td style={{ textAlign: "left" }}>A.S. Menon</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1969 - 1970 </td>
                                                <td style={{ textAlign: "left" }}>P.N. Menon</td>
                                                <td style={{ textAlign: "left" }}>K. Madhavan Nair</td>
                                                <td style={{ textAlign: "left" }}>T. Sankara Wariar</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1968 - 1969 </td>
                                                <td style={{ textAlign: "left" }}>P.N. Menon</td>
                                                <td style={{ textAlign: "left" }}>N.K.K. Menon</td>
                                                <td style={{ textAlign: "left" }}>N.S. Purushothaman</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1967 - 1968 </td>
                                                <td style={{ textAlign: "left" }}>P.N. Menon</td>
                                                <td style={{ textAlign: "left" }}>K.V. Balraj </td>
                                                <td style={{ textAlign: "left" }}>N.S. Purushothaman</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1966 - 1967 </td>
                                                <td style={{ textAlign: "left" }}>K. Krishnan Menon</td>
                                                <td style={{ textAlign: "left" }}>P. Chandran </td>
                                                <td style={{ textAlign: "left" }}>M. Krishnan Unnithan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1965 - 1966 </td>
                                                <td style={{ textAlign: "left" }}>K. Krishnan Menon</td>
                                                <td style={{ textAlign: "left" }}>P. Chandran </td>
                                                <td style={{ textAlign: "left" }}>M. Krishnan Unnithan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1964 - 1965 </td>
                                                <td style={{ textAlign: "left" }}>K. Krishnan Menon</td>
                                                <td style={{ textAlign: "left" }}>M.S. Warier </td>
                                                <td style={{ textAlign: "left" }}>K.V. Balakrishnan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1963 - 1964 </td>
                                                <td style={{ textAlign: "left" }}>K. Krishnan Menon</td>
                                                <td style={{ textAlign: "left" }}>P. Chandran </td>
                                                <td style={{ textAlign: "left" }}>K.V. Balakrishnan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1962 - 1963 </td>
                                                <td style={{ textAlign: "left" }}>K. Krishnan Menon</td>
                                                <td style={{ textAlign: "left" }}>M. Viswanathan Nair </td>
                                                <td style={{ textAlign: "left" }}>K.V. Balakrishnan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1961 - 1962 </td>
                                                <td style={{ textAlign: "left" }}>K. Krishnan Menon</td>
                                                <td style={{ textAlign: "left" }}>V.K. Eradi</td>
                                                <td style={{ textAlign: "left" }}>K.V. Balakrishnan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1960 - 1961 </td>
                                                <td style={{ textAlign: "left" }}>K. Krishnan Menon</td>
                                                <td style={{ textAlign: "left" }}>K.V. Balakrishnan</td>
                                                <td style={{ textAlign: "left" }}>M. Viswanathan Nair </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1959 - 1960 </td>
                                                <td style={{ textAlign: "left" }}>V.R. Ramanatha Iyer </td>
                                                <td style={{ textAlign: "left" }}>V.K. Eradi</td>
                                                <td style={{ textAlign: "left" }}>M. Viswanathan Nair </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1958 - 1959 </td>
                                                <td style={{ textAlign: "left" }}>V.R. Ramanatha Iyer </td>
                                                <td style={{ textAlign: "left" }}>V.R. Narayanan</td>
                                                <td style={{ textAlign: "left" }}>M. Viswanathan Nair </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1957 - 1958 </td>
                                                <td style={{ textAlign: "left" }}>V.R. Ramanatha Iyer </td>
                                                <td style={{ textAlign: "left" }}>V.K. Eradi</td>
                                                <td style={{ textAlign: "left" }}>R Parameswaran Nair </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1956 - 1957 </td>
                                                <td style={{ textAlign: "left" }}>K. Kuttikrishna Menon</td>
                                                <td style={{ textAlign: "left" }}>V. Balakrishnan Eradi</td>
                                                <td style={{ textAlign: "left" }}>R Parameswaran Nair </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1955 - 1956 </td>
                                                <td style={{ textAlign: "left" }}>K. Kuttikrishna Menon</td>
                                                <td style={{ textAlign: "left" }}>V. Balakrishnan Eradi</td>
                                                <td style={{ textAlign: "left" }}>R Parameswaran Nair </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1954 - 1955 </td>
                                                <td style={{ textAlign: "left" }}>K. Kuttikrishna Menon</td>
                                                <td style={{ textAlign: "left" }}>V. Balakrishnan Eradi</td>
                                                <td style={{ textAlign: "left" }}>M. Viswanathan Nair </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1953 - 1954 </td>
                                                <td style={{ textAlign: "left" }}>K. Kuttikrishna Menon</td>
                                                <td style={{ textAlign: "left" }}>Ravi Varma</td>
                                                <td style={{ textAlign: "left" }}>M. Viswanathan Nair </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1952 - 1953 </td>
                                                <td style={{ textAlign: "left" }}>K. Kuttikrishna Menon</td>
                                                <td style={{ textAlign: "left" }}>K.B. Panicker</td>
                                                <td style={{ textAlign: "left" }}>K. Narendranath </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1951 - 1952 </td>
                                                <td style={{ textAlign: "left" }}>K. Kuttikrishna Menon</td>
                                                <td style={{ textAlign: "left" }}>K.N. Sreedharan Nair</td>
                                                <td style={{ textAlign: "left" }}>C. Bhanu</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1950 - 1951 </td>
                                                <td style={{ textAlign: "left" }}>K. Kuttikrishna Menon</td>
                                                <td style={{ textAlign: "left" }}>C.V. Mannadiar</td>
                                                <td style={{ textAlign: "left" }}>K.N. Sreedharan Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1949 - 1950 </td>
                                                <td style={{ textAlign: "left" }}>K. Kuttikrishna Menon</td>
                                                <td style={{ textAlign: "left" }}>C.V. Mannadiar</td>
                                                <td style={{ textAlign: "left" }}>K.N. Sreedharan Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1948 - 1949 </td>
                                                <td style={{ textAlign: "left" }}>K. Kuttikrishna Menon</td>
                                                <td style={{ textAlign: "left" }}>Ravi Varma</td>
                                                <td style={{ textAlign: "left" }}>K. Ramanatha Menon</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1947 - 1948 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>Rao Bahadur C.B. Nair</td>
                                                <td style={{ textAlign: "left" }}>T.K. Raman Nambisan </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1946 - 1947</td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>K.P. Balakrishna Menon</td>
                                                <td style={{ textAlign: "left" }}>K.N. Krishnan Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1945 - 1946 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>Rao Bahadur C.B. Nair</td>
                                                <td style={{ textAlign: "left" }}>R Govinda Marar </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1944 - 1945 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>Rao Bahadur C.B. Nair</td>
                                                <td style={{ textAlign: "left" }}>K.P.Sethumadhavan Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1943 - 1944 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>A. Gopalakrishnan</td>
                                                <td style={{ textAlign: "left" }}>K.P.Sethumadhavan Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1942 - 1943 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>R Chandrasekhara</td>
                                                <td style={{ textAlign: "left" }}>K.P.Sethumadhavan Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1941 - 1942 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>D.H. Namburipad </td>
                                                <td style={{ textAlign: "left" }}>T. Achutha Menon</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1940 - 1941 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>E. Achutha Menon</td>
                                                <td style={{ textAlign: "left" }}>R Kannan Menon</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1939 - 1940 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>E. Achutha Menon</td>
                                                <td style={{ textAlign: "left" }}>R Kannan Menon</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1938 - 1939 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>P.A. Krishnan Kutty Nair</td>
                                                <td style={{ textAlign: "left" }}>C. Krishna Menon</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1937 - 1938 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>D.H. Nambuthiripad</td>
                                                <td style={{ textAlign: "left" }}>C. Krishna Menon</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1936 - 1937 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>P.A. Krishnan Kutty Nair</td>
                                                <td style={{ textAlign: "left" }}>C. Krishna Menon</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1935 - 1936 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>K. Kannankutty Menon</td>
                                                <td style={{ textAlign: "left" }}>P.N. Sivasankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1934 - 1935 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>D.H. Namburipad </td>
                                                <td style={{ textAlign: "left" }}>Dr.E. Achutha Menon </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1933 - 1934 </td>
                                                <td style={{ textAlign: "left" }}>M. Krishnan Nair</td>
                                                <td style={{ textAlign: "left" }}>K. Kelu Nair</td>
                                                <td style={{ textAlign: "left" }}>Dr.E. Achutha Menon </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1932 - 1933 </td>
                                                <td style={{ textAlign: "left" }}>M. Krishnan Nair</td>
                                                <td style={{ textAlign: "left" }}>K. Kelu Nair</td>
                                                <td style={{ textAlign: "left" }}>Dr.E. Achutha Menon </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1931- 1932</td>
                                                <td style={{ textAlign: "left" }}>M. Krishnan Nair</td>
                                                <td style={{ textAlign: "left" }}>K. Kelu Nair</td>
                                                <td style={{ textAlign: "left" }}>C.P.K. Menon</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1930 - 1931 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>P.G. Krishna Iyer</td>
                                                <td style={{ textAlign: "left" }}>K. Kannankutty Menon</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1929 - 1930 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>K. Kannankutty Menon</td>
                                                <td style={{ textAlign: "left" }}>K. Raman Menon</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1928 - 1929 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>Capt. G.R. Parasuraman</td>
                                                <td style={{ textAlign: "left" }}>K. Raman Menon</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1927 - 1928 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>K. Raman Menon</td>
                                                <td style={{ textAlign: "left" }}>K. Kannankutty Menon</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1926 - 1927</td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>C. Achutha Menon</td>
                                                <td style={{ textAlign: "left" }}>P.N. Chamu Nair </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1925 - 1926 </td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>C. Achutha Menon</td>
                                                <td style={{ textAlign: "left" }}>P.N. Chamu Nair </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1924 - 1925</td>
                                                <td style={{ textAlign: "left" }}>C. Gopala Menon </td>
                                                <td style={{ textAlign: "left" }}>C. Achutha Menon</td>
                                                <td style={{ textAlign: "left" }}>P.N. Chamu Nair </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1923 - 1924 </td>
                                                <td style={{ textAlign: "left" }}>Sir C.Sankaran Nair </td>
                                                <td style={{ textAlign: "left" }}>V.K. Krishna Menon</td>
                                                <td style={{ textAlign: "left" }}>G. Shankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1922 - 1923 </td>
                                                <td style={{ textAlign: "left" }}>Sir C.Sankaran Nair </td>
                                                <td style={{ textAlign: "left" }}>V.K. Krishna Menon</td>
                                                <td style={{ textAlign: "left" }}>G. Shankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1921 - 1922 </td>
                                                <td style={{ textAlign: "left" }}>Sir C.Sankaran Nair </td>
                                                <td style={{ textAlign: "left" }}>V.K. Krishna Menon</td>
                                                <td style={{ textAlign: "left" }}>G. Shankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1920 - 1921 </td>
                                                <td style={{ textAlign: "left" }}>Sir C.Sankaran Nair </td>
                                                <td style={{ textAlign: "left" }}>V.K. Krishna Menon</td>
                                                <td style={{ textAlign: "left" }}>G. Shankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1919 - 1920 </td>
                                                <td style={{ textAlign: "left" }}>Sir C.Sankaran Nair </td>
                                                <td style={{ textAlign: "left" }}>V.K. Krishna Menon</td>
                                                <td style={{ textAlign: "left" }}>G. Shankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1918 - 1919 </td>
                                                <td style={{ textAlign: "left" }}>Sir C.Sankaran Nair </td>
                                                <td style={{ textAlign: "left" }}>V.K. Krishna Menon</td>
                                                <td style={{ textAlign: "left" }}>G. Shankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1917 - 1918 </td>
                                                <td style={{ textAlign: "left" }}>Sir C.Sankaran Nair </td>
                                                <td style={{ textAlign: "left" }}>V.K. Krishna Menon</td>
                                                <td style={{ textAlign: "left" }}>G. Shankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1916 - 1917 </td>
                                                <td style={{ textAlign: "left" }}>Sir C.Sankaran Nair </td>
                                                <td style={{ textAlign: "left" }}>V.K. Krishna Menon</td>
                                                <td style={{ textAlign: "left" }}> G. Shankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1915 - 1916 </td>
                                                <td style={{ textAlign: "left" }}>Sir C.Sankaran Nair </td>
                                                <td style={{ textAlign: "left" }}>V.K. Krishna Menon</td>
                                                <td style={{ textAlign: "left" }}>G. Shankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1914 - 1915 </td>
                                                <td style={{ textAlign: "left" }}>Sir C.Sankaran Nair </td>
                                                <td style={{ textAlign: "left" }}>V.K. Narayanan</td>
                                                <td style={{ textAlign: "left" }}>G. Shankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1913 - 1914 </td>
                                                <td style={{ textAlign: "left" }}>Sir C.Sankaran Nair </td>
                                                <td style={{ textAlign: "left" }}>V.K. Narayanan</td>
                                                <td style={{ textAlign: "left" }}>G. Shankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1912 - 1913 </td>
                                                <td style={{ textAlign: "left" }}>Sir C.Sankaran Nair </td>
                                                <td style={{ textAlign: "left" }}>V.K. Narayanan</td>
                                                <td style={{ textAlign: "left" }}>G. Shankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1911 - 1912 </td>
                                                <td style={{ textAlign: "left" }}>Sir C.Sankaran Nair </td>
                                                <td style={{ textAlign: "left" }}>V.K. Narayanan</td>
                                                <td style={{ textAlign: "left" }}>G. Shankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1910 - 1911 </td>
                                                <td style={{ textAlign: "left" }}>Justice C. Sankaran Nair</td>
                                                <td style={{ textAlign: "left" }}>P. Narayana Menon</td>
                                                <td style={{ textAlign: "left" }}>V. Narayanan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1909 - 1910 </td>
                                                <td style={{ textAlign: "left" }}>Justice C. Sankaran Nair</td>
                                                <td style={{ textAlign: "left" }}>P. Narayana Menon</td>
                                                <td style={{ textAlign: "left" }}>V. Narayanan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1908 - 1909 </td>
                                                <td style={{ textAlign: "left" }}>Justice C. Sankaran Nair</td>
                                                <td style={{ textAlign: "left" }}>P. Narayana Menon</td>
                                                <td style={{ textAlign: "left" }}>V. Narayanan</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1907 - 1908 </td>
                                                <td style={{ textAlign: "left" }}>Justice C. Sankaran Nair</td>
                                                <td style={{ textAlign: "left" }}>K R Ramanunni Nair</td>
                                                <td style={{ textAlign: "left" }}>V. Sankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1906 - 1907 </td>
                                                <td style={{ textAlign: "left" }}>Justice C. Sankaran Nair</td>
                                                <td style={{ textAlign: "left" }}>K R Ramanunni Nair</td>
                                                <td style={{ textAlign: "left" }}>V. Sankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1905 - 1906 </td>
                                                <td style={{ textAlign: "left" }}>Justice C. Sankaran Nair</td>
                                                <td style={{ textAlign: "left" }}>K R Ramanunni Nair</td>
                                                <td style={{ textAlign: "left" }}>V. Sankaran Nair</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1904 - 1905 </td>
                                                <td style={{ textAlign: "left" }}>Justice C. Sankaran Nair</td>
                                                <td style={{ textAlign: "left" }}>K R Ramanunni Nair</td>
                                                <td style={{ textAlign: "left" }}>M Govindan Nair </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1903 - 1904 </td>
                                                <td style={{ textAlign: "left" }}>P. Govinda Menon</td>
                                                <td style={{ textAlign: "left" }}>V. Gopala Panicker</td>
                                                <td style={{ textAlign: "left" }}></td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1902 - 1903 </td>
                                                <td style={{ textAlign: "left" }}>P. Govinda Menon</td>
                                                <td style={{ textAlign: "left" }}>V. Gopala Panicker  </td>
                                                <td style={{ textAlign: "left" }}></td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1901 - 1902 </td>
                                                <td style={{ textAlign: "left" }}>P. Govinda Menon</td>
                                                <td style={{ textAlign: "left" }}>V. Gopala Panicker  </td>
                                                <td style={{ textAlign: "left" }}></td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1900 - 1901 </td>
                                                <td style={{ textAlign: "left" }}>Rao Sahib P. Govinda Menon  </td>
                                                <td style={{ textAlign: "left" }}>Kunju Nair  </td>
                                                <td style={{ textAlign: "left" }}></td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1899 - 1900 </td>
                                                <td style={{ textAlign: "left" }}>Rao Sahib P. Govinda Menon  </td>
                                                <td style={{ textAlign: "left" }}>Kunju Nair  </td>
                                                <td style={{ textAlign: "left" }}></td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1898 - 1899 </td>
                                                <td style={{ textAlign: "left" }}>Rao Sahib P. Govinda Menon  </td>
                                                <td style={{ textAlign: "left" }}>K. Appu Menon</td>
                                                <td style={{ textAlign: "left" }}></td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: "left" }}>1897 - 1898 </td>
                                                <td style={{ textAlign: "left" }}>Rao Sahib P. Govinda Menon  </td>
                                                <td style={{ textAlign: "left" }}>K.Appu Menon</td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* </div> */}
                        </div>

                    </div>
                </div>

                <section className="page-title" style={{ padding: "0px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">

                                <div className="breadcrumbs-wrap">
                                    <h1 style={{ textAlign: "left", fontSize: "25px", color: "#222a35" }}>Affiliation</h1>
                                    <hr />

                                    <nav aria-label="breadcrumb">

                                        <p style={{ textAlign: "justify" }}>Bringing you the best of all worlds, our club has affiliations with the most prestigious clubs in the country located within cities and at holiday spots. Avail special rates when travelling and enjoy the same star privileges from this ever growing network. The club has got affiliations with more than fifty clubs at different parts of the country. More affiliations are being added keeping in mind the quality and convenience of our members.</p>
                                    </nav>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <Tabs
                    defaultActiveKey="KERALA"
                    id="fill-tab-example"
                    className="mb-3 " fill style={{ display: "inline-flex", flexDirection: "row" }}
                >
                    <Tab eventKey="KERALA" title="KERALA" style={{ width: "100%", color: "#000" }}>
                        <div class="container">



                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">The Cosmopolitan Club</a></h4>
                                            <p>Cosmo Junction, Pookkottur Road, Manjeri. <br />Ph: 48327 66843 <br />Email: cosmopolitan.manjeri@gmail.com</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-2">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Bekal Club</a></h4>
                                            <p>Padannakad, Kanhangad, Kasaragod,
                                                Kerala 671328
                                                <br />Ph: 0091-467 2204 609, 09995 84609
                                                <br />E-mail: bekalclub@gmail.com
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-3">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Trivandrum Club</a></h4>
                                            <p>Vazhuthacaud,
                                                Thiruvanthapuram-695010. <br />Ph: 0091-467 2204 609, 09995 84609 <br />E-mail: trivandrumclub@gmail.com</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-4">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Reccaa Club</a></h4>
                                            <p>Reccaa Valley, Off Seaport-Airport Road,
                                                Kakkanad, Kochi-682030. <br />Ph: 095440 04335, 095443 43335. <br />E-mail: reccaacs@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-10">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">The Changanacherry Club</a></h4>
                                            <p>Bye-Pass Road, Vazhappally P.O.,
                                                Changanacherry-686103. <br />Ph: 62823 81682 <br />E-mail: changanacherryclub@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-6">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Banerji Memorial Club</a></h4>
                                            <p>Swaraj Round N, Round North,
                                                Thrissur, Kerala 680001 <br />Ph: 94463 7695 <br />E-mail: 1912banerji@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-7">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">The Citizen Club</a></h4>
                                            <p>MC Road, Kodimatha, Muppaikad,
                                                Kerala 686039. <br />Ph: 091-481-2361744, 95912 28966 <br />E-mail: thecitizensclubkottayam@gmail.com,
                                                heritageclubmys@gmail.com </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-8">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">The Calicut Cosmopolitan Club</a></h4>
                                            <p>8/360, Beach Rd, Next to All India Radio,
                                                Kozhikode, Kerala 673032 <br />Ph: 0495-2761 767, 2761 539,
                                                WHATSAPP: +91 97463 19721 <br />E-mail: calicutcosmoclub@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-9">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Century Club</a></h4>
                                            <p>Century Club Lane, Vennala, P.O.,
                                                Ernakulam, Kochi, Kerala 680028 <br />Ph: 0484 280 5387 <br />E-mail: centuryclubcochin@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Cochin Gymkhana</a></h4>
                                            <p>Toc-H Nagar, Vyttila, Kochi,
                                                Kerala 682019 <br />Ph: 0484 230 3071 <br />E-mail: mail@cochingymkhana.com.</p>
                                        </div>
                                    </div>
                                </div>




                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-2">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">The City Club</a></h4>
                                            <p> Main Road, Sulthan Bathery,
                                                Wayanad -673592<br /> Ph: 0493 6346539<br /></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-3">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Cosmos Club</a></h4>
                                            <p>Near Potta, NH Bye-pass,
                                                Chalakudy, Kerala 680307 <br />Ph: 085890 08918/0480 2708918 <br />E-mail: cosmoschalakudy@yahoo.com</p>
                                        </div>
                                    </div>
                                </div>





                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-4">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Lotus Club</a></h4>
                                            <p>Warriam Road, Cochin 682016
                                                Kerala-INDIA <br />Ph: 914842352456/2366737 <br />Email: lotusclubcochin@yahoo.co.in</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-10">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Mannam Memorial National Club</a></h4>
                                            <p>Press Club Road Behind,
                                                Secretariat East Residents Association Rd,
                                                Trivandrum, Kerala 695001 <br />Ph: 0471-2331128, 2336811, 2333599 / 8547556526 <br />E-mail: mmnctvm@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-6">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">The Munnar Club</a></h4>
                                            <p>Kannan Devan Hills, Near KDH Club,
                                                Idukki, Munnar, Kerala 685612 <br />Ph: 04865 231 781 <br />E-mail: reservation@deepwoodsmunnar.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-7">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Madhava Raja Club</a></h4>
                                            <p>English church Road,
                                                Palakkad-678014. <br />Ph: 0491-2522706, 2525354, 94969 10206 <br />E-mail: mrcpkd@yahoo.com,
                                                madhavarajapkd1943@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-8">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>

                                            <h4 class="title"><a href="#">The New Cosmopolitan Club</a></h4>
                                            <p>Near police station, Palissery,
                                                Thalassery, Kerala 670101 <br />Ph: 0490-232 2390 <br />E-mail: newcosmotly@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-9">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>

                                            <h4 class="title"><a href="#">National Club</a></h4>
                                            <p>KUMBANAD, Vattakottal,
                                                Thiruvalla, Kerala 689547 <br />Ph: 0469 266 5977 <br />E-mail: thekoramangalaclub@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">The Officers Club</a></h4>
                                            <p>420/1996 Thiruthiyad,
                                                Kozhikode-673004. <br />Ph: 0495 277 1017 <br />E-mail: officersclubcalicut@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-2">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Periyar Club</a></h4>
                                            <p>Desom PO. Swargam Road.
                                                Aluva Pin 683102. Kerala <br />Ph: 0484 4063083/94005 89963 <br />E-mail: periyarclubdesom@gmail.com</p>
                                        </div>
                                    </div>
                                </div>






                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-3">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Palghat Cosmopolitan Club</a></h4>
                                            <p>N.H. Bye-Pass Road,
                                                Salem-Kanyakumari Highway,
                                                Kadamkod, Kalmandapam,
                                                Palakkad, Kerala 678013 <br />Ph: 0491-2538866, 2504848/94461 82866 <br />E-mail: cosmopgt@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-4">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Ramavarma Club</a></h4>
                                            <p>Thevally, Kollam,
                                                Kerala 691009 <br />Ph : 0474-2792587 <br />E-mail : collam@gmail.com</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-10">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Regency Club</a></h4>
                                            <p>Club Road, Thottapady, Kuttancllur,
                                                Thrissur, Kerala 680014 <br />Ph : 0487 235 3101 <br />E-mail : booking©regencycountryclub.com</p>
                                        </div>
                                    </div>
                                </div>




                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-6">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Ramavarrna Club</a></h4>
                                            <p>Club Rd, Opp Durbar Hall Ground,
                                                Marine Drive. Ernakulam,
                                                Kerala 682011 <br />Ph : 0484 235 2040 / 236 5806 <br />E-mail : ramavarmaclubkochi@gmail.com</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-7">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Travancore Club</a></h4>
                                            <p>Perumthuruthy,
                                                Kerala 689107 <br />Ph : 085475 45909 <br />E-mail : travancorcclubthiruvalla@gmai|-com.</p>
                                        </div>
                                    </div>
                                </div>





                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-8">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>

                                            <h4 class="title"><a href="#">The United Club</a></h4>
                                            <p>Mangalassery - Chirangara Rd, Karukutty,
                                                Kerala 680308 <br />Ph : 0480 273 0733 <br />E-mail : theunitcdclub@yahoo.com</p>
                                        </div>
                                    </div>
                                </div>




                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-9">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>

                                            <h4 class="title"><a href="#">Varkala Club</a></h4>
                                            <p>Varkala, Kerala 695141 <br />Ph: 0821 2412377 / 2974377 /
                                                95912 28966 / 0470 260 6438 <br />E-mail : heritageclubmysénail.com
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">The Hill District Club</a></h4>
                                            <p>No AP/111/36C, Kolagappara,
                                                Meenangadi PO,
                                                Vaduvanchal - Kolagappara Road,
                                                Wayanad District, Kolagapaara,
                                                Kerala-673591 <br />Ph : 85899 50377 <br />E-mail : info@hilldistrictdub.com   </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </Tab>



                    <Tab eventKey="TAMIL NADU" title="TAMIL NADU" style={{ width: "100%", color: "#000" }}>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">The Kerala Club
                                            </a></h4>

                                            <p>
                                                78 80, ATT Colony, Gopalapuram,
                                                Coimbatore, Tamil Nadu 641018

                                                <br />Ph : 89033 53800
                                                <br />E-mail : thekeralaclub@gmail.com


                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-2">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Gudalur Cosmopolitan Club
                                            </a></h4>

                                            <p>
                                                PB- No.61, M-T- Nagar, Mysore Road,
                                                Gudalur - 643212.
                                                <br />Ph : 04262-261842, 089035 61842.
                                                <br />E-mail : gudalurcosmo@gmail.com
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-3">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Jenney Club

                                            </a></h4>

                                            <p>2. 2, Avinashi Rd, Peelamedu,
                                                Dr. Jaganathan Nagar,
                                                Coimbatore. Tamil Nadu 641014

                                                <br />Ph : 0422 433 5777 / 0422 2575552


                                                <br />E-mail : booking@jenneyresidency.in


                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>

                    </Tab>
                    <Tab eventKey="KARNATAKA" title="KARNATAKA" style={{ width: "100%", color: "#000" }}>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">East Cultural Association</a></h4>

                                            <p>
                                                8, 100 Fee! Road, Indiranagar,
                                                Bcngaluru, Karnataka 560095

                                                <br />Ph : 0802526 2058, 2526 0388, 2527 5241
                                                <br />E-mail : castculturalassociation@gmail.com

                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-2">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">The Club</a></h4>

                                            <p>
                                                Mysore Rd, SLV layout, Phase 3,
                                                Nayanda Halli, Bengaluru,
                                                Karnataka 560039

                                                <br />Ph : 080 28600665, 28600768 / 98458 30506

                                                <br />E-mail : thedubblr@gmail.com

                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-3">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Jain Farm & Resorts Ltd.
                                            </a></h4>

                                            <p>No-59/1, 3rd Floor, Nakoda Arcade,
                                                DVG Road, Basavanagudi,
                                                Bengaluru, Karnataka 560004

                                                <br />Ph : 080674 15551

                                                <br />E-mail : nature@jainfarms.com

                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>

                    </Tab>
                    <Tab eventKey="RAJASTHAN" title="RAJASTHAN" style={{ width: "100%", color: "#000" }}>
                        <div class="container">



                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Jaisal Club</a></h4>

                                            <p>
                                                Jethwai Road , RIICO Colony
                                                Jaisalmer-345001
                                                Rajasthan, India.
                                                <br />Ph : 02992 255 555 / 99503 32222
                                                <br />E-mail : info@jaisalclub.com
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-2">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Gym Khana Club Jodhpur</a></h4>

                                            <p>
                                                Mini Market, Uchiyarda Rd,
                                                Shikargarh, Palace, Jodhpur,
                                                Rajasthan 342015
                                                <br />Ph : 094141 49526 / 0291 2511390 I
                                                <br />jodhpurgymkhana©rcdiffmaiLcom
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-3">
                                    <div class="">

                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Golden City Club
                                            </a></h4>

                                            <p>Jethwai Road, Transport Nagar,
                                                Jaisalmer - 345001,
                                                <br />Ph : 02992-254558, 099281 28293,
                                                86968 91200-
                                                <br />E-mail : nicerajasthan293@gmail.com
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>

                    </Tab>
                    <Tab eventKey="MAHARASHTRA" title="MAHARASHTRA" style={{ width: "100%", color: "#000" }}>
                        <div class="container">



                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Spring Time Club</a></h4>

                                            <p>
                                                Club Road, opp. KDMC B Ward Office,
                                                Khadakpada, Bhoirwadi, Kalyan,
                                                Maharashtra 421301
                                                <br />Ph : 0251 223 3331 I 0251 2230597
                                                <br />E-mail : crm@springtimeclub.com
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-2">
                                    <div class="">
                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#"> Nasiklub</a></h4>

                                            <p>
                                                Sarda Activity Centre ’Nandinee',
                                                Nashik - Pune Road, Nashik,
                                                Maharashtra 422011
                                                <br />Ph : 0253 241 1254 / 0253 241 7931
                                                <br />E-mail : reservation@nasiklub.in
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>

                    </Tab>
                    <Tab eventKey="GUJARAT" title="GUJARAT" style={{ width: "100%", color: "#000" }}>
                        <div class="container">



                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-6 edu_cat_2 cat-1">
                                    <div class="">




                                        <div class="edu_cat_data" style={{ textAlign: "left" }}>
                                            <h4 class="title"><a href="#">Umbergoan Club</a></h4>

                                            <p>
                                                Madhuvan Complex,
                                                Sanjan - Umbergaon Rd,<br />
                                                Umargam, Gujarat 396171
                                                <br />Ph : 0260 2561 122 / 026025 62464 /<br />0260 2561 122 / 026025 62464 /

                                                <br />E-mail : info@umbergoanclub.com
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>

                    </Tab>
                </Tabs>

            </div>
        </div>
    );
};
