import React from "react";
import '../assets/css/styles.css';
import '../assets/css/colors.css';
import '../assets/css/plugins/font-awesome.css';
import '../assets/css/plugins/flaticon.css';
import '../assets/css/plugins/bootstrap.min.css';

export const Profile = () => {
  return (
    <div>
      {/* <h1>Profile</h1> */}
      <div className="image-cover half_banner" style={{ background: "#0b2248 url(https://img.freepik.com/free-photo/excited-audience-watching-confetti-fireworks-having-fun-music-festival-night-copy-space_637285-559.jpg?w=740&t=st=1701941001~exp=1701941601~hmac=8921b3bb7731824b898ee3b551385bd1872b4025ede90d8ae7811ea481bced0f) no-repeat" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="banner-search-2">
              <h1 style={{ textAlign: "center", fontSize: "35px", color: "#222a35" }}>Profile</h1>

                <p style={{ textAlign: "justify" }}>The Malayalee Club started off as a small meeting place of the Malayalee elite of then city of Madras way back in 1897 on a piece of land of its own. A small Club house was initially constructed where Madras malayalees congregated in the evenings to enjoy each others company to discuss state and social issues to relax with a pack of cards or to enjoy a cup of tea after a day's hard work. As the membership of the club grew so did the activities. To facilitate the conduct of cultural events by troupes brought specially from Kerala, a small stage with an open auditorium was constructed. On non - performance days the open air auditorium doubled as a Tennis or Badminton court.<br /><br />

                  Still later in its Journey, with the club metamorphosing into a family institution, a large ,covered auditorium was constructed. A restaurant serving authentic Kerala cuisine became a major attraction. A spacious, well- stacked library , a comfortable card room and a trendy bar were added on in addition to meeting halls and better laid office area. The Malayalee Club earned the reputation of being a very vibrant and dynamic institution with calendar full of events.</p>
                {/* <div className="input-group mt-3">
									<input type="text" className="form-control" placeholder="What do you want to learn?" />
									<div className="input-group-append">
										<button className="btn btn-outline-secondary" type="button"><img src="assets/img/search.svg" class="search-icon" alt="" /></button>
									</div>
								</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
