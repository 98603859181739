import React from "react";
import '../assets/css/styles.css';
import '../assets/css/colors.css';
import '../assets/css/plugins/font-awesome.css';
import '../assets/css/plugins/flaticon.css';
import '../assets/css/plugins/bootstrap.min.css';

export const Health = () => {
  return (
    <div>
      <section className="page-title" style={{ padding: "0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">

              <div className="breadcrumbs-wrap">
                <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Health & Wellness</h1>

                <hr />

                <h1>Coming soon</h1>
              </div>

            </div>
          </div>
        </div>
      </section>

    </div>
  );
};
