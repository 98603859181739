import React from "react";
// import Logo from "./Pages/assets/images/logo3.png";
import LogoWhite from "./Pages/assets/images/logoWhite.png";
import Iframe from 'react-iframe';

export const Footer = () => {
  return (
    <div>
      <footer className="light-footer skin-light-footer">
        <div>
          <div className="container">
            <div className="row">

              <div className="col-lg-3 col-md-6">
                <div className="footer-widget">
                  <a href={"/"}><img src={LogoWhite} className="img-footer" alt="" /></a>
                  <div className="footer-add">
                    <p>#28, Club Road,<br />Srinivasan Nagar, Chetpet,<br /> Chennai - 600 031</p>
                    <p>044 28360733</p>
                    <p>malayalee_club1897@yahoo.com</p>
                  </div>

                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-widget">
                  <h4 className="widget-title">Quick Link</h4>
                  <ul className="footer-menu">
                    <li><a href={"/accommodation"}>Guest Rooms</a></li>
                    <li><a href={"/conventions"}>Conferences & Banquets</a></li>
                    <li><a href={"/food"}>Bar & Restaurant</a></li>
                    <li><a href={"/health"}>Health & Wellness</a></li>
                    <li><a href={"/sports"}>Sports & Games</a></li>
                    <li><a href={"/events"}>Events & Activities</a></li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="footer-widget">
                  <h4 className="widget-title">Membership</h4>
                  <ul className="footer-menu">
                    <li><a href={"/membership"}>Premium Life Membership</a></li>
                    <li><a href={"/membership"}>Life Membership</a></li>
                    <li><a href={"/membership"}>Associate Membership</a></li>
                    <li><a href={"/membership"}>Corporate Membership</a></li>
                    <li><a href={"/membership"}>Long Term Temporary Membership</a></li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="footer-widget">
                  <h4 className="widget-title">Map</h4>
                  <Iframe target="blank" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.406299614084!2d80.24439237381206!3d13.073416912640388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52650023ffffff%3A0xd448ea14f09b83cc!2sThe%20Malayalee%20Club!5e0!3m2!1sen!2sin!4v1702130382500!5m2!1sen!2sin" width="100%" height="200px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
                </div>
              </div>



            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center">

              <div className="col-lg-6 col-md-6">
                <p className="mb-0" style={{ color: "#fff" }}>© 2024 Malayalee Club. Designd By <a style={{ color: "#fff" }} target="_blank" href={'https://www.accel-india.com'}>Accel</a>.</p>
              </div>

              {/* <div className="col-lg-6 col-md-6 text-right">
								<ul className="footer-bottom-social">
									<li><a href="#"><i className="ti-facebook"></i></a></li>
									<li><a href="#"><i className="ti-twitter"></i></a></li>
									<li><a href="#"><i className="ti-instagram"></i></a></li>
									<li><a href="#"><i className="ti-linkedin"></i></a></li>
								</ul>
							</div> */}

            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
