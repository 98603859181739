import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

export const LongTerm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_5jg3ske', 'template_o86ulkp', form.current,
            'tNwTmvoIP5Jp6GXov')
            .then((result) => {
                console.log(result.text);
                window.location.reload(false);
            }, (error) => {
                console.log(error.text);
            });
    };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div>
            <section className="gray">

                <div className="container">

                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="breadcrumbs-wrap">
                                <h1 style={{ textAlign: "center", fontSize: "35px", color: "#222a35" }}>Long Term Temporary Membership</h1>

                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">


                            <div className="packages_wrapping recommended">

                                <div className="packages_headers">
                                    <i className="lni-layers"></i>
                                    <h4 className="packages_pr_title">Long Term Temporary Membership</h4>
                                    <span className="packages_price-subtitle">(Eligibility: All)</span>
                                </div>
                                <div className="packages_price">
                                    <h6 style={{ color: "#fff" }}>Total</h6>
                                    <h4 className="pr-value">Rs.1,23,900</h4>
                                </div>
                                <div className="packages_middlebody">
                                    <ul>
                                        <li>Member Admission Fee <b>Rs.1,00,000</b></li>
                                        <li>GST 18% <b>Rs.18,000</b></li>
                                        <li>Number of Members <b>1</b></li>
                                        <li>Voting Rights <b>Nil</b></li>
                                        <li>Annual Maintenance <b>Rs.5000 +18% GST</b></li>
                                        <li>Non Transferable</li>
                                        <li>Term <b>5 Years</b></li>
                                    </ul>
                                </div>

                                <div class="packages_bottombody">
                                    <Button variant="primary" className="btn-pricing" onClick={handleShow}>
                                        Apply Now
                                    </Button>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">



                            <div className="single_widgets widget_tags">
                                <h4 className="title">For Booking Please Contact:
</h4>
                                <ul>
                                    <li><a href="#">
                                        04428360733 | 98843 80071</a></li>

                                </ul>
                            </div>
                            <div className="single_widgets widget_thumb_post">
                                <div className="prc_wrap">

                                    <div className="prc_wrap_header">
                                        <h4 className="property_block_title">Additional Terms and Conditions</h4>
                                    </div>
                                    <div class="edu_wraper" style={{ padding: "1rem" }}>
                                        <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                            <li>All Memberships require a minimum spend of Rs.500/- per month at the Club, on specified hospitality services.</li>
                                            <li>All applications have to be filled up properly and completely and recommended by two members of the Club before submission.</li>
                                            <li>Aadhar card copies are a must along with applications.</li>
                                            <li>The forms have to be vetted by the Office and to be approved by the members in charge for Membership Approval.</li>
                                            <li>All of the above are subject to the interpretations and decisions of the Executive Committee only and are subject to change as per the decisions of the Executive Commmittee.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Modal show={show} onHide={handleClose} style={{ width: "auto", margin: "auto", padding: "0px" }}>
                <div className="container">

                    <Modal.Header closeButton classname="btn-close" style={{ background: "#fff" }}>
                        <Modal.Title>Long Term Temporary Membership</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: "#fff" }}>
                        <form ref={form} onSubmit={sendEmail}>

                            <div className="row">

                                <div className="col-lg-12 col-md-12">


                                    <div className="form-group" style={{ alignItems: "baseline" }}>
                                        <label for="user_name">Name: &nbsp;&nbsp;</label>
                                        <input type="text" required id="user_name" name="user_name" className="form-control simple" />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group" style={{ alignItems: "baseline" }}>
                                        <label>Email: &nbsp;&nbsp;</label>
                                        <input type="email" required name="user_email" className="form-control simple" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" style={{ display: "none" }}>
                                <input name="enquiry_from" type="text" value="Message from long Term Temporary Membership" className="form-control simple" />
                            </div>

                            <div className="form-group" style={{ alignItems: "baseline" }}>
                                <label>Phone: &nbsp;&nbsp;</label>
                                <input name="user_number" required type="number" className="form-control simple" />
                            </div>

                            <div className="form-group" style={{ alignItems: "baseline" }}>
                                <label>Message: &nbsp;&nbsp;</label>
                                <textarea name="message" required className="form-control simple"></textarea>
                            </div>

                            <div className="form-group">

                                <Button variant="primary" value="Send" type="submit">
                                    Submit
                                </Button>&nbsp;&nbsp;&nbsp;
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    );
};
