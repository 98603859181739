import React from "react";
import '../assets/css/styles.css';
import '../assets/css/colors.css';
import '../assets/css/plugins/font-awesome.css';
import '../assets/css/plugins/flaticon.css';
import '../assets/css/plugins/bootstrap.min.css';

// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
import logo1 from "../assets/images/logos/1.png";
import logo2 from "../assets/images/logos/2.png";
import logo3 from "../assets/images/logos/3.png";
import logo4 from "../assets/images/logos/4.png";
import logo5 from "../assets/images/logos/5.png";
import logo6 from "../assets/images/logos/6.png";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import events1 from "../assets/images/slider1.jpg";
import events2 from "../assets/images/slider2.jpg";
import events3 from "../assets/images/slider3.jpg";
import events4 from "../assets/images/slider4.jpg";
import events5 from "../assets/images/slider5.jpg";
import events6 from "../assets/images/slider6.jpg";
import events7 from "../assets/images/slider7.jpg";
import events8 from "../assets/images/slider8.jpg";
import events9 from "../assets/images/slider9.jpg";
import events10 from "../assets/images/slider10.jpg";
import events11 from "../assets/images/slider11.jpg";
import events12 from "../assets/images/slider12.jpg";
import upevents1 from "../assets/images/upevents1.jpg";
import upevents2 from "../assets/images/upevents2.jpg";
import upevents3 from "../assets/images/upevents4.jpg";
import upevents5 from "../assets/images/upevents5.jpg";
import upevents7 from "../assets/images/upevents7.jpg";
import events13 from "../assets/images/events2.jpg";
import events14 from "../assets/images/events3.jpg";
import events15 from "../assets/images/upevents6.jpg";

export const Events = () => {
  return (
    <div>
      <Tabs
        defaultActiveKey="PAST EVENTS"
        id="fill-tab-example"
        className="mb-3 " fill style={{ display: "inline-flex", flexDirection: "row" }}
      >
        <Tab eventKey="PAST EVENTS" title="PAST EVENTS" style={{ width: "100%", color: "#000" }}>
          <section className="page-title" >
            <div className="container">

              <div className="row">
              <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={events15} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <a href="https://youtu.be/aBwUzcGYfEY" target="_blank" rel="noopener noreferrer"><img className="img-fluid" src={upevents7} alt="" /></a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={upevents5} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={upevents3} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={upevents1} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={upevents2} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={events1} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={events2} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={events3} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={events4} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={events5} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={events6} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={events7} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={events8} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={events9} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={events10} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={events11} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6" style={{ padding: "5px" }}>
                  <div className="article_featured_image">
                    <img className="img-fluid" src={events12} alt="" />
                  </div>
                </div>

              </div>
            </div>
          </section>

        </Tab>

        <Tab eventKey="ACTIVITIES" title="ACTIVITIES" style={{ width: "100%", color: "#000" }}>
          <section className="page-title" style={{ padding: "0px" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">

                  <div className="breadcrumbs-wrap">
                    {/* <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Events & Activities</h1>

                <hr /> */}
                    <nav aria-label="breadcrumb">

                      <p style={{ textAlign: "justify" }}>The Club is a lively and vibrant center for all age-groups with a host of events and activities happening throughout the year. There are designated groups like ‘Wings’ for women empowerment, ‘Kidzone’ for the little-ones, ‘Smart Minds’ for the youth, ‘Sanskriti’ for Arts and Cultural initiatives and many more such groups for special interest groups among the members. Apart from that, there are regular events conducted by these groups and in general, like the musical event ‘Paatinde Paalazhi’, regular movie screenings of the latest hits which are enabled by the industry members in our club, Games and activities at the Restobar, Tambola nights etc. Each member will find a specific interest group either in the Arts or in Sports and can develop oneself to the fullest potential.</p>
                    </nav>
                  </div>

                </div>
              </div>

              <div className="row">
                <div className="row" style={{ boxShadow: "0px 4px 5px #888888", marginBottom: "30px" }}>
                  <div class="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                    <div className="education_block_list_layout">
                      <div className="list_layout_ecucation_caption">
                        <div className="education_block_body">
                          <div className="education_block_thumb n-shadow">
                            <img src={logo1} className="img-fluid" alt="" />
                          </div>
                          <div className="education_block_author" style={{ textAlign: "justify" }}>
                            <p>Smart Minds - is an  initiative to equip youth of the Club to achieve their full potential through professional training and mentoring. Identify talent, help them through career development programs, Public Speaking skills through Toast Masters Club, other soft-skills / Entrepreneurship Development, Counselling  for parents and students on career dreams /  Provide a platform  to encourage great ideas and support them from concept to launch / Incubation and Co-working.</p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                    <div className="article_featured_image">
                      <img style={{ width: "auto" }} className="img-fluid" src={events2} alt="" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                    <div className="article_featured_image">
                      <img style={{ width: "auto" }} className="img-fluid" src={events9} alt="" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}  >
                    <div className="article_featured_image">
                      <img style={{ width: "auto" }} className="img-fluid" src={events10} alt="" />
                    </div>
                  </div>
                </div>
                <div className="row" style={{ boxShadow: "0px 4px 5px #888888", marginBottom: "30px" }}>
                  <div class="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                    <div className="education_block_list_layout">
                      <div className="list_layout_ecucation_caption">
                        <div className="education_block_body">
                          <div className="education_block_thumb n-shadow">
                            <img src={logo2} className="img-fluid" alt="" />
                          </div>
                          <div className="education_block_author" style={{ textAlign: "justify" }}>
                            <p>Sanskriti i- A Center For Excellence in Arts and Culture at The Malayalee Club. Training imparted on Carnatic Music, Light Music, Instrumental Music like Veena, Violin, Flute, Keyboard (western and carnatic) / Guitar / Mridangam, Bharatanatyam / Mohiniyattam / Kuchipudi, Folk Dance and Yoga. Training imparted for all age groups.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                    <div className="article_featured_image">
                      <img style={{ width: "auto" }} className="img-fluid" src={events1} alt="" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                    <div className="article_featured_image">
                      <img style={{ width: "auto" }} className="img-fluid" src={events5} alt="" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}  >
                    <div className="article_featured_image">
                      {/* <img style={{ width: "auto" }} className="img-fluid" src={events10} alt="" /> */}
                    </div>
                  </div>
                </div>
                <div className="row" style={{ boxShadow: "0px 4px 5px #888888", marginBottom: "30px" }}>
                  <div class="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                    <div className="education_block_list_layout">
                      <div className="list_layout_ecucation_caption">
                        <div className="education_block_body">
                          <div className="education_block_thumb n-shadow">
                            <img src={logo3} className="img-fluid" alt="" />
                          </div>
                          <div className="education_block_author" style={{ textAlign: "justify" }}>
                            <p>The Malayalee Club Music Group - is a special interest group of talented musicians and music aspirants. This initiative of the Group, unearths the music stars of The Club and enables a platform for them to unleash their potential and present it to a live audience. These monthly music shows have become a popular event at The Club, attracting  large gatherings of music lovers in the grand auditorium.</p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                    <div className="article_featured_image">
                      <img style={{ width: "auto" }} className="img-fluid" src={events4} alt="" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                    <div className="article_featured_image">
                      <img style={{ width: "auto" }} className="img-fluid" src={events11} alt="" />
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}  >
                    <div className="article_featured_image">
                      {/* <img style={{ width: "auto" }} className="img-fluid" src={events10} alt="" /> */}
                    </div>
                  </div>
                </div>
                <div className="row" style={{ boxShadow: "0px 4px 5px #888888", marginBottom: "30px" }}>
                  <div class="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                    <div className="education_block_list_layout">
                      <div className="list_layout_ecucation_caption">
                        <div className="education_block_body">
                          <div className="education_block_thumb n-shadow">
                            <img src={logo5} className="img-fluid" alt="" />
                          </div>
                          <div className="education_block_author" style={{ textAlign: "justify" }}>
                            <p>Creativity is intrinsic to all. The Literary and Cultural Group of The Malayalee Club realises this and brings to light all such talents with the active participation of members in all its activities. Be it dramatised readings of novels, poetry or short stories, under the banner of Akshara Velicham.
                              <br />
                              <br />The Group also provides a platform for member enthusiasts to showcase their  talents, engage in meaningful conversations through collaborative events aiming to strengthen the sense of belonging. Plans are also on the anvil to bring out a book, showcasing the creative works of members. The Group also showcases  classical art forms of Kerala for the members.

                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                    <div className="article_featured_image">
                      <img style={{ width: "auto" }} className="img-fluid" src={events13} alt="" />
                    </div>
                  </div>

                </div>
                <div className="row" style={{ boxShadow: "0px 4px 5px #888888", marginBottom: "30px" }}>
                  <div class="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                    <div className="education_block_list_layout">
                      <div className="list_layout_ecucation_caption">
                        <div className="education_block_body">
                          <div className="education_block_thumb n-shadow">
                            <img src={logo6} className="img-fluid" alt="" />
                          </div>
                          <div className="education_block_author" style={{ textAlign: "justify" }}>
                            <p>The dynamic Ladies wing of the Malayalee club is marching ahead with enjoyable and informative initiatives, aiming at a firm bonding of the members creating a feeling of bonhomie. Healthcare and  family welfare are also taken up as the prime subjects to be focused.

                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                    <div className="article_featured_image">
                      <img style={{ width: "auto" }} className="img-fluid" src={events14} alt="" />
                    </div>
                  </div>

                </div>
                <div className="row" style={{ boxShadow: "0px 4px 5px #888888", marginBottom: "30px" }}>
                  <div class="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                    <div className="education_block_list_layout">
                      <div className="list_layout_ecucation_caption">
                        <div className="education_block_body">
                          <div className="education_block_thumb n-shadow">
                            <img src={logo4} className="img-fluid" alt="" />
                          </div>
                          <div className="education_block_author" style={{ textAlign: "justify" }}>
                            <p><br />Grooming the next generation is both a responsibility and a necessity for any society. The Kids Group strives to achieve that in a fun and educative way so as to sustain interest and instil an all round learning that creates good future citizens. Fun and Games, Teamwork and Soft Skills for children, are the focus areas of this Group.

                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1" style={{ alignSelf: "center" }}>
                    <div className="article_featured_image">
                      {/* <img style={{ width: "auto" }} className="img-fluid" src={events14} alt="" /> */}
                    </div>
                  </div>

                </div>

              </div>
              <div className="row">

                <div className="col-lg-12 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">

                      <div className="education_block_list_layout">

                        <div className="education_block_thumb n-shadow">
                          <img src="https://mediaindia.eu/wp-content/uploads/2023/09/Onam-Pic-1-1024x704.jpeg" className="img-fluid" alt="" />
                        </div>

                        <div className="list_layout_ecucation_caption">

                          <div className="education_block_body">
                            <h4 className="bl-title" style={{ textAlign: "left" }}>Onam Festivities</h4>
                            <br />
                            <div className="education_block_author" style={{ textAlign: "justify" }}>
                              <p>During Onam, the Club conducts competitions in Pookkalam, Kaikottikkali and other sports and games. As a fitting finale to the celebrations, the Club hosts a grand Ona Sadya in the traditional style and with gaiety We bring cooks from Kerala to prepare all the traditional dishes for this grand banquet for members. As an additional service, the Club makes available Paalada Pradhaman for those members interested in getting it for their celebrations at home.An Ona Chantha' is arranged where various items such as Nentra Pazham, different varieties of banana chips, Onakkodi and other items are kept for sale, at very competitive rates for the benefit of members.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="education_block_list_layout">

                        <div className="education_block_thumb n-shadow">
                          <img src="https://static.tnn.in/thumb/msid-96349493,thumbsize-142302,width-1280,height-720,resizemode-75/96349493.jpg" className="img-fluid" alt="" />
                        </div>

                        <div className="list_layout_ecucation_caption">

                          <div className="education_block_body">
                            <h4 className="bl-title" style={{ textAlign: "left" }}>X-Mas</h4>
                            <br />
                            <div className="education_block_author" style={{ textAlign: "justify" }}>
                              <p>Christmas and New Year Eve celebrations are a major draw every year. Here, too, our members join in large numbers to celebrate in a spirit of true gaiety and fervour.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="education_block_list_layout">

                        <div className="education_block_thumb n-shadow">
                          <img src="https://clubmahindra.gumlet.io/blog/images/Traditions-of-Vishu.jpg?w=800&dpr=1.0" className="img-fluid" alt="" />
                        </div>

                        <div className="list_layout_ecucation_caption">

                          <div className="education_block_body">
                            <h4 className="bl-title" style={{ textAlign: "left" }}>Vishu the Harvest Festival</h4>
                            <br />
                            <div className="education_block_author" style={{ textAlign: "justify" }}>
                              <p>Vishu, celebrated as the beginning of new beginnings, is celebrated every year in the Club. One of the elderly members offers the traditional "Vishu Kaineettam' to all members present for the festivities A 'Vishu Kani' is arranged at the Club and in the evening a variety entertainment is held.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="education_block_list_layout">

                        <div className="education_block_thumb n-shadow">
                          <img src="https://image.wedmegood.com/resized/1000X/uploads/member/2494910/1635673119_Screenshot_from_2021_10_31_15_05_04.png" className="img-fluid" alt="" />
                        </div>

                        <div className="list_layout_ecucation_caption">

                          <div className="education_block_body">
                            <h4 className="bl-title" style={{ textAlign: "left" }}>Cultural Events</h4>
                            <br />
                            <div className="education_block_author" style={{ textAlign: "justify" }}>
                              <p>Apart from these annual festivals, the Club organizes at frequent intervals various Kerala cultural shows such as Ottan Thullal, Koothu, Mohiniyattam and other traditional dance forms.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </Tab>




      </Tabs>



    </div>
  );
};
