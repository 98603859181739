import React, { useState } from "react";
import data from "./data/images.json";
import Modal from "./components/Modal";
import "./components/index.css";

export const Gallery = () => {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleClick = (item, index) => {
    setCurrentIndex(index);
    setClickedImg(item.link);
  };

  const handelRotationRight = () => {
    const totalLength = data.data.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = data.data[0].link;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = data.data.filter((item) => {
      return data.data.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].link;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const handelRotationLeft = () => {
    const totalLength = data.data.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = data.data[totalLength - 1].link;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = data.data.filter((item) => {
      return data.data.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].link;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  return (
    // <section className="container" style={{ padding: "0px" }}>
    //    <div class="row " style={{ marginLeft: "auto", marginRight: "auto" }}>
    //    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">

    <div className="wrapper">
      {data.data.map((item, index) => (
        

               
          
            <div key={index} className="wrapper-images">
                    <img
                    src={item.link}
                    // alt={item.text}
                    onClick={() => handleClick(item, index)}
                    />
                    {/* <h2>{item.text}</h2> */}
            </div>
         
         
          ))}
      <div>
      {/* </div>
          </div>
      </section> */}

        {clickedImg && (
          <Modal
            clickedImg={clickedImg}
            handelRotationRight={handelRotationRight}
            setClickedImg={setClickedImg}
            handelRotationLeft={handelRotationLeft}
          />
        )}
      </div>
    </div>
  );
}

// import React, { useEffect } from "react";




// import "../../App.css";


// export const Gallery = () => {


//     return (
//         <div>

//       <h1 style={{textAlign:"center"}}>Coming Soon</h1>
            

//         </div>
//     );
// };



