import React from "react";

export const Bar= () => {
  return (
    <div>
      <section className="page-title" style={{ padding: "0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">

              <div className="breadcrumbs-wrap">
              <h1 style={{ textAlign: "center", fontSize: "35px", color: "#222a35" }}>Bar</h1>

                
                
              </div>

            </div>
          </div>
        </div>
      </section>
      <section className="gray">

        <div className="container">

          <div className="row">

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="article_detail_wrapss single_article_wrap format-standard">
                <div className="article_body_wrap">

                  <div className="article_featured_image">
                    <img className="img-fluid" src="https://malayaleeclub.org/assets/img/bar2.jpg" alt="" />
                  </div>


                  




                </div>
              </div>
            </div>
            

          </div>

        </div>

      </section>
    </div>
  );
};
