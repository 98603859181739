import './assets/css/styles.css';
import './assets/css/colors.css';
import './assets/css/plugins/font-awesome.css';
import './assets/css/plugins/flaticon.css';
import './assets/css/plugins/bootstrap.min.css';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export const Conventions = () => {
    const form1 = useRef();
    const form2 = useRef();
    const form3 = useRef();
    const form4 = useRef();

    const sendEmail1 = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_5jg3ske', 'template_o86ulkp', form1.current,
            'tNwTmvoIP5Jp6GXov')
            .then((result) => {
                console.log(result.text);
                window.location.reload(false);
            }, (error) => {
                console.log(error.text);
            });
    };
    const sendEmail2 = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_5jg3ske', 'template_o86ulkp', form2.current,
            'tNwTmvoIP5Jp6GXov')  
            .then((result) => {
                console.log(result.text);
                window.location.reload(false);
            }, (error) => {
                console.log(error.text);
            });
    };
    const sendEmail3 = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_5jg3ske', 'template_o86ulkp', form3.current,
            'tNwTmvoIP5Jp6GXov')
            .then((result) => {
                console.log(result.text);
                window.location.reload(false);
            }, (error) => {
                console.log(error.text);
            });
    };
    const sendEmail4 = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_5jg3ske', 'template_o86ulkp', form4.current,
            'tNwTmvoIP5Jp6GXov')
            .then((result) => {
                console.log(result.text);
                window.location.reload(false);
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <div >
            <Tabs
                defaultActiveKey="AUDITORIUM"
                id="fill-tab-example"
                className="mb-3 " fill style={{ display: "inline-flex", flexDirection: "row" }}
            >


                <Tab eventKey="AUDITORIUM" title="AUDITORIUM" style={{ width: "100%", color: "#000" }}>
                    <section className="page-title" style={{ padding: "0px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">

                                    <div className="breadcrumbs-wrap">
                                        <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Auditorium</h1>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="gray">

                        <div className="container">

                            <div className="row">

                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="article_detail_wrapss single_article_wrap format-standard">
                                        <div className="article_body_wrap">

                                            <div className="article_featured_image">
                                                <img className="img-fluid" src="https://malayaleeclub.org/assets/img/aud_bg.jpg" alt="" />
                                            </div>
                                            <hr />
                                            {/* <div className="table-responsive fixTableHead" >
                                    <table className="table search-table  "></table> */}
                                            <div class="dashboard_container_body">
                                                <div class="table-responsive fixTableHead2">
                                                    {/* <h4 style={{ textAlign: "center" }}>AUDITORIUM HALL </h4> */}
                                                    <table class="table">
                                                        <thead class="thead-dark">
                                                            <tr>
                                                                <th rowSpan={2} scope="col">Sl. No.</th>
                                                                <th rowSpan={2} scope="col" style={{ textAlign: "left" }}>Usage</th>
                                                                <th rowSpan={2} scope="col" style={{ textAlign: "left" }}>Capacity</th>
                                                                <th rowSpan={2} scope="col" style={{ textAlign: "left" }}>Duration</th>
                                                                <th rowSpan={2} scope="col" style={{ textAlign: "right" }}>Rate</th>
                                                                <th rowSpan={2} scope="col" style={{ textAlign: "right" }}>GST @ 18%</th>
                                                                <th rowSpan={2} scope="col" style={{ textAlign: "right" }}>Total (INR)</th>
                                                                <th rowSpan={2} scope="col" style={{ textAlign: "left" }}>Facilities Included</th>
                                                                <th scope="col" style={{ textAlign: "center" }} colSpan={2} >Additional Changes

                                                                </th>
                                                                {/* <th rowSpan={2} scope="col" style={{ textAlign: "right" }}>Additional Changes</th> */}
                                                            </tr>
                                                            <tr style={{
                                                                position: "sticky",
                                                                top: "35.5px"
                                                            }}>

                                                                <th scope="col" style={{ textAlign: "left" }}>Fixed</th>
                                                                <th scope="col" style={{ textAlign: "left" }}>Variable</th>

                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">1</th>
                                                                <td style={{ textAlign: "left" }}><b>Wedding (2 day package)<br />
                                                                    Gokulam Sabarish Auditorium with Travancore Banquet Hall</b></td>
                                                                <td style={{ textAlign: "left" }}>700 in Auditorium 150 in Banquet Hall</td>
                                                                <td style={{ textAlign: "left" }}>2 Days <br />(48 Hours)</td>
                                                                <td><b>450000</b></td>
                                                                <td>81000</td>
                                                                <td><b>531000</b></td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    Auditorium and adjacent Banquet Hall<br />
                                                                    2 Complementary A/C Guest Rooms<br />
                                                                    30 Tables<br />
                                                                    700 Chairs in Auditorium<br />
                                                                    150 Chairs in Banquet Hall<br />
                                                                    Air-Conditioning for 10 hours per day
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    Water: <br />Rs. 10,000<br />
                                                                    Conservancy: <br />Rs. 6,000<br />
                                                                    Cleaning: <br />Rs. 15,000
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>A/C beyond 20 hours <br />Rs.2500 per hours<br />
                                                                    Valet Parking: <br />Rs.1000 +18% GST driver per day<br />
                                                                    Generator: <br />Rs.6,000 per hour </td>


                                                            </tr>

                                                            <tr>
                                                                <th scope="row">2</th>
                                                                <td style={{ textAlign: "left" }}><b>Wedding (1 day package)<br />
                                                                    Gokulam Sabarish Auditorium with Travancore Banquet Hall</b></td>
                                                                <td style={{ textAlign: "left" }}>700 in Auditorium 150 in Banquet Hall</td>
                                                                <td style={{ textAlign: "left" }}>1 Day <br />(24 Hours)</td>
                                                                <td><b>250000</b></td>
                                                                <td>45000</td>
                                                                <td><b>295000</b></td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    Auditorium and adjacent Banquet Hall<br />
                                                                    2 free A/C Guest Rooms<br />
                                                                    30 Table with frills<br />
                                                                    700 Chairs in Auditorium<br />
                                                                    150 Chairs in Banquet Hall<br />
                                                                    Air-Conditioning for 10 hours per day
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    Water: <br />Rs. 5,000<br />
                                                                    Conservancy: <br />Rs. 5,000<br />
                                                                    Cleaning: <br />Rs. 10,000
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>A/C beyond 10 hours <br />Rs.2500 per hours<br />
                                                                    Valet Parking: <br />Rs.1000 +18% GST driver per day<br />
                                                                    Generator: <br />Rs.6,000 per hour </td>


                                                            </tr>

                                                            <tr>
                                                                <th scope="row">3</th>
                                                                <td style={{ textAlign: "left" }}><b>Wedding (12 hours package)<br />
                                                                    Gokulam Sabarish Auditorium with Travancore Banquet Hall</b></td>
                                                                <td style={{ textAlign: "left" }}>700 in Auditorium 150 in Banquet Hall</td>
                                                                <td style={{ textAlign: "left" }}>(12 Hours)</td>
                                                                <td><b>150000</b></td>
                                                                <td>27000</td>
                                                                <td><b>177000</b></td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    Auditorium and adjacent Banquet Hall<br />
                                                                    2 free A/C Guest Rooms<br />
                                                                    30 Table with frills<br />
                                                                    700 Chairs in Auditorium<br />
                                                                    150 Chairs in Banquet Hall<br />
                                                                    Air-Conditioning for 5 hours per day
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    Water: <br />Rs. 5,000<br />
                                                                    Conservancy: <br />Rs. 5,000<br />
                                                                    Cleaning: <br />Rs. 10,000
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>A/C beyond 5 hours <br />Rs.2500 per hours<br />
                                                                    Valet Parking: <br />Rs.1000 +18% GST driver per day<br />
                                                                    Generator: <br />Rs.6,000 per hour </td>


                                                            </tr>
                                                            <tr>
                                                                <th scope="row">4</th>
                                                                <td style={{ textAlign: "left" }}><b>Wedding Reception <br />(12 hours package)<br />
                                                                    Gokulam Sabarish Auditorium with Travancore Banquet Hall</b></td>
                                                                <td style={{ textAlign: "left" }}>700 in Auditorium 150 in Banquet Hall</td>
                                                                <td style={{ textAlign: "left" }}>(12 Hours)</td>
                                                                <td><b>162500</b></td>
                                                                <td>29250</td>
                                                                <td><b>191750</b></td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    Auditorium and adjacent Banquet Hall<br />
                                                                    2 free A/C Guest Rooms<br />
                                                                    30 Table with frills<br />
                                                                    700 Chairs in Auditorium<br />
                                                                    150 Chairs in Banquet Hall<br />
                                                                    Air-Conditioning for 5 hours per day
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    Water: <br />Rs. 5,000<br />
                                                                    Conservancy: <br />Rs. 5,000<br />
                                                                    Cleaning: <br />Rs. 10,000
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>A/C beyond 5 hours <br />Rs.2500 per hours<br />
                                                                    Valet Parking: <br />Rs.1000 +18% GST driver per day<br />
                                                                    Generator:<br /> Rs.6,000 per hour </td>


                                                            </tr>
                                                            <tr>
                                                                <th scope="row">5</th>
                                                                <td style={{ textAlign: "left" }}><b>Wedding Reception <br />(6 hours package)
                                                                    <br />
                                                                    Gokulam Sabarish Auditorium</b></td>
                                                                <td style={{ textAlign: "left" }}>700 in Auditorium <br />(Banquet Hall optional at addl. cost)</td>
                                                                <td style={{ textAlign: "left" }}>(6 Hours)</td>
                                                                <td><b>100000</b></td>
                                                                <td>18000</td>
                                                                <td><b>118000</b></td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    Auditorium<br />
                                                                    Air-Conditioning for 5 hours per day
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    Water: <br />Rs. 5,000<br />
                                                                    Conservancy: <br />Rs. 5,000<br />
                                                                    Cleaning: <br />Rs. 10,000
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>A/C beyond 5 hours <br />Rs.2500 per hours<br />
                                                                    Valet Parking: <br />Rs.1000 +18% GST driver per day<br />
                                                                    Generator:<br /> Rs.6,000 per hour </td>


                                                            </tr>

                                                            <tr>
                                                                <th scope="row">6</th>
                                                                <td style={{ textAlign: "left" }}><b>Public Meetings / Corporate Events / Cultural Programs for Malayalee Associations etc  <br />
                                                                    Gokulam Sabarish Auditorium</b></td>
                                                                <td style={{ textAlign: "left" }}>700 in Auditorium</td>
                                                                <td style={{ textAlign: "left" }}>(6 Hours)</td>
                                                                <td><b>75000</b></td>
                                                                <td>13500</td>
                                                                <td><b>88500</b></td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    Auditorium<br />
                                                                    400 Chairs in Auditorium <br />(addl. chairs at extra cost)<br />
                                                                    Air-Conditioning
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>

                                                                    Cleaning: <br />Rs. 5,000
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>Valet Parking: <br />Rs.1000 +18% GST driver per day<br />
                                                                    Generator: <br />Rs.6,000 per hour </td>


                                                            </tr>







                                                            <tr>
                                                                <th scope="row">7</th>
                                                                <td style={{ textAlign: "left" }}><b>Auditorium for Exhibition / Carnivals <br />
                                                                    Gokulam Sabarish Auditorium</b></td>
                                                                <td style={{ textAlign: "left" }}>N.A</td>
                                                                <td style={{ textAlign: "left" }}>(17 Hours)</td>
                                                                <td><b>150000</b></td>
                                                                <td>27000</td>
                                                                <td><b>177000</b></td>
                                                                <td style={{ textAlign: "left" }}>
                                                                    Auditorium<br />

                                                                    Air-Conditioning
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>

                                                                    Cleaning: <br />Rs. 5,000
                                                                </td>
                                                                <td style={{ textAlign: "left" }}>Valet Parking: <br />Rs.1000 +18% GST driver per day<br />
                                                                    Generator: <br />Rs.6,000 per hour </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                            <div className='jumbotron' style={{ padding: "2rem 1rem" }}>

                                                <h1 style={{ textAlign: "left", fontSize: "15px", color: "#222a35" }}>Remarks</h1>

                                                <ul style={{ textAlign: "left", listStyle: "dot", padding: "0px 0px 0px 14px" }}>
                                                    <li>Club Members eligible for 10% discount</li>
                                                    <li>Malayalee Associations affiliated with CTMA eligible for 20% discount</li>
                                                    <li>Tariffs are subject to change. Please enquire for latest rates.</li>
                                                </ul>
                                            </div>

                                            {/* <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping">
                            <div className="packages_headers">
                              <i className="lni-layers"></i>
                              <h4 className="packages_pr_title">Banquet Hall 1 for Wedding / Reception / Engagement <br />(100/150)</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">16000</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 2880 (GST 18%)</li>
                                <li>Duration of Usage : 5pm to 10pm(5hrs)</li>
                                <li>Banquet Hall - 1 with 150 chairs</li>
                                <li>Frills for Dinning tables</li>
                                <li>1 A/C Guest Room</li>

                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping recommended">
                            <div className="packages_headers">
                              <i className="lni-diamond"></i>
                              <h4 className="packages_pr_title">Banquet Hall 1 for General Oublic Meeting / Function / Get together (100/150)</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">15000</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 2700 (GST 18%)</li>
                                <li>Duration of Usage : 5 hours</li>
                                <li>150 Sitting Capacity</li>
                                <li>A/C Hall</li>
                                <li>Banquet Chairs</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping">
                            <div className="packages_headers">
                              <i className="lni-layers"></i>
                              <h4 className="packages_pr_title">Banquet Hall 1 for Club Members (100/150)</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">10000</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 1800 (GST 18%)</li>
                                <li>Duration of Usage : 5 hours</li>
                                <li>150 Sitting Capacity</li>
                                <li>A/C Hall</li>
                                <li>Banquet Chairs</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping recommended">

                            <div className="packages_headers">
                              <i className="lni-diamond"></i>
                              <h4 className="packages_pr_title">Banquet Hall 1 for Malayalee Associations (100/150)</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">12000</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 2160 (GST 18%)</li>
                                <li>Duration of Usage : 5 hours</li>
                                <li>150 Sitting Capacity</li>
                                <li>A/C Hall</li>
                                <li>Banquet Chairs</li>
                              </ul>
                            </div>
                          </div>
                        </div>

                      </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="single_widgets widget_tags">
                                        <h3 className="title">For Booking Please Contact:
                                        </h3>
                                        <ul>
                                            <li><a href="#">
                                                04428360733 | 98843 80071</a></li>
                                            <br />
                                            {/* <li><a href="#">
                        Book Now</a></li> */}
                                        </ul>
                                    </div>
                                    <div className="single_widgets widget_thumb_post">
                                        <div className="prc_wrap">

                                            <div className="prc_wrap_header">
                                                <h3 className="property_block_title">Enquire Now</h3>
                                            </div>

                                            <form ref={form4} onSubmit={sendEmail4}>

                                                <div className="prc_wrap-body">
                                                    <div className="row">

                                                        <div className="col-lg-12 col-md-12">


                                                            <div className="form-group" style={{ textAlign: "left" }}>
                                                                <label >Name</label>
                                                                <input type="text" name="user_name" className="form-control simple" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12">
                                                            <div className="form-group" style={{ textAlign: "left" }}>
                                                                <label>Email</label>
                                                                <input type="email" name="user_email" className="form-control simple" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group" style={{ textAlign: "left" }}>
                                                        <label>Phone Number</label>
                                                        <input name="user_number" type="text" className="form-control simple" />
                                                    </div>
                                                    <div className="form-group" style={{ display: "none" }}>
                                                        <input name="enquiry_from" type="text" value="Message from Auditorium Booking page" className="form-control simple" />
                                                    </div>

                                                    <div className="form-group" style={{ textAlign: "left" }}>
                                                        <label>Message</label>
                                                        <textarea name="message" className="form-control simple"></textarea>
                                                    </div>

                                                    <div className="form-group">

                                                        <button className="btn btn-theme" value="Send" type="submit">Submit</button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>



                                </div>

                            </div>

                        </div>

                    </section>

                </Tab>
                {/* <Tab eventKey="Restaurant"  title="Restaurant">
          <section className="page-title" style={{ padding: "0px" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="breadcrumbs-wrap">
                    <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Restaurant</h1>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="article_featured_image">
                    <img className="img-fluid" src="https://malayaleeclub.org/assets/img/rest3.jpeg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>

        </Tab> */}
                <Tab eventKey="BANQUET HALLS" title="BANQUET HALLS" style={{ width: "100%", color: "#000" }}>
                    <section className="page-title" style={{ padding: "0px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="breadcrumbs-wrap">
                                        <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Banquet Halls</h1>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="gray">

                        <div className="container">

                            <div className="row">

                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="article_detail_wrapss single_article_wrap format-standard">
                                        <div className="article_body_wrap">

                                            <div className="article_featured_image">
                                                <img className="img-fluid" src="https://malayaleeclub.org/assets/img/hover_slide3.jpg" alt="" />
                                            </div>
                                            <hr />
                                            <div class="dashboard_container_body">
                                                <div class="table-responsive">
                                                    <h4 style={{ textAlign: "center" }}>BANQUET HALLS</h4>
                                                    <h5 style={{ textAlign: "center", color: "#581117" }}>For wedding receptions, engagements, birthday parties, club meetings, alumni meets, retirement parties, etc</h5>
                                                    <table class="table">
                                                        <thead class="thead-dark">
                                                            <tr>
                                                                <th scope="col">Sl. No.</th>
                                                                <th scope="col" style={{ textAlign: "left" }}>Banquet Halls</th>
                                                                <th scope="col" style={{ textAlign: "right" }}>Capacity</th>
                                                                <th scope="col" style={{ textAlign: "right" }}>Tariff for 5 hours</th>
                                                                <th scope="col" style={{ textAlign: "right" }}>GST (18%)</th>
                                                                <th scope="col" style={{ textAlign: "right" }}>Total (INR)</th>
                                                                {/* <th scope="col" style={{ textAlign: "right" }}>Duration</th> */}
                                                                {/* <th scope="col" style={{ textAlign: "right" }}>Capacity</th> */}
                                                                <th scope="col">Facilities</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">1</th>
                                                                <td style={{ textAlign: "left" }}><b>Travancore Hall (Ground Floor) </b></td>
                                                                <td>150</td>
                                                                <td>20000</td>
                                                                <td>3600</td>
                                                                <td><b>23600</b></td>
                                                                {/* <td>5hrs</td> */}
                                                                <td style={{ textAlign: "left" }}>Air Conditioned Hall with banquet tables and chairs.<br />Attached Vegetarian Kitchen at extra cost.
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="row">2</th>
                                                                <td style={{ textAlign: "left" }}><b>Pallava Hall (Fourth Floor)</b></td>
                                                                <td>120</td>
                                                                <td>15000</td>
                                                                <td>2700</td>
                                                                <td><b>17700</b></td>
                                                                {/* <td>5 hours</td> */}
                                                                <td style={{ textAlign: "left" }}>Air Conditioned Hall with banquet tables and chairs.</td>
                                                            </tr>

                                                            {/* <tr>
                                <th scope="row">3</th>
                                <td style={{ textAlign: "left" }}><b>Club Members</b></td>
                                <td>10000</td>
                                <td>1800</td>
                                <td><b>11800</b></td>
                                <td>5 hours</td>
                                <td style={{ textAlign: "left" }}>Banquet Chairs</td>
                              </tr>

                              <tr>
                                <th scope="row">4</th>
                                <td style={{ textAlign: "left" }}><b>Malayalee Associations</b></td>
                                <td>12000</td>
                                <td>2160</td>
                                <td><b>14160</b></td>
                                <td>5 hours</td>
                                <td style={{ textAlign: "left" }}>Banquet Chairs</td>
                              </tr> */}
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                            <div className='jumbotron' style={{ padding: "2rem 1rem" }}>

                                                <h1 style={{ textAlign: "left", fontSize: "15px", color: "#222a35" }}>Remarks</h1>

                                                <ul style={{ textAlign: "left", listStyle: "dot", padding: "0px 0px 0px 14px" }}>
                                                    <li>Club Members eligible for 10% discount</li>
                                                    <li>Malayalee Associations affiliated with CTMA eligible for 20% discount</li>
                                                    <li>Tariffs are subject to change. Please enquire for latest rates.</li>
                                                </ul>
                                            </div>
                                            {/* <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="packages_wrapping">
                <div className="packages_headers">
                  <i className="lni-layers"></i>
                  <h4 className="packages_pr_title">Banquet Hall 1 for Wedding / Reception / Engagement <br />(100/150)</h4>
                </div>
                <div className="packages_price">
                  <h4 className="pr-value">16000</h4>
                </div>
                <div className="packages_middlebody">
                  <ul>
                    <li>+ 2880 (GST 18%)</li>
                    <li>Duration of Usage : 5pm to 10pm(5hrs)</li>
                    <li>Banquet Hall - 1 with 150 chairs</li>
                    <li>Frills for Dinning tables</li>
                    <li>1 A/C Guest Room</li>

                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="packages_wrapping recommended">
                <div className="packages_headers">
                  <i className="lni-diamond"></i>
                  <h4 className="packages_pr_title">Banquet Hall 1 for General Oublic Meeting / Function / Get together (100/150)</h4>
                </div>
                <div className="packages_price">
                  <h4 className="pr-value">15000</h4>
                </div>
                <div className="packages_middlebody">
                  <ul>
                    <li>+ 2700 (GST 18%)</li>
                    <li>Duration of Usage : 5 hours</li>
                    <li>150 Sitting Capacity</li>
                    <li>A/C Hall</li>
                    <li>Banquet Chairs</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="packages_wrapping">
                <div className="packages_headers">
                  <i className="lni-layers"></i>
                  <h4 className="packages_pr_title">Banquet Hall 1 for Club Members (100/150)</h4>
                </div>
                <div className="packages_price">
                  <h4 className="pr-value">10000</h4>
                </div>
                <div className="packages_middlebody">
                  <ul>
                    <li>+ 1800 (GST 18%)</li>
                    <li>Duration of Usage : 5 hours</li>
                    <li>150 Sitting Capacity</li>
                    <li>A/C Hall</li>
                    <li>Banquet Chairs</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="packages_wrapping recommended">

                <div className="packages_headers">
                  <i className="lni-diamond"></i>
                  <h4 className="packages_pr_title">Banquet Hall 1 for Malayalee Associations (100/150)</h4>
                </div>
                <div className="packages_price">
                  <h4 className="pr-value">12000</h4>
                </div>
                <div className="packages_middlebody">
                  <ul>
                    <li>+ 2160 (GST 18%)</li>
                    <li>Duration of Usage : 5 hours</li>
                    <li>150 Sitting Capacity</li>
                    <li>A/C Hall</li>
                    <li>Banquet Chairs</li>
                  </ul>
                </div>
              </div>
            </div>

          </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="single_widgets widget_tags">
                                        <h3 className="title">For Booking Please Contact:
                                        </h3>
                                        <ul>
                                            <li><a href="#">
                                                04428360733 | 98843 80071</a></li>
                                            <br />
                                            {/* <li><a href="#">
                        Book Now</a></li> */}
                                        </ul>
                                    </div>
                                    <div className="single_widgets widget_thumb_post">
                                        <div className="prc_wrap">

                                            <div className="prc_wrap_header">
                                                <h3 className="property_block_title">Enquire Now</h3>
                                            </div>

                                            <form ref={form3} onSubmit={sendEmail3}>

                                                <div className="prc_wrap-body">
                                                    <div className="row">

                                                        <div className="col-lg-12 col-md-12">


                                                            <div className="form-group" style={{ textAlign: "left" }}>
                                                                <label >Name</label>
                                                                <input type="text" name="user_name" className="form-control simple" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12">
                                                            <div className="form-group" style={{ textAlign: "left" }}>
                                                                <label>Email</label>
                                                                <input type="email" name="user_email" className="form-control simple" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group" style={{ textAlign: "left" }}>
                                                        <label>Phone Number</label>
                                                        <input name="user_number" type="text" className="form-control simple" />
                                                    </div>
                                                    <div className="form-group" style={{ display: "none" }}>
                                                        <input name="enquiry_from" type="text" value="Message from Banquet Booking page" className="form-control simple" />
                                                    </div>

                                                    <div className="form-group" style={{ textAlign: "left" }}>
                                                        <label>Message</label>
                                                        <textarea name="message" className="form-control simple"></textarea>
                                                    </div>

                                                    <div className="form-group">

                                                        <button className="btn btn-theme" value="Send" type="submit">Submit</button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>



                                </div>

                            </div>

                        </div>

                    </section>

                </Tab>
                {/* <Tab eventKey="Sports"  title="Sports">
          <section className="page-title" style={{ padding: "0px" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="breadcrumbs-wrap">
                    <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Sports</h1>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="article_featured_image">
                    <img className="img-fluid" src="https://malayaleeclub.org/assets/img/sports.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>

        </Tab>
        <Tab eventKey="Library"  title="Library">

          <section className="page-title" style={{ padding: "0px" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="breadcrumbs-wrap">
                    <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Library</h1>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="article_featured_image">
                    <img className="img-fluid" src="https://malayaleeclub.org/assets/img/54.JPG" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Tab>
        <Tab eventKey="Bar"  title="Bar">
          <section className="page-title" style={{ padding: "0px" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="breadcrumbs-wrap">
                    <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Bar</h1>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="article_featured_image">
                    <img className="img-fluid" src="https://malayaleeclub.org/assets/img/bar2.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>

        </Tab>
        <Tab eventKey="Basement Parking"  title="Basement Parking">
          <section className="page-title" style={{ padding: "0px" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="breadcrumbs-wrap">
                    <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Basement Parking</h1>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="article_featured_image">
                    <img className="img-fluid" src="https://malayaleeclub.org/assets/img/parking.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>

        </Tab> */}
                <Tab eventKey="MEETING ROOMS" title="MEETING ROOMS" id="conferenceRedirect" style={{ width: "100%", color: "#000" }}>
                    <section className="page-title" style={{ padding: "0px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">

                                    <div className="breadcrumbs-wrap">
                                        <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Meeting Rooms</h1>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="gray">

                        <div className="container">

                            <div className="row">

                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="article_detail_wrapss single_article_wrap format-standard">
                                        <div className="article_body_wrap">

                                            <div className="article_featured_image">
                                                <img className="img-fluid" src="https://malayaleeclub.org/assets/img/meet_bg.jpg" alt="" />
                                            </div>
                                            <hr />
                                            <div class="dashboard_container_body">
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <thead class="thead-dark">
                                                            <tr>
                                                                <th scope="col">Sl. No.</th>
                                                                <th scope="col">Halls</th>
                                                                <th scope="col" style={{ textAlign: "right" }}>Capacity</th>
                                                                <th scope="col" style={{ textAlign: "right" }}>Tariff for 5 hours</th>
                                                                <th scope="col" style={{ textAlign: "right" }}>GST (18%)</th>
                                                                <th scope="col" style={{ textAlign: "right" }}>Total (INR)</th>
                                                                {/* <th scope="col" style={{ textAlign: "right" }}>Duration</th> */}
                                                                {/* <th scope="col" style={{ textAlign: "right" }}>Capacity</th> */}
                                                                <th scope="col">Facilities</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                            <tr>
                                                                <th scope="row">1</th>
                                                                <td style={{ textAlign: "left" }}><b>Farwood Conference Hall</b></td>
                                                                <td>50</td>
                                                                <td>4500</td>
                                                                <td>810</td>
                                                                <td><b>5310</b></td>
                                                                <td style={{ textAlign: "left" }}>Conference Table with 50 Chairs<br />
                                                                    Audio System<br />
                                                                    Overhead Projector<br />
                                                                    Reception Room with Food Counter</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">2</th>
                                                                <td style={{ textAlign: "left" }}><b>Bina Nandu Meeting Hall</b></td>
                                                                <td>50</td>
                                                                <td >4000</td>
                                                                <td >720</td>
                                                                <td ><b>4720</b></td>
                                                                {/* <td>5 hours</td> */}
                                                                <td style={{ textAlign: "left" }}>Conference Table with 50 Chairs<br />
                                                                    Audio System<br />
                                                                    Overhead Projector</td>

                                                            </tr>
                                                            <tr>
                                                                <th scope="row">3</th>
                                                                <td style={{ textAlign: "left" }}><b>Sheenlac Meeting Hall</b></td>
                                                                <td>50</td>
                                                                <td>3500</td>
                                                                <td>630</td>
                                                                <td><b>4130</b></td>
                                                                <td style={{ textAlign: "left" }}>50 Banquet Chairs</td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="row">4</th>
                                                                <td style={{ textAlign: "left" }}><b>Mini Meeting Room</b></td>
                                                                <td>15</td>
                                                                <td>1750</td>
                                                                <td>315</td>
                                                                <td><b>2065</b></td>
                                                                <td style={{ textAlign: "left" }}>Conference Table with chairs</td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="row">5</th>
                                                                <td style={{ textAlign: "left" }}><b>M.A. Vellodi Board Room</b></td>
                                                                <td>10</td>
                                                                <td>1500</td>
                                                                <td>270</td>
                                                                <td><b>1770</b></td>
                                                                <td style={{ textAlign: "left" }}>Subject to availability.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                            <div className='jumbotron' style={{ padding: "2rem 1rem" }}>

                                                <h1 style={{ textAlign: "left", fontSize: "15px", color: "#222a35" }}>Remarks</h1>

                                                <ul style={{ textAlign: "left", listStyle: "dot", padding: "0px 0px 0px 14px" }}>
                                                    <li>Club Members eligible for 10% discount</li>
                                                    <li>Tariffs are subject to change. Please enquire for latest rates.</li>

                                                </ul>
                                            </div>
                                            {/* <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping">
                            <div className="packages_headers">
                              <i className="lni-layers"></i>
                              <h4 className="packages_pr_title">Bina Nandu Meeting Center (60)</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">4000</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 720 (GST 18%)</li>
                                <li>Duration of Usage : 5 hours</li>
                                <li>60 Sitting Capacity A/C Hall</li>
                                <li>60 Banquet Chairs</li>
                                <li>Meeting Head Table</li>
                                <li>Public Address System</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping recommended">
                            <div className="packages_headers">
                              <i className="lni-diamond"></i>
                              <h4 className="packages_pr_title">Farwood Conference Hall (50)</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">4500</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 810 (GST 18%)</li>
                                <li>Duration of Usage : 5 hours</li>
                                <li>Conference Table + 50 Chairs</li>
                                <li>Public Address System</li>
                                <li>Visual Projector + Reception Room</li>
                                <li>Food Counter</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping">
                            <div className="packages_headers">
                              <i className="lni-layers"></i>
                              <h4 className="packages_pr_title">Sheenlac Meeting Center (50)</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">3500</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 630 (GST 18%)</li>
                                <li>Duration of Usage : 5 hours</li>
                                <li>50 Sitting Capacity A/C Hall</li>
                                <li>50 Banquet Chairs</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping recommended">

                            <div className="packages_headers">
                              <i className="lni-diamond"></i>
                              <h4 className="packages_pr_title">Mini Meeting Center (15)</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">1500</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 270 (GST 18%)</li>
                                <li>Duration of Usage : 5 hours</li>
                                <li>Conference Table</li>
                                <li>15 Chairs</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping">
                            <div className="packages_headers">
                              <i className="lni-layers"></i>
                              <h4 className="packages_pr_title">M.A. Vellodi Literary Room (10)</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">1500</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 270 (GST 18%)</li>
                                <li>Duration of Usage : 5 hours</li>
                                <li>10 Sitting Capacity</li>
                                <li>A/C Hall</li>

                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="single_widgets widget_tags">
                                        <h3 className="title">For Booking Please Contact:
                                        </h3>
                                        <ul>
                                            <li><a href="#">
                                                04428360733 | 98843 80071</a></li>
                                            <br />
                                            {/* <li><a href="#">
                        Book Now</a></li> */}
                                        </ul>
                                    </div>
                                    <div className="single_widgets widget_thumb_post">
                                        <div className="prc_wrap">

                                            <div className="prc_wrap_header">
                                                <h3 className="property_block_title">Enquire Now</h3>
                                            </div>

                                            <form ref={form2} onSubmit={sendEmail2}>

                                                <div className="prc_wrap-body">
                                                    <div className="row">

                                                        <div className="col-lg-12 col-md-12">


                                                            <div className="form-group" style={{ textAlign: "left" }}>
                                                                <label >Name</label>
                                                                <input type="text" name="user_name" className="form-control simple" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12">
                                                            <div className="form-group" style={{ textAlign: "left" }}>
                                                                <label>Email</label>
                                                                <input type="email" name="user_email" className="form-control simple" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group" style={{ textAlign: "left" }}>
                                                        <label>Phone Number</label>
                                                        <input name="user_number" type="text" className="form-control simple" />
                                                    </div>
                                                    <div className="form-group" style={{ display: "none" }}>
                                                        <input name="enquiry_from" type="text" value="Message from Conference Booking page" className="form-control simple" />
                                                    </div>

                                                    <div className="form-group" style={{ textAlign: "left" }}>
                                                        <label>Message</label>
                                                        <textarea name="message" className="form-control simple"></textarea>
                                                    </div>

                                                    <div className="form-group">

                                                        <button className="btn btn-theme" value="Send" type="submit">Submit</button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>



                                </div>

                            </div>

                        </div>

                    </section>

                </Tab>  
            </Tabs>

        </div>
    );
};
