import './assets/css/styles.css';
import './assets/css/colors.css';
import './assets/css/plugins/font-awesome.css';
import './assets/css/plugins/flaticon.css';
import './assets/css/plugins/bootstrap.min.css';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
export const Membership = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_5jg3ske', 'template_o86ulkp', form.current,
            'tNwTmvoIP5Jp6GXov')
            .then((result) => {
                console.log(result.text);
                window.location.reload(false);
            }, (error) => {
                console.log(error.text);
            });
    };
    // const [show, setShow] = useState(false);
    // const [show2, setShow2] = useState(false);
    // const [show3, setShow3] = useState(false);
    // const [show4, setShow4] = useState(false);
    // const [show5, setShow5] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () =>{
        
    // };


    // const handleClose2 = () => setShow2(false);
    // const handleShow2 = () => setShow2(true);

    // const handleClose3 = () => setShow3(false);
    // const handleShow3 = () => setShow3(true);

    // const handleClose4 = () => setShow4(false);
    // const handleShow4 = () => setShow4(true);

    // const handleClose5 = () => setShow5(false);
    // const handleShow5 = () => setShow5(true);
    return (
        <div>
            {/* <h1>Rooms</h1> */}
            <section className="page-title" style={{ padding: "0px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">

                            <div className="breadcrumbs-wrap">
                                <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Membership</h1>


                            </div>

                        </div>
                        <div className="col-lg-2 col-md-12"></div>
                        <div className="col-lg-8 col-md-12">

                            <div className="breadcrumbs-wrap">

                                <p style={{ textAlign: "justify" }}>While the endeavour is to keep the memberships exclusive and build a network of likeminded professionals, the club is always open to adding new memberships to enable those wanting to join and be part of this growing community of successful professionals. While the regular memberships are for those from the diaspora, there are non-voting associate memberships available to all states so as to enable a cosmopolitan environment.</p>

                            </div>

                        </div>
                        <div className="col-lg-2 col-md-12"></div>

                    </div>
                </div>
            </section>
            {/* <section className="gray">

                <Button variant="primary" className="btn-pricing" onClick='scroll("scrollEnquiry")'>
                    Membership Enquiry
                </Button>
            </section> */}

            <section className="gray">

                <div className="container">
                    <div class="row">

                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="dashboard_container">

                                <div class="dashboard_container_body">
                                    <div class="table-responsive fixTableHead2">
                                        <table class="table">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">Sl. No.</th>
                                                    <th scope="col">Membership Type</th>
                                                    <th scope="col">Eligibility</th>
                                                    <th scope="col">Term</th>
                                                    <th scope="col" style={{textAlign:"right"}}>Number of Members</th>
                                                    <th scope="col" style={{textAlign:"right"}}>Voting Rights</th>
                                                    <th scope="col">Transferability</th>
                                                    <th scope="col" style={{textAlign:"right"}}>Entrance Fee</th>
                                                    <th scope="col" style={{textAlign:"right"}}>GST @ 18%</th>
                                                    <th scope="col" style={{textAlign:"right"}}>Annual Maintenance Charges</th>
                                                    <th scope="col" style={{textAlign:"right"}}>GST @ 18%</th>
                                                    <th scope="col" style={{textAlign:"right"}}>Total Payable with Application  </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td style={{textAlign:"left"}}><b>Premium Family Membership</b></td>
                                                    <td style={{textAlign:"left"}}>Malayalees</td>
                                                    <td style={{textAlign:"left"}}>Lifelong</td>
                                                    <td>4</td>
                                                    <td>4</td>
                                                    <td style={{textAlign:"left"}}>Yes. To family Members @ Rs. 50,000 per member</td>
                                                    <td>10,00,000</td>
                                                    <td>1,80,000</td>
                                                    <td>20,000</td>
                                                    <td>3,600</td>
                                                    <td><b>12,03,600</b></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">2</th>
                                                    <td style={{textAlign:"left"}}><b>Life Membership</b></td>
                                                    <td style={{textAlign:"left"}}>Malayalees</td>
                                                    <td style={{textAlign:"left"}}>Lifelong</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td style={{textAlign:"left"}}>Yes, with transfer fee of Rs.1,00,000 /- plus GST</td>
                                                    <td>3,00,000</td>
                                                    <td>54,000</td>
                                                    <td>5,000</td>
                                                    <td>900</td>
                                                    <td><b>3,59,900</b></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">3</th>
                                                    <td style={{textAlign:"left"}}><b>Associate Membership</b></td>
                                                    <td style={{textAlign:"left"}}>Non Malayalees</td>
                                                    <td style={{textAlign:"left"}}>Lifelong</td>
                                                    <td>1</td>
                                                    <td>Nil</td>
                                                    <td style={{textAlign:"left"}}>Yes. To anyone with a transfer fee of Rs.1,00,000 /- plus GST</td>
                                                    <td>3,00,000</td>
                                                    <td>54,000</td>
                                                    <td>5,000</td>
                                                    <td>900</td>
                                                    <td><b>3,59,900</b></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">4</th>
                                                    <td style={{textAlign:"left"}}><b>Corporate Membership</b></td>
                                                    <td style={{textAlign:"left"}}>Companies</td>
                                                    <td style={{textAlign:"left"}}>20 Years</td>
                                                    <td>2</td>
                                                    <td>Nil</td>
                                                    <td style={{textAlign:"left"}}>Non Transferable</td>
                                                    <td>5,00,000</td>
                                                    <td>90,000</td>
                                                    <td>10,000</td>
                                                    <td>1,800</td>
                                                    <td><b>6,01,800</b></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">5</th>
                                                    <td style={{textAlign:"left"}}><b>Long Term Temporary Membership</b></td>
                                                    <td style={{textAlign:"left"}}>Anyone</td>
                                                    <td style={{textAlign:"left"}}>5 Years</td>
                                                    <td>1</td>
                                                    <td>Nil</td>
                                                    <td style={{textAlign:"left"}}>Non Transferable</td>
                                                    <td>1,00,000</td>
                                                    <td>18,000</td>
                                                    <td>5,000</td>
                                                    <td>900</td>
                                                    <td><b>1,23,900</b></td>
                                                </tr>
                                               



                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="row">

                        {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="article_detail_wrapss single_article_wrap format-standard">
                                <div className="article_body_wrap">



                                   <div className="row">

                                        <div className="col-lg-4 col-md-4">
                                            <div className="packages_wrapping">

                                                <div className="packages_headers">
                                                    <i className="lni-layers"></i>
                                                    <h4 className="packages_pr_title">Premium Life Membership</h4>
                                                    <span className="packages_price-subtitle">(Eligibility: Malayalees)</span>
                                                </div>
                                                <div className="packages_price">
                                                    <h6 >Total</h6>
                                                    <h4 className="pr-value">Rs.12,03,600</h4>
                                                </div>
                                                <div className="packages_middlebody">
                                                    <ul>
                                                        <li>Member Admission Fee <b>Rs.10,00,000</b></li>
                                                        <li>GST 18% <b>Rs.1,80,000</b></li>
                                                        <li>Number of Members <b>4</b></li>
                                                        <li>Voting Rights <b>4</b></li>
                                                        <li>Annual Maintenance Charges <b>20000 +18% GST</b></li>
                                                        <li>Transferability <b>Yes. To family Members Rs.50,000 per person</b></li>
                                                        <li>Term <b>Lifelong</b></li>
                                                    </ul>
                                                </div>
                                                <div class="packages_bottombody">
                                                    <Button variant="primary" className="btn-pricing" onClick={handleShow}>
                                                        Apply Now
                                                    </Button>
                                                </div>


                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="packages_wrapping recommended">

                                                <div className="packages_headers">
                                                    <i className="lni-layers"></i>
                                                    <h4 className="packages_pr_title">Life Membership</h4>
                                                    <span className="packages_price-subtitle">(Eligibility: Malayalees)</span>
                                                </div>
                                                <div className="packages_price">
                                                    <h6 style={{ color: "#fff" }}>Total</h6>
                                                    <h4 className="pr-value">Rs.3,59,900</h4>
                                                </div>
                                                <div className="packages_middlebody">
                                                    <ul>
                                                        <li>Member Admission Fee <b>Rs.3,00,000</b></li>
                                                        <li>GST 18% <b>Rs.54,000</b></li>
                                                        <li>Number of Members <b>1</b></li>
                                                        <li>Voting Rights <b>1</b></li>
                                                        <li>Annual Maintenance <b>Rs.5000+18% GST</b></li>
                                                        <li>Transferability <b>Yes, with transfer fee of Rs.1,00,000 plus GST</b></li>
                                                        <li>Term <b>Lifelong</b></li>
                                                    </ul>
                                                </div>
                                                <div class="packages_bottombody">
                                                    <Button variant="primary" className="btn-pricing" onClick={handleShow2}>
                                                        Apply Now
                                                    </Button>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="packages_wrapping">

                                                <div className="packages_headers">
                                                    <i className="lni-layers"></i>
                                                    <h4 className="packages_pr_title">Associate Membership</h4>
                                                    <span className="packages_price-subtitle">(Eligibility: Non Malayalees)</span>
                                                </div>
                                                <div className="packages_price">
                                                    <h6 >Total</h6>
                                                    <h4 className="pr-value">Rs.3,59,900</h4>
                                                </div>
                                                <div className="packages_middlebody">
                                                    <ul >
                                                        <li>Member Admission Fee <b>Rs.3,00,000</b></li>
                                                        <li>GST 18% <b>Rs.54,000</b></li>
                                                        <li>Number of Members <b>1</b></li>
                                                        <li>Voting Rights <b>Nil</b></li>
                                                        <li>Annual Maintenance <b>Rs.5000 +18% GST</b></li>
                                                        <li>Transferability <b>Yes, with transfer fee of Rs.1,00,000 plus GST</b></li>
                                                        <li>Term <b>Lifelong</b></li>
                                                    </ul>
                                                </div>
                                                <div class="packages_bottombody">
                                                    <Button variant="primary" className="btn-pricing" onClick={handleShow3}>
                                                        Apply Now
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4">
                                            <div className="packages_wrapping recommended">

                                                <div className="packages_headers">
                                                    <i className="lni-layers"></i>
                                                    <h4 className="packages_pr_title">Corporate Membership</h4>
                                                    <span className="packages_price-subtitle">(Eligibility: Companies)</span>
                                                </div>
                                                <div className="packages_price">
                                                    <h6 style={{ color: "#fff" }}>Total</h6>
                                                    <h4 className="pr-value">Rs.5,92,800</h4>
                                                </div>
                                                <div className="packages_middlebody">
                                                    <ul>
                                                        <li>Member Admission Fee <b>Rs.5,00,000</b></li>
                                                        <li>GST 18% <b>Rs.90,000</b></li>
                                                        <li>Number of Members <b>1</b></li>
                                                        <li>Voting Rights <b>Nil</b></li>
                                                        <li>Annual Maintenance <b>Rs.5000 per nominee +18% GST</b></li>
                                                        <li>Non Transferable </li>
                                                        <li>Term <b>20 Years</b></li>
                                                    </ul>
                                                </div>

                                                <div class="packages_bottombody">
                                                    <Button variant="primary" className="btn-pricing" onClick={handleShow4}>
                                                        Apply Now
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <div className="packages_wrapping">

                                                <div className="packages_headers">
                                                    <i className="lni-layers"></i>
                                                    <h4 className="packages_pr_title">Long Term Temporary Membership</h4>
                                                    <span className="packages_price-subtitle">(Eligibility: All)</span>
                                                </div>
                                                <div className="packages_price">
                                                    <h6 >Total</h6>
                                                    <h4 className="pr-value">Rs.1,23,900</h4>
                                                </div>
                                                <div className="packages_middlebody">
                                                    <ul>
                                                        <li>Member Admission Fee <b>Rs.1,00,000</b></li>
                                                        <li>GST 18% <b>Rs.18,000</b></li>
                                                        <li>Number of Members <b>1</b></li>
                                                        <li>Voting Rights <b>Nil</b></li>
                                                        <li>Annual Maintenance <b>Rs.5000 +18% GST</b></li>
                                                        <li>Non Transferable</li>
                                                        <li>Term <b>5 Years</b></li>
                                                    </ul>
                                                </div>
                                                <div class="packages_bottombody">
                                                    <Button variant="primary" className="btn-pricing" onClick={handleShow5}>
                                                        Apply Now
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 


                                </div>
                            </div>




                        </div>*/}
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">

                            <div className="single_widgets widget_thumb_post">
                                <div className="prc_wrap">

                                    <div className="prc_wrap_header">
                                        <h3 className="property_block_title">Additional Terms and Conditions</h3>
                                    </div>
                                    <div class="edu_wraper" style={{ padding: "1rem", marginBottom: "0px" }}>
                                        <ul style={{ textAlign: "left", listStyle: "circle" }}>
                                            <li>All Memberships require a minimum spend of Rs.500/- per month at the Club, on specified hospitality services.</li>
                                            <li>All applications have to be filled up properly and completely and recommended by two members of the Club before submission.</li>
                                            <li>Aadhar card copies are a must along with applications.</li>
                                            <li>The forms have to be vetted by the Office and to be approved by the members in charge for Membership Approval.</li>
                                            <li>All of the above are subject to the interpretations and decisions of the Executive Committee only and are subject to change as per the decisions of the Executive Commmittee.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="container" id="scrollEnquiry">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">

                            <div className="col-lg-12 col-md-12">
                                <div className="prc_wrap">

                                    <div className="prc_wrap_header">
                                        <h3 className="property_block_title">Membership Enquiry</h3>
                                    </div>

                                    <form ref={form} onSubmit={sendEmail}>

                                        <div className="prc_wrap-body">
                                            <div className="row">

                                                <div className="col-lg-6 col-md-12">


                                                    <div className="form-group" style={{ textAlign: "left" }}>
                                                        <label >Name</label>
                                                        <input type="text" required name="user_name" className="form-control simple" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group" style={{ textAlign: "left" }}>
                                                        <label>Email</label>
                                                        <input type="email" required name="user_email" className="form-control simple" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="form-group" style={{ display: "none" }}>
                                                <input name="enquiry_from" type="text" value="Message from Contact Us page" className="form-control simple" />
                                            </div> */}
                                            <div className="form-group" style={{ textAlign: "left" }}>

                                                <select required className="form-control simple form-select" name="membership" aria-label="Default select example">
                                                    <option selected>Select Membership</option>
                                                    <option value="Premium Membership">Premium</option>
                                                    <option value="Life Membership">Life</option>
                                                    <option value="Associate Membership">Associate</option>
                                                    <option value="Corporate Membership">Corporate</option>
                                                    <option value="Long Term Temporary Membership">Long Term Temporary Membership</option>
                                                </select>
                                            </div>
                                            <div className="form-group" style={{ textAlign: "left" }}>
                                                <label>Phone Number</label>
                                                <input required name="user_number" type="text" className="form-control simple" />
                                            </div>

                                            <div className="form-group" style={{ textAlign: "left" }}>
                                                <label>Message</label>
                                                <textarea name="message" className="form-control simple"></textarea>
                                            </div>

                                            <div className="form-group">

                                                <button className="btn btn-theme" value="Send" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <Modal show={show} onHide={handleClose} style={{ width: "auto", margin: "auto", padding: "0px" }}>
                <div className="container">

                    <Modal.Header closeButton classname="btn-close" style={{ background: "#fff" }}>
                        <Modal.Title>Premium Membership</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: "#fff" }}>
                        <form ref={form} onSubmit={sendEmail}>

                            <div className="row">

                                <div className="col-lg-12 col-md-12">


                                    <div className="form-group" style={{ alignItems: "baseline" }}>
                                        <label for="user_name">Name: &nbsp;&nbsp;</label>
                                        <input type="text" required id="user_name" name="user_name" className="form-control simple" />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group" style={{ alignItems: "baseline" }}>
                                        <label>Email: &nbsp;&nbsp;</label>
                                        <input type="email" required name="user_email" className="form-control simple" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" style={{ display: "none" }}>
                                <input name="enquiry_from" type="text" value="Message from Premium Membership" className="form-control simple" />
                            </div>

                            <div className="form-group" style={{ alignItems: "baseline" }}>
                                <label>Phone: &nbsp;&nbsp;</label>
                                <input name="user_number" required type="number" className="form-control simple" />
                            </div>

                            <div className="form-group" style={{ alignItems: "baseline" }}>
                                <label>Message: &nbsp;&nbsp;</label>
                                <textarea name="message" required className="form-control simple"></textarea>
                            </div>

                            <div className="form-group">

                                <Button variant="primary" value="Send" type="submit">
                                    Submit
                                </Button>&nbsp;&nbsp;&nbsp;
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    </Modal.Body>
                </div>
            </Modal>
            <Modal show={show2} onHide={handleClose2} style={{ width: "auto", margin: "auto", padding: "0px" }}>
                <div className="container">

                    <Modal.Header closeButton classname="btn-close" style={{ background: "#fff" }}>
                        <Modal.Title>Life Membership</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: "#fff" }}>
                        <form ref={form} onSubmit={sendEmail}>

                            <div className="row">

                                <div className="col-lg-12 col-md-12">


                                    <div className="form-group" style={{ alignItems: "baseline" }}>
                                        <label for="user_name">Name: &nbsp;&nbsp;</label>
                                        <input type="text" required id="user_name" name="user_name" className="form-control simple" />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group" style={{ alignItems: "baseline" }}>
                                        <label>Email: &nbsp;&nbsp;</label>
                                        <input type="email" required name="user_email" className="form-control simple" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" style={{ display: "none" }}>
                                <input name="enquiry_from" type="text" value="Message from Life Membership" className="form-control simple" />
                            </div>

                            <div className="form-group" style={{ alignItems: "baseline" }}>
                                <label>Phone: &nbsp;&nbsp;</label>
                                <input name="user_number" required type="number" className="form-control simple" />
                            </div>

                            <div className="form-group" style={{ alignItems: "baseline" }}>
                                <label>Message: &nbsp;&nbsp;</label>
                                <textarea name="message" required className="form-control simple"></textarea>
                            </div>

                            <div className="form-group">

                                <Button variant="primary" value="Send" type="submit">
                                    Submit
                                </Button>&nbsp;&nbsp;&nbsp;
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    </Modal.Body>
                </div>
            </Modal>
            <Modal show={show3} onHide={handleClose3} style={{ width: "auto", margin: "auto", padding: "0px" }}>
                <div className="container">

                    <Modal.Header closeButton classname="btn-close" style={{ background: "#fff" }}>
                        <Modal.Title>Associate Membership</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: "#fff" }}>
                        <form ref={form} onSubmit={sendEmail}>

                            <div className="row">

                                <div className="col-lg-12 col-md-12">


                                    <div className="form-group" style={{ alignItems: "baseline" }}>
                                        <label for="user_name">Name: &nbsp;&nbsp;</label>
                                        <input type="text" required id="user_name" name="user_name" className="form-control simple" />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group" style={{ alignItems: "baseline" }}>
                                        <label>Email: &nbsp;&nbsp;</label>
                                        <input type="email" required name="user_email" className="form-control simple" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" style={{ display: "none" }}>
                                <input name="enquiry_from" type="text" value="Message from Associate Membership" className="form-control simple" />
                            </div>

                            <div className="form-group" style={{ alignItems: "baseline" }}>
                                <label>Phone: &nbsp;&nbsp;</label>
                                <input name="user_number" required type="number" className="form-control simple" />
                            </div>

                            <div className="form-group" style={{ alignItems: "baseline" }}>
                                <label>Message: &nbsp;&nbsp;</label>
                                <textarea name="message" required className="form-control simple"></textarea>
                            </div>

                            <div className="form-group">

                                <Button variant="primary" value="Send" type="submit">
                                    Submit
                                </Button>&nbsp;&nbsp;&nbsp;
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    </Modal.Body>
                </div>
            </Modal>
            <Modal show={show4} onHide={handleClose4} style={{ width: "auto", margin: "auto", padding: "0px" }}>
                <div className="container">

                    <Modal.Header closeButton classname="btn-close" style={{ background: "#fff" }}>
                        <Modal.Title>Corporate Membership</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: "#fff" }}>
                        <form ref={form} onSubmit={sendEmail}>

                            <div className="row">

                                <div className="col-lg-12 col-md-12">


                                    <div className="form-group" style={{ alignItems: "baseline" }}>
                                        <label for="user_name">Name: &nbsp;&nbsp;</label>
                                        <input type="text" required id="user_name" name="user_name" className="form-control simple" />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group" style={{ alignItems: "baseline" }}>
                                        <label>Email: &nbsp;&nbsp;</label>
                                        <input type="email" required name="user_email" className="form-control simple" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" style={{ display: "none" }}>
                                <input name="enquiry_from" type="text" value="Message from Corporate Membership" className="form-control simple" />
                            </div>

                            <div className="form-group" style={{ alignItems: "baseline" }}>
                                <label>Phone: &nbsp;&nbsp;</label>
                                <input name="user_number" required type="number" className="form-control simple" />
                            </div>

                            <div className="form-group" style={{ alignItems: "baseline" }}>
                                <label>Message: &nbsp;&nbsp;</label>
                                <textarea name="message" required className="form-control simple"></textarea>
                            </div>

                            <div className="form-group">

                                <Button variant="primary" value="Send" type="submit">
                                    Submit
                                </Button>&nbsp;&nbsp;&nbsp;
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    </Modal.Body>
                </div>
            </Modal>
            <Modal show={show5} onHide={handleClose5} style={{ width: "auto", margin: "auto", padding: "0px" }}>
                <div className="container">

                    <Modal.Header closeButton classname="btn-close" style={{ background: "#fff" }}>
                        <Modal.Title>Long Term Temporary Membership</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: "#fff" }}>
                        <form ref={form} onSubmit={sendEmail}>

                            <div className="row">

                                <div className="col-lg-12 col-md-12">


                                    <div className="form-group" style={{ alignItems: "baseline" }}>
                                        <label for="user_name">Name: &nbsp;&nbsp;</label>
                                        <input type="text" required id="user_name" name="user_name" className="form-control simple" />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group" style={{ alignItems: "baseline" }}>
                                        <label>Email: &nbsp;&nbsp;</label>
                                        <input type="email" required name="user_email" className="form-control simple" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group" style={{ display: "none" }}>
                                <input name="enquiry_from" type="text" value="Message from long Term Temporary Membership" className="form-control simple" />
                            </div>

                            <div className="form-group" style={{ alignItems: "baseline" }}>
                                <label>Phone: &nbsp;&nbsp;</label>
                                <input name="user_number" required type="number" className="form-control simple" />
                            </div>

                            <div className="form-group" style={{ alignItems: "baseline" }}>
                                <label>Message: &nbsp;&nbsp;</label>
                                <textarea name="message" required className="form-control simple"></textarea>
                            </div>

                            <div className="form-group">

                                <Button variant="primary" value="Send" type="submit">
                                    Submit
                                </Button>&nbsp;&nbsp;&nbsp;
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    </Modal.Body>
                </div>
            </Modal> */}
        </div>
    );
};
