import "./App.css";
import { ScrollUp } from "./components/ScrollUp";
import { Nav2 } from "./components/Nav2";

import NavBar from "./components/NavBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from "./components/Pages/Home";
import { Sample } from "./components/Pages/sample";
import { Profile } from "./components/Pages/about-us/Profile";
import { Management } from "./components/Pages/about-us/Management";
import { Affiliation } from "./components/Pages/about-us/Affiliation";
import { History } from "./components/Pages/about-us/History";
import { Rooms } from "./components/Pages/facilities/Rooms";
import { Conference } from "./components/Pages/facilities/Conference";
import { Food } from "./components/Pages/facilities/Food";
import { Health } from "./components/Pages/facilities/Health";
import { Sports } from "./components/Pages/facilities/Sports";
import { Events } from "./components/Pages/facilities/Events";
import { UpcomingEvents } from "./components/Pages/facilities/UpcomingEvents";
import { Accommodation } from "./components/Pages/Accommodation";
import { Conventions } from "./components/Pages/Conventions";
import { Restaurent } from "./components/Pages/food-and-beverages/Restaurent";
import { Bar } from "./components/Pages/food-and-beverages/Bar";
import { LongTerm } from "./components/Pages/membership/LongTerm";
import { Life } from "./components/Pages/membership/Life";
import { Associate } from "./components/Pages/membership/Associate";
import { Corporate } from "./components/Pages/membership/Corporate";
import { Gal } from "./components/Pages/data/gal";
import { Premium } from "./components/Pages/membership/Premium";

import { Gallery } from "./components/Pages/Gallery";
import { Pay } from "./components/Pay"

import { Contact } from "./components/Pages/Contact";
import { Facilities } from "./components/Pages/Facilities";
import { About } from "./components/Pages/About";
import { Membership } from "./components/Pages/Membership";
// import { PayOnline } from "./components/Pages/PayOnline";

import { Login } from "./Login";
import { Admin } from "./Admin";
import { Footer } from './components/Footer';




function App() {

  return (
    <>
      <Router>
        <ScrollUp />
        {/* <NavBar /> */}
        {/* <Nav2 {...(isHome && navBg ? { backgroundColor: '#00008' : {})} /> */}
        <Nav2 />
        <div
          style={{
            overflowY: "scroll",
            // height: "800px",
          }}
        ></div>
        <div className="pages">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/sample" element={<Sample />} />
            <Route path="/gal" element={<Gal />} />
            <Route path="/membership" element={<Membership />} />
            <Route path="/pay" element={<Pay />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/management" element={<Management />} />
            <Route path="/affiliation" element={<Affiliation />} />
            <Route path="/history" element={<History />} />
            <Route path="/rooms" element={<Rooms />} />
            <Route path="/conference" element={<Conference />} />
            <Route path="/food" element={<Food />} />
            <Route path="/health" element={<Health />} />
            <Route path="/sports" element={<Sports />} />
            <Route path="/events" element={<Events />} />
            <Route path="/upcomingEvents" element={<UpcomingEvents />} />
            <Route path="/restaurent" element={<Restaurent />} />
            <Route path="/bar" element={<Bar />} />
            <Route path="/longTerm" element={<LongTerm />} />
            <Route path="/life" element={<Life />} />
            <Route path="/associate" element={<Associate />} />
            <Route path="/corporate" element={<Corporate />} />
            <Route path="/premium" element={<Premium />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/facilities" element={<Facilities />} />

            <Route path="/accommodation" element={<Accommodation />} />
            <Route path="/conventions" element={<Conventions />} />


            <Route path="/contact" element={<Contact />} />
          </Routes>

          {/* <Routes>

            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<Admin />} />

          </Routes> */}
        </div>
        <Footer />
      </Router>

    </>
  );
}

export default App;


