import React from "react";
import '../assets/css/styles.css';
import '../assets/css/colors.css';
import '../assets/css/plugins/font-awesome.css';
import '../assets/css/plugins/flaticon.css';
import '../assets/css/plugins/bootstrap.min.css';
import logo1 from "../assets/images/logos/1.png";
import logo2 from "../assets/images/logos/2.png";
import logo3 from "../assets/images/logos/3.png";

export const Restaurent = () => {
  return (
    <div>
      <section className="page-title" style={{ padding: "0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">

              <div className="breadcrumbs-wrap">
                <h1 style={{ textAlign: "center", fontSize: "35px", color: "#222a35" }}>Food & Beverages</h1>



              </div>

            </div>
          </div>
        </div>
      </section>

      <section className="page-title" style={{ padding: "0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-2 col-sm-4 order-1 order-lg-2 order-md-1"></div>
            <div className="col-lg-2 col-md-2 col-sm-4 order-1 order-lg-2 order-md-1">
              <img style={{ width: "100%", height: "auto" }} src={logo1} alt="" />

            </div>
            <div className="col-lg-2 col-md-2 col-sm-4 order-1 order-lg-2 order-md-1">
              <img style={{ width: "100%", height: "auto" }} src={logo2} alt="" />

            </div>
            <div className="col-lg-2 col-md-2 col-sm-4 order-1 order-lg-2 order-md-1">
              <img style={{ width: "100%", height: "auto" }} src={logo3} alt="" />

            </div>
            <div className="col-lg-3 col-md-2 col-sm-4 order-1 order-lg-2 order-md-1"></div>

          </div>
          <div className="row">

            <div className="col-lg-12 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1">

              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="education_block_list_layout">



                    <div className="list_layout_ecucation_caption">


                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="article_featured_image">
                            <img className="img-fluid" src="https://malayaleeclub.org/assets/img/rest3.jpeg" alt="" />
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Soup</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Nandu Soup:<strong>Rs 140</strong></li>
                                  <li>Malabar Kozhi Soup:<strong>Rs 160</strong></li>
                                  <li>Hot & Sour Chicken Soup:<strong>Rs 115</strong></li>
                                  <li>Chicken Manchow Soup:<strong>Rs 110</strong></li>
                                  <li>Sweet Corn Chicken Soup:<strong>Rs 110</strong></li>
                                  <li>Hot & Sour Veg Soup:<strong>Rs 100</strong></li>
                                  <li>Veg Manchow Soup:<strong>Rs 110</strong></li>
                                  <li>Sweet Corn Veg Soup:<strong>Rs 100</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Indian Breads</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Malabar Paratha:<strong>30</strong></li>
                                  <li>Chappathi:<strong>25</strong></li>
                                  <li>Kallappam:<strong>30</strong></li>
                                  <li>Kal Dosa:<strong>30</strong></li>
                                  <li>Plain Dosa:<strong>60</strong></li>
                                  <li>Ghee Dosa:<strong>80</strong></li>
                                  <li>Appam:<strong>40</strong></li>
                                  <li>Egg Appam:<strong>50</strong></li>
                                  <li>Kuthu Ari:<strong>40</strong></li>
                                  <li>Steam Rice:<strong>40</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Salad</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Green Salad:<strong>70</strong></li>
                                  <li>Tossed Salad:<strong>70</strong></li>
                                  <li>Pineapple Chatt:<strong>70</strong></li>
                                  <li>Russian:<strong>90</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Kappa</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Kappa:<strong>80</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Rice</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Veg Meals:<strong>110</strong></li>
                                  <li>Fish Curry Meals:<strong>140</strong></li>
                                  <li>Ghee Rice:<strong>125</strong></li>
                                  <li>Veg Biriyani:<strong>150</strong></li>
                                  <li>Chicken Fried Rice/Noodles:<strong>190</strong></li>
                                  <li>Veg Fried Rice/Noodles:<strong>150</strong></li>
                                  <li>Mixed Fried Rice:<strong>170</strong></li>
                                  <li>Egg Fried Rice:<strong>150</strong></li>
                                  <li>Schezwan Fried Rice:<strong>190</strong></li>
                                  <li>Schezwan Veg Fried Rice<strong>170</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>





                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Egg</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Karandi Omelette:<strong>70</strong></li>
                                  <li>Egg Burji:<strong>60</strong></li>
                                  <li>Chilli Egg:<strong>90</strong></li>
                                  <li>Omelette:<strong>60</strong></li>
                                  <li>Boiled Egg:<strong>60</strong></li>
                                  <li>Fried Egg:<strong>60</strong></li>
                                  <li>Egg Roast:<strong>130</strong></li>
                                  <li>Egg Currry<strong>130</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Curries(Vegetarian)</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Veg Stew:<strong>140</strong></li>
                                  <li>Veg Kuruma :<strong>140</strong></li>
                                  <li>Green Peas Curry:<strong>130</strong></li>
                                  <li>Veg Theal:<strong>120</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Puttu</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Puttu:<strong>80</strong></li>
                                  <li>Chicken Puttu:<strong>80</strong></li>
                                  <li>Prawn Puttu:<strong>180</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Curries(Non-Veg)</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Kozhi Varatharachathu Curry:<strong>150</strong></li>
                                  <li>Malabar Chicken Curry:<strong>160</strong></li>
                                  <li>Kozhi Roast:<strong>150</strong></li>
                                  <li>Manchatti Kozhi Curry:<strong>150</strong></li>
                                  <li>Chicken Stew:<strong>150</strong></li>
                                  <li>Chicken Pepper Masala:<strong>160</strong></li>
                                  <li>Kappa With Chicken Curry:<strong>160</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Starters(Vegetarian)</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Kerala Veg Roast:<strong>160</strong></li>
                                  <li>Babycorn Ularthiyathu:<strong>140</strong></li>
                                  <li>Koon Kurumulegu:<strong>140</strong></li>
                                  <li>Chilli Paneer:<strong>140</strong></li>
                                  <li>Paneer Dry Fry:<strong>140</strong></li>
                                  <li>Gobi 65:<strong>120</strong></li>
                                  <li>Cauliflower Manchurian:<strong>140</strong></li>
                                  <li>Babycorn Salt & Pepper:<strong>140</strong></li>
                                  <li>Veg Spring Roll:<strong>140</strong></li>
                                  <li>Veg Cheese Balls:<strong>150</strong></li>
                                  <li>Mushroom Pepper Fry:<strong>160</strong></li>
                                  <li>Cheese Pineapple:<strong>100</strong></li>
                                  <li>French Fries:<strong>60</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Sea Food</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Chemmeen Kizhi:<strong>275</strong></li>
                                  <li>Club Special Fish Roast:<strong>275</strong></li>
                                  <li>Fish Kizhi(Seer Fish):<strong>250</strong></li>
                                  <li>Tawa Fish Fry(Seer Fish):<strong>275</strong></li>
                                  <li>Meen Pollichathu(Seer Fish):<strong>275</strong></li>
                                  <li>Chemmeen Ulathiyathu:<strong>250</strong></li>
                                  <li>Prawns Dry Fry Roast:<strong>250</strong></li>
                                  <li>Crab Claws:<strong>220</strong></li>
                                  <li>Karuveppilai Meen Fry:<strong>250</strong></li>
                                  <li>Fish Chilli:<strong>200</strong></li>
                                  <li>Netholi Fry:<strong>130</strong></li>
                                  <li>Mathi Fry:<strong>95</strong></li>
                                  <li>Ayala Fry:<strong>130</strong></li>
                                  <li>Kappa With Fish Curry:<strong>160</strong></li>
                                  <li>Fish Roast:<strong>200</strong></li>
                                  <li>Meen Vattichathu:<strong>200</strong></li>
                                  <li>Malabar Fish Fry:<strong>200</strong></li>
                                  <li>Squid Fry:<strong>180</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Mutton</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Mutton Stew:<strong>200</strong></li>
                                  <li>Mutton Roast:<strong>200</strong></li>
                                  <li>Nadan Mutton Curry:<strong>200</strong></li>
                                  <li>Mutton Kuru Melagu:<strong>200</strong></li>
                                  <li>Mutton Thangai Fry:<strong>220</strong></li>
                                  <li>Nadan Ularthiyathu:<strong>250</strong></li>
                                  <li>Mutton Curry:<strong>200</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Beef</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Kappa With Beef Curry:<strong>160</strong></li>
                                  <li>Kappa Beef Biriyani:<strong>200</strong></li>
                                  <li>Beef Ularthiyathu:<strong>150</strong></li>
                                  <li>Beef Thenga Fry:<strong>150</strong></li>
                                  <li>Beef Dry Fry:<strong>150</strong></li>
                                  <li>Beef Roast:<strong>175</strong></li>
                                  <li>Beef Curry:<strong>250</strong></li>
                                  <li>Chilli Beef<strong>150</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Desserts</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Gulab Jamun(2 Pcs):<strong>50</strong></li>
                                  <li>Carrot Halwa:<strong>100</strong></li>
                                  <li>Cut Fruit:<strong>75</strong></li>
                                  <li>Gulab Jamun With Ice Cream:<strong>100</strong></li>
                                  <li>Carrot Halwa With Ice Cream:<strong>100</strong></li>
                                  <li>Cut Fruit With Ice Cream:<strong>100</strong></li>
                                  <li>Payasam Of The Day:<strong>100</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Beverage</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Soda:<strong>30</strong></li>
                                  <li>Mineral Water:<strong>30</strong></li>
                                  <li>Soft Drink:<strong>30</strong></li>
                                  <li>Tea:<strong>30</strong></li>
                                  <li>Coffee:<strong>40</strong></li>
                                  <li>Juice:<strong>40</strong></li>
                                  <li>Sweet Lime:<strong>80</strong></li>
                                  <li>Watermelon:<strong>80</strong></li>
                                  <li>Pineapple:<strong>80</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <div className="education_block_body">
                            <div className="education_block_author" >
                              <div class="edu_wraper">
                                <h4 className="bl-title" >Starters(Non-Vegetarian)</h4>
                                <br />
                                <ul class="edu_list right" style={{ textAlign: "left", listStyle: "circle" }}>
                                  <li>Club Special Kozhi Porichathu:<strong>200</strong></li>
                                  <li>Kozhi Kuru Melagu:<strong>175</strong></li>
                                  <li>Chicken 65:<strong>175</strong></li>
                                  <li>Club Special Kozhi Roast:<strong>200</strong></li>
                                  <li>Chicken Lollipop:<strong>200</strong></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};