import './assets/css/styles.css';
import './assets/css/colors.css';
import './assets/css/plugins/font-awesome.css';
import './assets/css/plugins/flaticon.css';
import './assets/css/plugins/bootstrap.min.css';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export const Accommodation = () => {
  const form1 = useRef();
  const form2 = useRef();
  const form3 = useRef();
  const form4 = useRef();

  const sendEmail1 = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_5jg3ske', 'template_o86ulkp', form1.current,
      'tNwTmvoIP5Jp6GXov')
      .then((result) => {
        console.log(result.text);
        window.location.reload(false);
      }, (error) => {
        console.log(error.text);
      });
  };
  const sendEmail2 = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_5jg3ske', 'template_o86ulkp', form2.current,
      'tNwTmvoIP5Jp6GXov')
      .then((result) => {
        console.log(result.text);
        window.location.reload(false);
      }, (error) => {
        console.log(error.text);
      });
  };
  const sendEmail3 = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_5jg3ske', 'template_o86ulkp', form3.current,
      'tNwTmvoIP5Jp6GXov')
      .then((result) => {
        console.log(result.text);
        window.location.reload(false);
      }, (error) => {
        console.log(error.text);
      });
  };
  const sendEmail4 = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_5jg3ske', 'template_o86ulkp', form4.current,
      'tNwTmvoIP5Jp6GXov')
      .then((result) => {
        console.log(result.text);
        window.location.reload(false);
      }, (error) => {
        console.log(error.text);
      });
  };
  return (
    <div >
      <section className="page-title" style={{ padding: "0px" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">

                  <div className="breadcrumbs-wrap">
                    <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Guest Rooms</h1>
                    {/* <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href={"/"}>Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Rooms & Amenities</li>
                  </ol>
                </nav> */}
                  </div>

                </div>
              </div>
            </div>
          </section>
          <section className="gray">

            <div className="container">

              <div className="row">

                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="article_detail_wrapss single_article_wrap format-standard">
                    <div className="article_body_wrap">

                      <div className="article_featured_image">
                        <img className="img-fluid" src="https://malayaleeclub.org/assets/img/gust_bg.jpg" alt="" />
                      </div>
                      <hr />
                      <div class="dashboard_container_body">
                        <div class="table-responsive">
                          <table class="table">
                            <thead class="thead-dark">
                              <tr>
                                <th scope="col">Sl. No.</th>
                                <th scope="col" style={{ textAlign: "left" }}>Rooms</th>
                                <th scope="col" style={{ textAlign: "right" }}>Amount (INR)</th>
                                <th scope="col" style={{ textAlign: "right" }}>GST(12%)</th>
                                <th scope="col" style={{ textAlign: "right" }}>Total (INR)</th>
                                {/* <th scope="col" style={{ textAlign: "left" }}>Included for all rooms</th> */}

                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">1</th>
                                <td style={{ textAlign: "left" }}><b>Single Room</b></td>
                                <td>2250</td>
                                <td>270</td>

                                <td><b>2520</b></td>
                                {/* <td rowSpan="3" style={{ textAlign: "left" }}> Breakfast<br />
                                  Extra Bed : Rs 750 + Rs 90 (GST 12%)</td> */}


                              </tr>

                              <tr>
                                <th scope="row">2</th>
                                <td style={{ textAlign: "left" }}><b>Double Room</b></td>
                                <td>2750</td>
                                <td>300</td>

                                <td><b>3050</b></td>
                              </tr>

                              <tr>
                                <th scope="row">3</th>
                                <td style={{ textAlign: "left" }}><b>Suite Room</b></td>
                                <td>4000</td>
                                <td>480</td>

                                <td><b>4480</b></td>

                              </tr>

                            </tbody>
                          </table>

                        </div>
                      </div>
                      <div className='jumbotron' style={{ padding: "2rem 1rem" }}>

                        <h1 style={{ textAlign: "left", fontSize: "15px", color: "#222a35" }}>Remarks</h1>

                        <ul style={{ textAlign: "left", listStyle: "dot", padding: "0px 0px 0px 14px" }}>

                          <li>Complimentary Breakfast</li>
                          <li>Extra Bed : Rs 750 + Rs 90 (GST 12%)</li>
                          <li>Tariffs are subject to change. Please enquire for latest rates.</li>

                        </ul>
                      </div>

                      {/* <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <h1 style={{ textAlign: "center", fontSize: "28px", color: "#222a35" }}>Guest Room Tariff</h1>

                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping">

                            <div className="packages_headers">
                              <i className="lni-layers"></i>
                              <h4 className="packages_pr_title">Single Room</h4>
                              <span className="packages_price-subtitle"></span>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">2250</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 270 (GST 12%)</li>
                                <li>Breakfast : Included</li>
                                <li>Water Bottles : 2 Nos</li>
                                <li>Cofee/ Tea Sachets : 2 Sets each</li>
                                <li>Linen Change : Every Night</li>
                                <li>Extra Bed : Rs 600 + Rs 72 (GST 12%)</li>

                              </ul>
                            </div>


                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping recommended">

                            <div className="packages_headers">
                              <i className="lni-diamond"></i>
                              <h4 className="packages_pr_title">Double Room</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">2750</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 300 (GST 12%)</li>
                                <li>Breakfast : Included</li>
                                <li>Water Bottles : 2 Nos</li>
                                <li>Cofee/ Tea Sachets : 2 Sets each</li>
                                <li>Linen Change : Every Night</li>
                                <li>Extra Bed : Rs 600 + Rs 72 (GST 12%)</li>

                              </ul>
                            </div>


                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="packages_wrapping">

                            <div className="packages_headers">
                              <i className="lni-invention"></i>
                              <h4 className="packages_pr_title">Suite Room</h4>
                            </div>
                            <div className="packages_price">
                              <h4 className="pr-value">4000</h4>
                            </div>
                            <div className="packages_middlebody">
                              <ul>
                                <li>+ 480 (GST 12%)</li>
                                <li>Breakfast : Included</li>
                                <li>Water Bottles : 2 Nos</li>
                                <li>Cofee/ Tea Sachets : 2 Sets each</li>
                                <li>Linen Change : Every Night</li>
                                <li>Extra Bed : Rs 600 + Rs 72 (GST 12%)</li>

                              </ul>
                            </div>


                          </div>
                        </div>

                      </div> */}


                    </div>
                  </div>




                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-12">



                  <div className="single_widgets widget_tags">
                    <h3 className="title">For Booking Please Contact:
                    </h3>
                    <ul>
                      <li><a href="#">
                        04428360733 | 98843 80071</a></li><br />
                      {/* <li><a href="#">
                        Book Now</a></li> */}
                    </ul>

                  </div>
                  <div className="single_widgets widget_thumb_post">
                    <div className="prc_wrap">

                      <div className="prc_wrap_header">
                        <h3 className="property_block_title">Enquire Now</h3>
                      </div>

                      <form ref={form1} onSubmit={sendEmail1}>

                        <div className="prc_wrap-body">
                          <div className="row">

                            <div className="col-lg-12 col-md-12">


                              <div className="form-group" style={{ textAlign: "left" }}>
                                <label >Name</label>
                                <input type="text" name="user_name" className="form-control simple" />
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group" style={{ textAlign: "left" }}>
                                <label>Email</label>
                                <input type="email" name="user_email" className="form-control simple" />
                              </div>
                            </div>
                          </div>

                          <div className="form-group" style={{ textAlign: "left" }}>
                            <label>Phone Number</label>
                            <input name="user_number" type="text" className="form-control simple" />
                          </div>
                          <div className="form-group" style={{ display: "none" }}>
                            <input name="enquiry_from" type="text" value="Message from Room Booking page" className="form-control simple" />
                          </div>

                          <div className="form-group" style={{ textAlign: "left" }}>
                            <label>Message</label>
                            <textarea name="message" className="form-control simple"></textarea>
                          </div>

                          <div className="form-group">

                            <button className="btn btn-theme" value="Send" type="submit">Submit</button>
                          </div>
                        </div>
                      </form>

                    </div>
                  </div>



                </div>

              </div>

            </div>

          </section>
    </div>
  );
};
