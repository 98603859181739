import React from "react";
import payment from "./Pages/assets/images/payment.png";



export const Pay = () => {
    return (
        <div>
            <section class=" gradient-form" style={{backgroundColor: "#eee"}}>
                <div class="container">
                    <div class="row d-flex justify-content-center align-items-center h-100">
                        <div class="col-xl-10">
                            <div class="card rounded-3 text-black">
                                <div class="row g-0">
                                    <div class="col-lg-6 d-flex align-items-center gradient-custom-2">
                                        <div class="text-white p-md-3 mx-md-4">
                                            <h5 style={{color:"#fff", fontSize:"20px"}}>LOGIN INSTRUCTIONS</h5><br/>
                                            <ol class="small mb-0" style={{textAlign: "left", color:"#fff", fontSize:"14px"}}>
                                                <li>
                                                    User ID - is your membership number with year <br/>(example: P-XXX-2022) as available on your membership card
                                                </li><br/>
                                                <li>
                                                    Default password is your year of joining<br/>(example: 2022). Please change your password after first login for security reasons
                                                </li><br/>
                                                <li>
                                                    In case you forgot the year of joining, Click on forgot password
                                                </li><br/>

                                                <li>For any assistance call +91 90875 89539</li>
                                            </ol>
                                            {/* <hr />
                                            <h5 style={{color:"#fff", fontSize:"20px"}}>YOU CAN MAKE PAYMENTS THROUGH</h5><br/>
                                            <img src={payment} class="paymentImg" alt="logo" /> */}
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="card-body p-md-3 mx-md-4" style={{height:"100%"}}>
                                            <div class="text-center">
                                                <img src="https://themalayaleeclub1897.org/static/media/logo3.6061af173bb1455447ea.png"
                                                    style={{width: "185px"}} alt="logo" />
                                                <hr />
                                                <h4 class="mt-1 mb-5 pb-1">Member Login</h4>
                                            </div>

                                            <form>
                                                <div class="form-outline mb-4" >
                                                    <input type="email" id="form2Example11" class="form-control" placeholder="Membership ID" />
                                                    {/* <label class="form-label" for="form2Example11">Membership ID</label> */}
                                                </div>

                                                <div class="form-outline mb-4" >
                                                    <input type="password" id="form2Example22" placeholder="Password" class="form-control" />
                                                    {/* <label class="form-label" for="form2Example22">Password</label> */}
                                                </div>

                                                <div class="text-center pt-1 mb-5 pb-1">
                                                    <button class="btn  btn-block  gradient-custom-2 mb-3 " style={{ marginLeft:"auto", marginRight:"auto", display:"block"}}
                                                        type="button">
                                                        Log in
                                                    </button>
                                                    <a class="text-muted" href="#!">Forgot password?</a>
                                                </div>

                                                <div class="d-flex align-items-center justify-content-center pb-4">
                                                    <p class="mb-0 me-2">Don't have an account?</p>
                                                    <button type="button" class="btn btn-outline-primary">
                                                        How to Register
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    );
};
