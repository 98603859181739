import React, { useEffect } from "react";

// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams, Link } from "react-router-dom";

// import axios from "axios";
// import React, { useEffect, useState } from "react";

import './assets/css/styles.css';
import './assets/css/colors.css';
import './assets/css/plugins/font-awesome.css';
import './assets/css/plugins/flaticon.css';
import './assets/css/plugins/bootstrap.min.css';
// import './assets/css/plugins/imagelightbox.min.css';
import { Link } from "react-router-dom";


import BannerImage from "./assets/images/back10.png";
import RandA from "./assets/images/1.jpg";
import SandG from "./assets/images/5.jpg";
import CandB from "./assets/images/2.jpg";
import FandB from "./assets/images/3.jpg";
import HandW from "./assets/images/4.jpg";
import EandA from "./assets/images/6.jpg";



import "../../App.css";

import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

export const Facilities = () => {


    return (
        <div>


            <section className="facilitiesRow">
                <div style={{ textAlign: "center" }}>
                    <h1 style={{ textAlign: "center", fontSize: "25px", color: "#222a35" }}>Facilities and Services</h1>
                    <hr />
                    <ul className="columnTwo" style={{ listStyleType: "circle", padding: "0px 20px 0px 20px" }}>
                        <li style={{ listStyleType: "circle" }}>
                            A state- of- the- art 700 seater air-conditioned auditorium
                        </li>
                        <li>2 Banquet Halls (upto 150 seats)</li>
                        <li>5 Meeting Rooms (upto 50 seats)</li>
                        <li>
                            24 luxurious Guest Rooms

                        </li>
                        <li>2 Restaurants</li>


                        <li>
                            A Modern Elite Bar

                        </li>

                        <li>
                            Indoor games facilities

                        </li>

                        <li>
                            Spacious basement car-parking

                        </li>

                        <li>
                            Sports facilities with Badminton / Table Tennis / Card Room / Chess / Carroms / Kids Center

                        </li>
                        <li>
                            Arts and Culture Training Center

                        </li>
                    </ul>
                    <br />
                </div>
                <div className="row" style={{ borderRadius: "4px" }}>

                    <div className="col-lg-4">
                        <div className="articles_grid_style" style={{ boxShadow: "7px 7px 14px #888888 " }}>
                            <div className=" articles_grid_thumb ">
                                <a href="/accommodation"><img src={RandA} className="img-fluid " alt=" " /></a>
                            </div>

                            <div className="articles_grid_caption ">
                                <Link to="/accommodation"> <h4>Guest Rooms</h4>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 ">
                        <div className="articles_grid_style " style={{ boxShadow: "7px 7px 14px #888888", marginLeft: "auto", marginRight: "auto" }}>
                            <div className="articles_grid_thumb ">
                                <a href="/conventions"><img src={CandB} className="img-fluid " alt=" " /></a>
                            </div>

                            <div className="articles_grid_caption ">
                                <Link to="/conventions"> <h4>Conferences & Banquets</h4>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 ">
                        <div className="articles_grid_style " style={{ boxShadow: "7px 7px 14px #888888", float: "right" }}>
                            <div className="articles_grid_thumb ">
                                <a href="/food"><img src={FandB} className="img-fluid " alt=" " /></a>
                            </div>

                            <div className="articles_grid_caption ">
                                <Link to="/food"> <h4>Bar & Restaurant</h4>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 ">
                        <div className="articles_grid_style " style={{ boxShadow: "7px 7px 14px #888888", marginLeft: "auto", marginRight: "auto" }}>
                            <div className="articles_grid_thumb ">
                                <a href="/sports"><img src={SandG} className="img-fluid " alt=" " /></a>
                            </div>

                            <div className="articles_grid_caption ">
                                <Link to="/sports"> <h4 style={{ color: "#222a35" }}>Sports & Games</h4>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 ">
                        <div className="articles_grid_style " style={{ boxShadow: "7px 7px 14px #888888 " }}>
                            <div className="articles_grid_thumb ">
                                <a href="/health"><img src={HandW} className="img-fluid " alt=" " /></a>
                            </div>

                            <div className="articles_grid_caption ">
                                <Link to="/health"> <h4>Health & Wellness</h4>
                                </Link>
                            </div>
                        </div>
                    </div>

                    

                    <div className="col-lg-4 ">
                        <div className="articles_grid_style " style={{ boxShadow: "7px 7px 14px #888888", float: "right" }}>
                            <div className="articles_grid_thumb ">
                                <a href="/events"><img src={EandA} className="img-fluid " alt=" " /></a>
                            </div>

                            <div className="articles_grid_caption ">
                                <Link to="/events">  <h4>Events & Activities</h4>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>



            </section>

        </div>
    );
};
