import React from "react";
import '../assets/css/styles.css';
import '../assets/css/colors.css';
import '../assets/css/plugins/font-awesome.css';
import '../assets/css/plugins/flaticon.css';
import '../assets/css/plugins/bootstrap.min.css';

import NRP from "../assets/img/1.png";
import GN from "../assets/img/2.png";
import ANG from "../assets/img/3.png";
import VM from "../assets/img/4.jpg";
import STP from "../assets/img/5.jpg";

export const Management = () => {
  return (
    <div className="container">
      <h1 style={{ textAlign: "center", fontSize: "35px", color: "#222a35" }}>Management</h1>
      <div className="row ">
        <div className="col-lg-1 col-md-12 col-sm-12 "></div>

        <div className="col-lg-2 col-md-12 col-sm-12 ">
          <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
            <img className="card-img-top " src={NRP} alt="Card cap " />
            <div className="card-body ">
              <div className="instructor_caption " style={{ textAlign: "center" }}>
                <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>N. R. Panicker</a></h6>
                <span style={{ fontSize: "10px" }}>PRESIDENT</span>

              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-12 col-sm-12 ">
          <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
            <img className="card-img-top " src={GN} alt="Card cap " />
            <div className="card-body ">
              <div className="instructor_caption " style={{ textAlign: "center" }}>
                <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Gopakumaran Nair</a></h6>
                <span style={{ fontSize: "10px" }}>VICE PRESIDENT</span>

              </div>
            </div>
          </div>
        </div>



        <div className="col-lg-2 col-md-12 col-sm-12 ">
          <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
            <img className="card-img-top " src={ANG} alt="Card cap " />
            <div className="card-body ">
              <div className="instructor_caption " style={{ textAlign: "center" }}>
                <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>A N Gireeshan</a></h6>
                <span style={{ fontSize: "10px" }}>SECRETARY</span>

              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-12 col-sm-12 ">
          <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
            <img className="card-img-top " src={VM} alt="Card cap " />
            <div className="card-body ">
              <div className="instructor_caption " style={{ textAlign: "center" }}>
                <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>Vijayan Mukundan</a></h6>
                <span style={{ fontSize: "10px" }}>JT. SECRETARY</span>

              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-12 col-sm-12 ">
          <div className="card " style={{ marginLeft: "auto", marginRight: "auto" }}>
            <img className="card-img-top " src={STP} alt="Card cap " />
            <div className="card-body ">
              <div className="instructor_caption " style={{ textAlign: "center" }}>
                <h6><a href="# " className="hoverName" style={{ fontSize: "10px" }}>S T Prabhu</a></h6>
                <span style={{ fontSize: "10px" }}>TREASURER</span>

              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-1 col-md-12 col-sm-12 "></div>

      </div>
    </div>
  );
};
