import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import './Pages/assets/css/plugins/bootstrap.min.css';
// import Dropdown from 'react-multilevel-dropdown';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import './Pages/assets/css/styles.css';
import "./NavBar.css";


import Logo from "./Pages/assets/images/logo3.png";
import { CodeIcon, HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";
// import { useLocation, Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
function NavBar() {
  const [click, setClick] = useState(false);
  // const { state } = useLocation();
  // console.log({state});
  const navigate = useNavigate();
  const aboutNavigate = () => {
    navigate("/about");
  }
  const membershipNavigate = () => {
    navigate("/membership");
  }
  // const facilitiesNavigate = () => {
  //   navigate("/accommodation");
  // }
  const handleClick = () => setClick(!click);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);


  const showDropdownAbout = (e) => {
    setShow1(!show1);
  }
  const hideDropdownAbout = e => {
    setShow1(false);
  }
  const showDropdownFacilities = (e) => {
    setShow2(!show2);
  }
  const hideDropdownFacilities = e => {
    setShow2(false);
  }
  const showDropdownFandB = (e) => {
    setShow3(!show3);
  }
  const hideDropdownFandB = e => {
    setShow3(false);
  }
  const showDropdownMembership = (e) => {
    setShow4(!show4);
  }
  const hideDropdownMembership = e => {
    setShow4(false);
  }
  return (
    <>

      <div className="nav-container">
        <nav className="navbar">
          

          <div className="row navAlign" >


            <div className="col-lg-2" style={{ alignSelf: "center" }}>
              <div className="nav-header">
                <div style={{ width: "230px" }}>
                  <a className="nav-brand static-logo" href="/"><img
                    src={Logo} style={{ width: "100%" }} className="logo"
                    alt="Malayalee Club Logo" /></a>


                </div>
              </div>
            </div>
            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-12" style={{ padding: "13px 13px 0px 0px", margin: "auto" }}>
                <div
                    style={{ float: "right", backgroundColor: "#222a35", borderRadius: "19.8px", padding: "10px 13px 10px 13px" }}>
                    <a href={"https://malayaleeclub.com/"} target="blank" style={{ color: "#fff" }} data-toggle="modal" data-target="#signup">PAY
                      ONLINE</a>

                  </div>
                  <div
                    style={{ float: "right", backgroundColor: "#222a35", borderRadius: "19.8px", padding: "10px 13px 10px 13px", margin: "0px 15px 0px 0px" }}>
                    <a href={"https://admin.malayaleeclub.org/login.php"} target="blank" style={{ color: "#fff" }} data-toggle="modal"
                      data-target="#signup">MEMBERS LOGIN</a>
                  </div>
                  
                </div>

                <div className="col-lg-12" style={{ alignSelf: "center", textShadow: "1px 1px #ccc" }}>
                  <div className="nav-menus-wrapper" style={{ transitionProperty: "none", float: "right" }}>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                      <a style={{ textAlign: "left", padding: "0.2rem 0.5rem", fontWeight: "600" }} href="/">
                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/"
                            activeClassName="active"
                            onClick={handleClick}
                          >
                            Home
                          </NavLink>
                        </li>
                      </a>
                      {/* <Link to="/about#profileRedirect">Section three</Link> */}

                      <li >

                        <NavDropdown className="nav-item" title="About Us"
                          id="collasible-nav-dropdown"
                          onClick={aboutNavigate}
                          show={show1}
                          onMouseEnter={showDropdownAbout}
                          onMouseLeave={hideDropdownAbout}
                        >
                          <NavDropdown.Item >
                            <NavLink
                              exact


                              onClick={handleClick}
                            >
                              <Link className="changeAboutTab" style={{ background: "#fff" }} to="/about#profileRedirect">The Journey</Link>
                            </NavLink>
                          </NavDropdown.Item>
                          <NavDropdown.Item >
                            <NavLink
                              exact
                              onClick={handleClick}
                            >
                              <Link className="changeAboutTab" style={{ background: "#fff" }} to="/about#managementRedirect">Management</Link>
                            </NavLink>
                          </NavDropdown.Item>
                          <NavDropdown.Item >
                            <NavLink
                              exact
                              onClick={handleClick}
                            >
                              <Link className="changeAboutTab" style={{ background: "#fff" }} to="/about#affiliationRedirect">Affiliation</Link>
                            </NavLink>
                          </NavDropdown.Item>
                          <NavDropdown.Item >
                            <NavLink
                              exact
                              onClick={handleClick}
                            >
                              <Link className="changeAboutTab" style={{ background: "#fff" }} to="/about#history2Redirect">History</Link>
                            </NavLink>
                          </NavDropdown.Item>

                          {/* <NavDropdown.Divider />
                          <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
                        </NavDropdown>

                      </li>
                      {/* <li className="nav-item">
                        <NavLink
                          exact
                          to="/about"
                          activeClassName="active"
                          
                          onClick={handleClick}
                        >
                          About
                        </NavLink>
                      </li> */}

                      <li className="nav-item">

                        <NavDropdown title="Facilities"
                          id="collasible-nav-dropdown"
                          show={show2}
                          // onClick={facilitiesNavigate}
                          onMouseEnter={showDropdownFacilities}
                          onMouseLeave={hideDropdownFacilities}
                        >
                          <NavDropdown.Item href="/rooms" id="collasible-nav-dropdown"
                          >
                            <NavLink
                              exact
                              to="/rooms"
                              onClick={handleClick}
                            >
                              Rooms & Amenities
                            </NavLink>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/conference">
                            <NavLink
                              exact
                              to="/conference"
                              onClick={handleClick}
                            >
                              Conference & Banquets
                            </NavLink>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/food">
                            <NavLink
                              exact
                              to="/food"
                              onClick={handleClick}
                            >
                              Food and Beverages
                            </NavLink></NavDropdown.Item>
                          <NavDropdown.Item href="/health">
                            <NavLink
                              exact
                              to="/health"

                              onClick={handleClick}
                            >
                              Health & Wellness
                            </NavLink></NavDropdown.Item>
                          <NavDropdown.Item href="/sports">
                            <NavLink
                              exact
                              to="/sports"

                              onClick={handleClick}
                            >
                              Sports & Games
                            </NavLink></NavDropdown.Item>
                          <NavDropdown.Item href="/events">
                            <NavLink
                              exact
                              to="/events"

                              onClick={handleClick}
                            >
                              Events & Activities
                            </NavLink></NavDropdown.Item>
                        </NavDropdown>

                      </li>
                      <a style={{ textAlign: "left", padding: "0.2rem 0.5rem", fontWeight: "600" }} href="/accommodation">
                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/accommodation"
                            activeClassName="active"
                            onClick={handleClick}
                          >
                            Accommodation
                          </NavLink>
                        </li>
                      </a>
                      <a style={{ textAlign: "left", padding: "0.2rem 0.5rem", fontWeight: "600" }} href="/food">
                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/food"
                            activeClassName="active"
                            onClick={handleClick}
                          >
                            Food & Beverages
                          </NavLink>
                        </li>
                      </a>
                      {/* <li >

                        <NavDropdown className="nav-item" title="Food & Beverages"
                          id="collasible-nav-dropdown"
                          show={show3}
                          onMouseEnter={showDropdownFandB}
                          onMouseLeave={hideDropdownFandB}
                        >
                          <NavDropdown.Item href="/restaurent" id="collasible-nav-dropdown"
                          ><NavLink
                            exact
                            to="/restaurent"

                            onClick={handleClick}
                          >
                              Restaurant
                            </NavLink>
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/bar">
                            <NavLink
                              exact
                              to="/bar"

                              onClick={handleClick}
                            >
                              Bar - 1897
                            </NavLink></NavDropdown.Item>

                        </NavDropdown>

                      </li> */}
                      <a style={{ textAlign: "left", padding: "0.2rem 0.5rem", fontWeight: "600" }} href="/events">


                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/events"
                            activeClassName="active"

                            onClick={handleClick}
                          >
                            Event & Activities
                          </NavLink>
                        </li>
                      </a>
                      {/* <li className="nav-item">

                        <NavDropdown title="Membership"
                          id="collasible-nav-dropdown"
                          show={show4}
                          onClick={membershipNavigate}
                          onMouseEnter={showDropdownMembership}
                          onMouseLeave={hideDropdownMembership}
                        >
                          <NavDropdown.Item href="/premium">

                            Premium Family Membership
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/life" id="collasible-nav-dropdown">

                            Life Membership
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/associate">

                            Associate Membership
                          </NavDropdown.Item>
                          <NavDropdown.Item href="/corporate">

                            Corporate Membership
                          </NavDropdown.Item>

                          <NavDropdown.Item href="/longTerm" id="collasible-nav-dropdown">

                            Long Term Temporary Membership
                          </NavDropdown.Item>

                        </NavDropdown>

                      </li> */}
                      <a style={{ textAlign: "left", padding: "0.2rem 0.5rem", fontWeight: "600" }} href="/membership">

                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/membership"
                            activeClassName="active"

                            onClick={handleClick}
                          >
                            Membership
                          </NavLink>
                        </li>
                      </a>

                      <a style={{ textAlign: "left", padding: "0.2rem 0.5rem", fontWeight: "600" }} href="/affiliation">

                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/affiliation"
                            activeClassName="active"

                            onClick={handleClick}
                          >
                            Affiliation
                          </NavLink>
                        </li>
                      </a>
                      <a style={{ textAlign: "left", padding: "0.2rem 0.5rem", fontWeight: "600" }} href="/gallery">

                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/gallery"
                            activeClassName="active"

                            onClick={handleClick}
                          >
                            Gallery
                          </NavLink>
                        </li>
                      </a>
                      <a style={{ textAlign: "left", padding: "0.2rem 0.5rem", fontWeight: "600" }} href="/contact">

                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/contact"
                            activeClassName="active"

                            onClick={handleClick}
                          >
                            Contact Us
                          </NavLink>
                        </li>
                      </a>

                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>



          {/* <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                
                onClick={handleClick}
              >
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/blog"
                activeClassName="active"
                
                onClick={handleClick}
              >
                Blog
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                
                onClick={handleClick}
              >
                Contact Us
              </NavLink>
            </li>
          </ul> */}
          <div className="nav-icon" onClick={handleClick}>

            {click ? (
              <span className="icon">
                <HamburgetMenuOpen />{" "}
              </span>
            ) : (
              <span className="icon">
                <HamburgetMenuClose />
              </span>
            )}
          </div>
        </nav>
      </div>

    </>
  );
}

export default NavBar;
